import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { departmentsOfCenterBanner } from "../../components/images";
import CaseManagement from "./caseManagement/caseManagement";
import FinancialAffairs from "./financialAffairs/financialAffairs";
import InformationTechnology from "./informationTechnology/informationTechnology";
import Management from "./management/management";
import Faq from "../../components/common/faq/faq";
import Footer from "../../components/common/footer/footer";
const departmentsOfCenter = () => {
  return (
    <div>
      <Header />
      <Banner
        img={departmentsOfCenterBanner}
        title="Departments Of The Center"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <CaseManagement />
      <FinancialAffairs />
      <InformationTechnology />
      <Management />
      <Faq />
      <Footer />
    </div>
  );
};

export default departmentsOfCenter;
