import {
  LOADER_FALSE,
  LOADER_TRUE,
  ARBITRATOR_ESERVICES_APPLICATIONS,
  ARBITRATOR_SERVICE_COUNT,
} from "../../actionTypes";

const initialState = {
  applications: {},
  loader: false,
  serviceCount: {},
};

const ArbitratorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARBITRATOR_ESERVICES_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };
    case ARBITRATOR_SERVICE_COUNT:
      return {
        ...state,
        serviceCount: action.payload,
      };
    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };
    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    default:
      return state;
  }
};

export default ArbitratorReducer;
