import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Memberships = () => {
  return (
    <>
      <Form>
        <h1>MEMBERSHIPS</h1>
        <Row>
          <Col lg={6}>
            <Link to="/dashboard/e-services/membership/arbitrator">
              {" "}
              Arbitrator Registration New / Renewal{" "}
            </Link>
            <br />
            <Link to="/dashboard/e-services/membership/expert-registration">
              {" "}
              Expert Registration Request New / Renewal{" "}
            </Link>
            <br />
            <Link to="/dashboard/e-services/membership/membership">
              {" "}
              Membership Registration Renewal{" "}
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Memberships;
