import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import chairman from "../../../assets/images/chairman.png";
import AlLogo from "../../../assets/images/al-logo.png";
import "./chairmanMessage.css";
function ChairmanMessage() {
  return (
    <section className="chairman-message">
      <Container fluid className="custom-container">
        <Row className="justify-content-between">
          <Col lg={7} className="mb-lg-0 mb-4">
            <span className="pre-heading">Chairman Message</span>
            <h2>Trusted Arbitration Centre</h2>
            <p className="p-lg">
              Our team of highly skilled and experienced arbitration lawyers in
              the UAE is dedicated to providing top-notch legal assistance.
            </p>
            <p>
              Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
              Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
              otent oevnec qdfruis libero molestie. Sed ut purus vel est
              pulvinar eleife ellensque eget arcu in enim condimentum
              pellentesque ornare id enim aecenas.
            </p>
            <ul className="general-list list-unstyled mb-4">
              <li>Sed ut purus vel est pulvinar eleifend.</li>
              <li>
                Pellentesque eget arcu in enim condimentum pellentesque ornare
                id enim.
              </li>
              <li>
                Maecenas vitae dui quis lorem semper gravida ac sollicitudin
                sapien.
              </li>
              <li>Maecenas iaculis tortor eu erat mattis suscipit.</li>
            </ul>
            <Button variant="none" className="btn-blue">
              READ MORE
            </Button>
          </Col>
          <Col lg={5} className="position-relative text-end">
            <div className="chainrmain-img">
              <Image src={chairman} fluid alt="chairmain img" />
            </div>
            <div className="al-logo">
              <Image src={AlLogo} fluid alt="al logo" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ChairmanMessage;
