import React, { useRef, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../redux/auth/action";
// UI Libraries
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/header-logo.png";
import ForgotPasswordModal from "../modal/forgotPassword/forgotPassword";
import ResendVerifyMail from "../modal/resendVerifyEmail/resendVerifyMail";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { loginSchema } from "../../validation/validationSchema";
import { config } from "../../config/config";
import "./login.css";

/**
 * Login Component
 * Renders a login form with email, password fields, and reCAPTCHA verification.
 * Includes forgot password and resend verification email modals.
 */
const Login = () => {
  const { t } = useTranslation();
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth);

  // State to manage modal visibility
  const [forgotPassShow, setforgotPassShow] = useState(false);
  const [resendEmailShow, setresendEmailShow] = useState(false);

  /**
   * React Hook Form setup
   * `register` - Registers form fields into the React Hook Form system.
   * `handleSubmit` - Handles form submission with validation.
   * `errors` - Contains validation error messages, if any.
   * `reset` - Resets form fields to their initial values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  /**
   * Handles form submission and dispatches login action.
   * @param {Object} data - Form data containing email and password.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data?.email,
      userPassword: data?.password,
    };

    // Dispatch login action and handle response
    const response = await dispatch(LoginUser(payload));
    if (response?.success) {
      toast.success(`${response?.message}`);
      navigate(
        response?.data?.isArbitrator ? "/dashboard/arbitrator" : "/dashboard",
      );
    }
  };
  const handleForgetModal = () => {
    setforgotPassShow(!forgotPassShow);
  };
  const closeForgetModal = () => {
    setforgotPassShow(false);
  };
  const handleResendModal = () => {
    setresendEmailShow(!resendEmailShow);
  };
  const closeResendModal = () => {
    setresendEmailShow(false);
  };

  /**
   * Updates the ReCAPTCHA value in the form state.
   * @param {string} value - The ReCAPTCHA token.
   */
  const handleCaptchaChange = (value) => {
    setValue("reCaptcha", value);
  };

  return (
    <>
      {user?.loader ? <FullPageLoader /> : null}
      <div className="login-form-area">
        <div className="login-form-main position-relative">
          <div className="logo-wrapper d-md-block d-none">
            <Image src={Logo} alt="RAK logo" width="350" fluid />
          </div>
          <div className="login-manage-wrapper">
            <div className="login-form">
              <div className="login-form-content">
                <h1 className="text-blue"></h1>
                <h2 className="mb-3">{t("loginSignIn")}</h2>
                <p className="mb-3">Please sign-in below to continue.</p>
                <ForgotPasswordModal
                  show={forgotPassShow}
                  handleClose={() => closeForgetModal()}
                />
                <ResendVerifyMail
                  show={resendEmailShow}
                  handleClose={() => closeResendModal()}
                />
                <Form
                  className="form-content"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group-wrapper">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("email")}</Form.Label>
                      <Form.Control
                        type="email"
                        {...register("email")}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 password-feild position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>{t("password")}</Form.Label>
                      <Form.Control
                        type="password"
                        {...register("password")}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={config?.recaptchaSiteKey}
                        onChange={handleCaptchaChange}
                      />
                      {errors.reCaptcha && (
                        <div className="invalid-feedback d-block">
                          {errors.reCaptcha?.message}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="remember-login mb-md-4 mb-3 d-flex flex-sm-row flex-column-reverse align-items-sm-center justify-content-sm-between mt-lg-5 gap-sm-0 gap-4">
                    <Button
                      variant="none"
                      onClick={() => handleForgetModal()}
                      className="forgot-link text-decoration-none text-left p-0"
                    >
                      Forgotten password ?
                    </Button>
                    <Button className="btn-blue" type="submit" variant="none">
                      {t("login")}
                    </Button>
                  </div>
                  <p className="member-text d-flex flex-wrap align-items-center justify-content-end text-end mb-0 mt-xxl-5 mt-lg-4 mt-3">
                    Didn't get Verification Email ?
                    <Button
                      variant="none"
                      className="text-blue p-0 ps-1 text-decoration-underline"
                      onClick={handleResendModal}
                    >
                      Resend Email
                    </Button>
                  </p>
                  <p className="member-text text-end mb-0 mt-lg-4 mt-3">
                    Are you a new member ?{" "}
                    <Link to={"/register"} className="text-decoration-none">
                      Create Your Account
                    </Link>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
