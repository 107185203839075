import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { aboutLawsBanner } from "../../components/images";
import AboutLaws from "./aboutLaws";
import Faq from "../../components/common/faq/faq";
import Footer from "../../components/common/footer/footer";

const Index = () => {
  return (
    <>
      <Header />
      <Banner
        img={aboutLawsBanner}
        title="The Laws"
        content="Arbitration in the United Arab Emirates (UAE) is a reliable and efficient method for resolving conflicts in a world where commercial disputes can arise quickly."
      />
      <AboutLaws />
      <Faq />
      <Footer />
    </>
  );
};

export default Index;
