import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./procedures.css";
import { alLogo, peopleMeeting } from "../../../components/images";

const Procedures = () => {
  return (
    <section className="procedure-followed">
      <Container fluid className="custom-container">
        <Row className="align-items-center flex-lg-row flex-column-reverse">
          <Col lg={7}>
            <div className="content-wrapper">
              <h2 className="mb-lg-4 mb-3">
                Procedures Followed By Ras Al Khaimah
              </h2>
              <p className="p-lg">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p>
                Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
                otent oevnec qdfruis libero molestie. Sed ut purus vel est
                pulvinar eleife ellensque eget arcu in enim condimentum
                pellentesque ornare id enim aecenas.
              </p>
              <ul className="general-list list-unstyled">
                <li>Sed ut purus vel est pulvinar eleifend.</li>
                <li>
                  Pellentesque eget arcu in enim condimentum pellentesque ornare
                  id enim.
                </li>
                <li>
                  Maecenas vitae dui quis lorem semper gravida ac sollicitudin
                  sapien.
                </li>
                <li>Maecenas iaculis tortor eu erat mattis suscipit.</li>
              </ul>
            </div>
          </Col>
          <Col lg={5}>
            <div className="position-relative text-end mb-lg-0 mb-sm-5 mb-4">
              <div className="meeting">
                <Image src={peopleMeeting} alt="meeting img" fluid />
              </div>
              <div className="al-logo position-absolute">
                <Image src={alLogo} fluid alt="al logo" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Procedures;
