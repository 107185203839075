import React from 'react'
import {Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "./sidebar.css"
import { headerLogo } from '../../images';

const E_Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <aside className='side-bar position-fixed'>
        <div className='logo-icon d-flex align-items-center justify-content-center'>
          <Image src={headerLogo} alt='Logo icon' fluid />
        </div>
        <div className='menu-wrapper w-100'>
          <ul className='menu-list list-unstyled'>
            {/* <li className='active position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/membership'}>{t("Memberships")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/arbitration'}>{t("Arbitration")}</Link>
            </li> */}
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/copy'} >{t("Certified Copy")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/interpretation'} >{t("Interpretation Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/intervention'} >{t("Intervention Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/joinder'} >{t("Joinder Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/claim'} >{t("claimStatement")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/objection'} >{t("Objection Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/revocation'} >{t("Arbitrator")} {t("Revocation Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/request/certificate'} >{t("Statement")} {t("Certificate Request")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/non-listed/arbitrator'} > {t('Nomination of')} {t("Non-listed")} {t("Arbitrator")}</Link>
            </li>
            <li className='position-relative' role='button'>
              <Link className="text-decoration-none text-white" to={'/dashboard/e-services/arbitrator/not-governed'} > {t('Arbitration')} {t("not governed")}</Link>
            </li>
          </ul>
        </div>
        {/* <button className='logout-btn d-flex align-items-center'>
          <Image src={LogOutIcon} alt='icon' fluid />
        </button> */}
      </aside>
      {/* <Row>
        <Link to={'/dashboard/e-services/membership'}>{t("Memberships")}</Link>
        <Link to={'/dashboard/e-services/request/arbitration'}>{t("Arbitration")}</Link>
        <Link to={'/dashboard/e-services/request/copy'} >{t("Certified Copy")}</Link>
        <Link to={'/dashboard/e-services/request/interpretation'} >{t("Interpretation Request")}</Link>
        <Link to={'/dashboard/e-services/request/intervention'} >{t("Intervention Request")}</Link>
        <Link to={'/dashboard/e-services/request/joinder'} >{t("Joinder Request")}</Link>
        <Link to={'/dashboard/e-services/request/claim'} >{t("claimStatement")}</Link>
        <Link to={'/dashboard/e-services/request/objection'} >{t("Objection Request")}</Link>
        <Link to={'/dashboard/e-services/request/revocation'} >{t("Arbitrator")} {t("Revocation Request")}</Link>
        <Link to={'/dashboard/e-services/request/certificate'} >{t("Statement")} {t("Certificate Request")}</Link>
        <Link to={'/dashboard/e-services/non-listed/arbitrator'} > {t('Nomination of')} {t("Non-listed")} {t("Arbitrator")}</Link>
        <Link to={'/dashboard/e-services/arbitrator/not-governed'} > {t('Arbitration')} {t("not governed")}</Link>
      </Row> */}
    </>
  );
};

export default E_Services;
