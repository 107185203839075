//////////////// Auth ///////////////////

export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const ERR_USER = "ERR_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

////////////// Users /////////////////////

export const UPDATE_USER = "UPDATE_USER";

///////////// E-Services ////////////////

export const CREATE_ARBITRATION = "CREATE_ARBITRATION";
export const CREATE_COPY = "CREATE_COPY";
export const CREATE_CORRECTION_REQUEST = "CREATE_CORRECTION_REQUEST";

///////////// Loader ///////////////////

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

///////////// Arbitrator services //////////////

export const ARBITRATOR_ESERVICES_APPLICATIONS =
  "ARBITRATOR_ESERVICES_APPLICATIONS";
export const ARBITRATOR_SERVICE_COUNT = "ARBITRATOR_SERVICE_COUNT";
