import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { course1 } from "../../../components/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "./trainingCourses.css";
function TrainingCourses() {
  return (
    <section className="training-courses">
      <Container fluid className="custom-container">
        <h4 className="text-blue mb-4">Training Courses</h4>
        <Row className="gy-3 justify-content-center">
          <Col lg={4} sm={6}>
            <Card className="info-card border-0">
              <Card.Img variant="top" src={course1} />
              <Card.Body>
                <span className="pre-heading">Arbitration</span>
                <h4 className="title">Settlement of Commercial Dispute</h4>
                <Card.Text>
                  Proin sit amet orci congue, facilisis msie ad uris sed,
                  ultrices ante. In semper lobortis mesve ki eget elementum eros
                  aliquet non.
                </Card.Text>
                {/* <Button variant="none" className='detail-btn'>Details</Button> */}
                <span
                  className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                  role="button"
                >
                  Details
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="info-card border-0">
              <Card.Img variant="top" src={course1} />
              <Card.Body>
                <span className="pre-heading">Arbitration</span>
                <h4 className="title">Settlement of Commercial Dispute</h4>
                <Card.Text>
                  Proin sit amet orci congue, facilisis msie ad uris sed,
                  ultrices ante. In semper lobortis mesve ki eget elementum eros
                  aliquet non.
                </Card.Text>
                {/* <Button variant="none" className='detail-btn'>Details</Button> */}
                <span
                  className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                  role="button"
                >
                  Details
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="info-card border-0">
              <Card.Img variant="top" src={course1} />
              <Card.Body>
                <span className="pre-heading">Arbitration</span>
                <h4 className="title">Settlement of Commercial Dispute</h4>
                <Card.Text>
                  Proin sit amet orci congue, facilisis msie ad uris sed,
                  ultrices ante. In semper lobortis mesve ki eget elementum eros
                  aliquet non.
                </Card.Text>
                {/* <Button variant="none" className='detail-btn'>Details</Button> */}
                <span
                  className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                  role="button"
                >
                  Details
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TrainingCourses;
