// ModalComponent.jsx
import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const ArbitratorResponse = ({
  showModal,
  handleCloseModal,
  selectedApplication,
  handleSendResponse,
  onFileChange,
  errDocument,
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Send Response</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formApplicationID">
          <Form.Label>Application ID</Form.Label>
          <Form.Control
            type="text"
            value={selectedApplication?.applicationId || ""}
            readOnly
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="file"
            onChange={(e) => onFileChange(e)}
          ></Form.Control>
        </Form.Group>
        <span className={errDocument ? `` : `d-none`}>
          <label className="pl-1 pt-0 text-danger">{errDocument}</label>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSendResponse}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArbitratorResponse;
