import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import servicesList1 from "../../../assets/images/services-slide1.png";

const ListOfServices = () => {
  return (
    <section className="list-of-services pb-5">
      <Container fluid className="custom-container">
        <div className="heading-sec text-center mb-xl-5 mb-md-4 mb-3 px-xl-5 pe-lg-4">
          <h2 className="mb-3">List Of Services Provided By Ras Al Khaimah</h2>
          <p className="mb-0">
            Arbitrators offer impartial resolution of disputes through
            confidential and efficient proceedings, providing an alternative to
            traditional legal processes for parties seeking fair and timely
            resolution. Their expertise in interpreting and applying relevant
            laws ensures a balanced and legally sound outcome for both parties
            involved.
          </p>
        </div>
        <Row>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={servicesList1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ListOfServices;
