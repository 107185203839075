import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import * as yup from "yup";
import { createFormRequest } from "../../../../../redux/e-services/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import FullPageLoader from "../../../../FullPageLoader/FullPageLoader";
import { useTranslation } from "react-i18next";
import E_Services from "../../e-services";
import { interpretationSchema } from "../../../../../validation/validationSchema";



const Interpretation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.e_services.loader);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(interpretationSchema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("serviceType", "interpretationRequest");
    formData.append("serviceData", JSON.stringify(data));

    Swal.fire({
      text: t("sureMessage"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("CancelButton"),
      confirmButtonText: t("SendButton"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(createFormRequest(formData));
        if (response?.success === true) {
          toast.success(t("successApiValidation"));
          reset();
        } else {
          toast.error(t("errorApiValidation"));
        }
      }
    });
  };

  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
        <h2>{t("INTERPRETATION REQUEST")}</h2>
        {loader && <FullPageLoader />}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>{t("Date")}</Form.Label>
            <Form.Control type="date" {...register("caseDate")} />
            <span className="text-danger">{errors.caseDate?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("caseNumber")}</Form.Label>
            <Form.Control type="text" {...register("caseNo")} />
            <span className="text-danger">{errors.caseNo?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Legal Representative")}</Form.Label>
            <Form.Control type="text" {...register("legalRepresentative")} />
            <span className="text-danger">{errors.legalRepresentative?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control type="email" {...register("email")} />
            <span className="text-danger">{errors.email?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control type="text" {...register("phoneNumber")} />
            <span className="text-danger">{errors.phoneNumber?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Award Date Of Issuance")}</Form.Label>
            <Form.Control type="date" {...register("awardDateOfIssuance")} />
            <span className="text-danger">{errors.awardDateOfIssuance?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Award Date Of Receipt")}</Form.Label>
            <Form.Control type="date" {...register("awardDateOfReceipt")} />
            <span className="text-danger">{errors.awardDateOfReceipt?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Interpretation Subject")}</Form.Label>
            <Form.Control as="textarea" {...register("interpretationSubject")} />
            <span className="text-danger">{errors.interpretationSubject?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Signature Date")}</Form.Label>
            <Form.Control type="date" {...register("signatureDate")} />
            <span className="text-danger">{errors.signatureDate?.message}</span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Signature")}</Form.Label>
            <Form.Control type="text" {...register("signature")} />
            <span className="text-danger">{errors.signature?.message}</span>
          </Form.Group>

          <Button variant="primary" type="submit">
            {t("Save Changes")}
          </Button>
          <Link to="/dashboard/e-services">{t("Back to e-services")}</Link>
        </Form>
      </div>
    </div>
  );
};

export default Interpretation;
