import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEServices,
  createResponse,
  arbitratorClosedResponse,
} from "../../../../../redux/arbitrator/e-services/action";
import { doubleString } from "../../../../../utils/validations/validation";
import { helperFunction } from "../../../../../utils/helperFunctions";
import { Card, Button } from "react-bootstrap";
import ArbitratorResponse from "../../../modal/arbitratorResponse/response";
import ApplicationStatus from "./applicationStatus";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { fileValidation } from "../../../../../utils/validations/validation";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const UserApplication = () => {
  const { allowedMediaTypes } = helperFunction;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const arbitrator = useSelector((state) => state.Arbitrator);
  const userKey = helperFunction.getUserKey("userId")
    ? doubleString(helperFunction.getUserKey("userId"))
    : "";
  const [document, setdocument] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [errDocument, setErrDocument] = useState("");
  const [Flag, setFlag] = useState(false);

  useEffect(() => {
    if (userKey) {
      dispatch(getAllEServices(userKey));
    }
  }, [userKey, Flag]);

  const handleShowModal = (application) => {
    setSelectedApplication(application);
    setShowModal(true);
    navigate('/dashboard/arbitrator/application/status');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrDocument(null);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileErr = fileValidation(file, allowedMediaTypes.images);
      if (fileErr) {
        toast.error(
          `Supported File Types: ${allowedMediaTypes.images.join(", ")}`,
        );
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setdocument(file);
    }
  };

  const handleSendResponse = () => {
    let check = true;
    if (!document) {
      setErrDocument("You have to upload document");
      check = false;
    }
    if (check) {
      const formData = new FormData();
      formData.append("responseDocuments", document);
      formData.append("arbitratorId", selectedApplication.arbitratorId);
      formData.append("eServiceId", selectedApplication.id);
      dispatch(createResponse(formData));
      handleCloseModal();
    }
    // handleCloseModal();
  };
  const handleCloseResponse = (id) => {
    if (id) {
      const payload = {
        id: id,
      };
      Swal.fire({
        // title: 'Are you sure?',
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(arbitratorClosedResponse(payload));
            if (data?.success === true) {
              toast.success("Application is closed now");
              setFlag(true);
            } else {
              toast.error(t("errorApiValidation"));
            }
          }
        }
      });
    }
  };

  return (
    <>
      {arbitrator.applications.filteredServices &&
      arbitrator.applications.filteredServices.some(
        (data) => data.serviceStatus !== "closed",
      ) ? (
        arbitrator.applications.filteredServices.map((data, key) => {
          if (data.serviceStatus !== "closed") {
            return (
              <div style={{ borderBottom: "1px solid #000" }} key={key}>
                <Card role="button" onClick={() => handleShowModal(data)}>
                  <Card.Header>
                    Name of e-service : {data.serviceType}
                  </Card.Header>
                  <Card.Body>
                    <p>arbitrator Id : {data.arbitratorId}</p>
                    <p> application Id : {data.applicationId}</p>
                  </Card.Body>
                  <Card.Footer></Card.Footer>
                </Card>
                <Button onClick={() => handleCloseResponse(data.id)}>
                  Mark as Completed
                </Button>
              </div>
            );
          } else {
            return null;
          }
        })
      ) : (
        <div>No Applications found</div>
      )}
      <ArbitratorResponse
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedApplication={selectedApplication}
        handleSendResponse={handleSendResponse}
        onFileChange={onFileChange}
        errDocument={errDocument}
      />
      <ApplicationStatus
            applicationData={selectedApplication} />
    </>
  );
};

export default UserApplication;
