import React from "react";
import Header from "../../components/common/header/header";
import Banner from "./banner/banner";
import OurVision from "../../components/common/ourVision/ourVision";
import ChairmanMessage from "./chairmanMessage/chairmanMessage";
import About from "./about/about";
import OurServices from "./ourServices/ourServices";
import Stats from "./stats/stats";
import LatestNews from "../../components/common/latestNews/latestNews";
import EventPollsCalculator from "./eventPollsCalculator/eventPollsCalculator";
import Faq from "../../components/common/faq/faq";
import Footer from "../../components/common/footer/footer";

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <OurVision />
      <ChairmanMessage />
      <About />
      <OurServices />
      <Stats />
      <LatestNews mainTitle="Latest News" />
      <EventPollsCalculator />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
