import React, { useState } from "react";
import langIcon from "../../../assets/images/icon-lang.svg";
import userIcon from "../../../assets/images/icon-user.svg";
import {
  Container,
  Dropdown,
  DropdownHeader,
  DropdownMenu,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./header.css";
import { config } from "../../../config/config";
import { useTranslation } from "react-i18next";
import { headerLogo } from "../../images";

function Header() {
  const { i18n } = useTranslation();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);

  const handleTranslate = () => {
    const nextLanguageIndex = (currentLanguageIndex + 1) % config.lngs.length;
    const nextLanguageCode = config.lngs[nextLanguageIndex].code;
    i18n.changeLanguage(nextLanguageCode);
    setCurrentLanguageIndex(nextLanguageIndex);
  };
  return (
    <section className="header postion-relative">
      <Container className="custom-container" fluid>
        <div className="d-flex py-2">
          <Navbar expand="lg" className="flex-fill">
            <Container fluid className="px-0">
              <Navbar.Brand href="/home" className="p-0">
                <Image src={headerLogo} alt="logo" fluid />
              </Navbar.Brand>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="d-lg-block d-none"
              >
                <Nav className="ms-auto my-2 my-lg-0 align-items-center">
                  <Nav.Link href="/home">Home</Nav.Link>
                  <NavDropdown title="e-Services" className="e-services">
                    <NavDropdown.Item href="/membership">
                      Memberships
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/arbitration">
                      Arbitration Request Form
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/claim">
                      Claim Statement Form
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/dashboard/e-services/request/interpretation">
                                            Request To Correct/Interpret The Arbitration Award</NavDropdown.Item> */}
                    <NavDropdown.Item href="#action4">
                      Request The Arbitrator’s Response
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/intervention">
                      Intervention Request Form
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/interpretation">
                      Interpretation Request Form
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/joinder">
                      Joinder Request Form
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Objection Request
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/dashboard/e-services/request/copy">
                      Request A True Copy Of The Ruling
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action3">
                      Request A Statement/Certificate
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Request To Select An Arbitrator Outside The Center’s List
                      Of Accredited Arbitrators
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      An Arbitration Request That Is Not Subject To The Centre’s
                      Rules And Regulations
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="About Us">
                    <NavDropdown.Item href="/jury-panel">
                      Jury Panel
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/about-laws">
                      The Laws
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Services">
                    <NavDropdown.Item href="/arbitration">
                      Arbitration
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mediation">
                      Mediation
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/departments-Of-Center">
                      Departments Of The Center
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/center-tables">
                      Center Tables
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/media-center">Media Center</Nav.Link>
                  <Nav.Link href="/contact-us">Contact us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav className="flex-row gap-2 me-2">
                <Nav.Item
                  role="button"
                  onClick={(event) => handleTranslate(event)}
                >
                  <Image src={langIcon} alt="icon" fluid />
                </Nav.Item>
                <Nav.Item role="button">
                  <NavLink to="/login">
                    <Image src={userIcon} alt="icon" fluid />
                  </NavLink>
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>
          <Navbar expand="false">
            <Container fluid className="p-0">
              <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" />
              <Navbar.Offcanvas
                id="offcanvasNavbar-expand-false"
                aria-labelledby="offcanvasNavbarLabel-expand-false"
                placement="end"
              >
                <Offcanvas.Header
                  closeButton
                  className="p-lg-4 p-3 justify-content-end"
                >
                  {/* <Offcanvas.Title className='text-blue' id="offcanvasNavbarLabel-expand-false">
                                    </Offcanvas.Title> */}
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0 px-4">
                  <Nav className="ms-auto mb-4 d-lg-none ">
                    <Nav.Link href="/home">Home</Nav.Link>
                    <NavDropdown title="e-Services" className="e-services">
                      <NavDropdown.Item href="/membership">
                        Memberships
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action4">
                        Arbitration Request Form
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/e-services/request/claim">
                        Claim Statement Form
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item href="/dashboard/e-services/request/interpretation">
                                                Request To Correct/Interpret The Arbitration Award</NavDropdown.Item> */}
                      <NavDropdown.Item href="#action4">
                        Request The Arbitrator’s Response
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/e-services/request/intervention">
                        Intervention Request Form
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/e-services/request/interpretation">
                        Interpretation Request Form
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/e-services/request/joinder">
                        Joinder Request Form
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action4">
                        Objection Request
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/e-services/request/copy">
                        Request A True Copy Of The Ruling
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action3">
                        Request A Statement/Certificate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action4">
                        Request To Select An Arbitrator Outside The Center’s
                        List Of Accredited Arbitrators
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action5">
                        An Arbitration Request That Is Not Subject To The
                        Centre’s Rules And Regulations
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="About Us">
                      <NavDropdown.Item href="/jury-panel">
                        Jury Panel
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/about-laws">
                        The Laws
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Services">
                      <NavDropdown.Item href="/arbitration">
                        Arbitration
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/mediation">
                        Mediation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/departments-Of-Center">
                        Departments Of The Center
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/center-tables">
                        Center Tables
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/media-center">Media Center</Nav.Link>
                    <Nav.Link href="/contact-us">Contact us</Nav.Link>
                  </Nav>
                  <h3 className="text-blue mb-3">Useful Link</h3>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="#action1">
                      Frequently Asked Questions
                    </Nav.Link>
                    <Nav.Link href="#action2">Advertisement Areas</Nav.Link>
                    <Nav.Link href="#action3">Terms And Conditions</Nav.Link>
                    <Nav.Link href="#action4">Privacy Policy</Nav.Link>
                    <Nav.Link href="#action5">Usage Policy</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </Container>
    </section>
  );
}

export default Header;
