import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./accountingCosts.css";
const AccountingCosts = () => {
  return (
    <section className="accounting-cost">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={8} className="pe-xxl-5 pe-xl-4">
            <h2 className="mb-3">Fees For Arbitration And Accounting Costs</h2>
            <p>
              Following the submission of the Application for Arbitration (the
              Application), should the Centre determine the Application to
              ultimately be not eligible for registration under the DIAC
              Arbitration Rules and/or the applicable Centre’s policies, the
              Registration Fee will not be refunded.
            </p>
            <h6 className="text-blue mb-2">REGISTRATION FEE</h6>
            <h6 className="text-yellow mb-3">AED 5,000</h6>
            <p>
              A request for arbitration, the introduction of a counterclaim and
              an application for emergency interim relief must in each case be
              accompanied by the applicable non-refundable registration fees
              specified in the Table in force on the date of the commencement of
              the arbitration.
            </p>
            <h6 className="text-blue">COSTS OF ARBITRATION</h6>
            <p>
              n accordance with Article 2.1 of Appendix I to the DIAC
              Arbitration Rules in conjunction with Article 40.3 of the DIAC
              Arbitration Rules, the advance on the Tribunal’s fees is fixed by
              the Centre at the average amount calculated by reference to the
              minimum and maximum values indicated in DIAC Table of Fees and
              Costs corresponding the total of the sum(s) claimed and/or
              counterclaimed. In addition, a sum equivalent to 20% of the
              advance on the Tribunal’s fees is added to the advance on costs of
              the arbitration, which is intended to cover the expenses of the
              Tribunal.
            </p>
          </Col>
          <Col lg={4}>
            <div className="fee-structure">
              <h6 className="text-blue">PAYMENT OF FEES AND COSTS</h6>
              <p>
                Payment of fees and costs could be made by bank transfer at the
                following details:
              </p>
              <ul className="detail-list list-unstyled mb-0">
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">Account</h6>
                  <p className="mb-0">Dubai International Arbitration Centre</p>
                </li>
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">Bank</h6>
                  <p className="mb-0">Emirates NBD</p>
                </li>
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">Account number</h6>
                  <p className="text-break mb-0">1015826070506</p>
                </li>
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">IBAN</h6>
                  <p className="text-break mb-0">AE890260001015826070506</p>
                </li>
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">SWIFT</h6>
                  <p className="mb-0">EBILAEAD</p>
                </li>
                <li className="d-flex mb-3">
                  <h6 className="title flex-shrink-0">Currency:</h6>
                  <p className="mb-0">AED</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AccountingCosts;
