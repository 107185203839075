import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import NotFound from "./components/404Page/404Page";
import Login from "./components/login/login";
import Profile from "./components/userDashboard/profile/profile";
import Register from "./components/register/register";
import Dashboard from "./components/userDashboard/dashboard";
import ProtectedRoute from "./utils/protectedRoutes/protectedRoutes";
import Admin from "./layouts/admin";
import Auth from "./layouts/auth";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import LoginForm from "./pages/auth/LoginForm";
import AboutUs from "./pages/aboutUs";
import News from "./pages/news";
import MediaCenter from "./pages/mediaCenter";
import AboutLaws from "./pages/aboutLaws/index";
import JuryPanel from "./pages/juryPanel";
import Services from "./pages/services";
import ArbitrationPage from "./pages/arbitration";
import DepartmentsOfCenter from "./pages/departmentsOfCenter";
import Mediation from "./pages/mediation";
import ResetPasswordComponent from "./components/resetPassword/resetPassword";
import E_Services from "./components/userDashboard/e-services/e-services";
import Arbitration from "./components/userDashboard/e-services/request/arbitration/arbitration";
import CertifiedCopy from "./components/userDashboard/e-services/request/certifiedCopy/certifiedCopy";
import Interpretation from "./components/userDashboard/e-services/request/interpretation/interpretation";
import Memberships from "./components/userDashboard/e-services/memberships/memberships";
import ExpertRegistration from "./components/userDashboard/e-services/memberships/expertComponent/expertRegistration/expertRegistration";
import ArbitratorRegistration from "./components/userDashboard/e-services/memberships/arbitratorComponent/arbitratorRegistration/arbitratorRegistraion";
import ArbitratorDashboard from "./components/arbitrator/dashboard/dashboard";
import ArbitratorProfile from "./components/arbitrator/dashboard/profile.js/profile";
import Intervention from "./components/userDashboard/e-services/request/intervention/intervention";
import Joinder from "./components/userDashboard/e-services/request/joinder/joinder";
import Claim from "./components/userDashboard/e-services/request/claim/claim";
import UserApplication from "./components/arbitrator/dashboard/applications/userApplications/userApplication";
import ApplicationStatus from "./components/arbitrator/dashboard/applications/userApplications/applicationStatus";
import CenterTable from "./pages/centerTables";
import Objection from "./components/userDashboard/e-services/request/objection/objection";
import Revocation from "./components/userDashboard/e-services/request/revocation/revocation";
import Certificate from "./components/userDashboard/e-services/request/certificate/certificate";
import Membership from "./pages/membership";
import NonListedArbitrator from "./components/userDashboard/e-services/nonListedArbitrator/nonListedArbitrator";
import ArbitratorNotGoverned from "./components/userDashboard/e-services/arbitratorNotGoverned/arbitratorNotGoverned";
import ContactUs from "./pages/contactUs";
import MembershipRegistration from "./components/userDashboard/e-services/memberships/membershipRegistration/membershipRegistration";
import Events from "./pages/mediaCenter/events/events";
function App() {
  const router = createBrowserRouter([
    {
      element: <Admin />,
      errorElement: <Navigate to="/login" />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/login2",
          element: <LoginForm />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/resetPassword",
          element: <ResetPasswordComponent />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/events",
          element: <Events />,
        },
        {
          path: "/news",
          element: <News />,
        },
        {
          path: "/media-center",
          element: <MediaCenter />,
        },
        {
          path: "/about-laws",
          element: <AboutLaws />,
        },
        {
          path: "/jury-panel",
          element: <JuryPanel />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/arbitration",
          element: <ArbitrationPage />,
        },
        {
          path: "/mediation",
          element: <Mediation />,
        },
        {
          path: "/center-tables",
          element: <CenterTable />,
        },
        {
          path: "membership",
          element: <Membership />,
        },
        {
          path: "departments-Of-Center",
          element: <DepartmentsOfCenter />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
      ],
    },
    {
      element: <Auth />,
      children: [
        {
          path: "/dashboard",
          element: (
            <ProtectedRoute>
            <Dashboard />
             </ProtectedRoute>
          ),
        },
        {
          path: "/profile",
          element: (
            // <ProtectedRoute>
            <Profile />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/arbitration",
          element: (
            // <ProtectedRoute>
            <Arbitration />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/copy",
          element: (
            // <ProtectedRoute>
            <CertifiedCopy />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/interpretation",
          element: (
            // <ProtectedRoute>
            <Interpretation />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/intervention",
          element: (
            // <ProtectedRoute>
            <Intervention />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/joinder",
          element: (
            // <ProtectedRoute>
            <Joinder />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/claim",
          element: (
            // <ProtectedRoute>
            <Claim />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/objection",
          element: (
            // <ProtectedRoute>
            <Objection />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/revocation",
          element: (
            // <ProtectedRoute>
            <Revocation />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/request/certificate",
          element: (
            // <ProtectedRoute>
            <Certificate />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/non-listed/arbitrator",
          element: (
            // <ProtectedRoute>
            <NonListedArbitrator />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/arbitrator/not-governed",
          element: (
            // <ProtectedRoute>
            <ArbitratorNotGoverned />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/membership",
          element: (
            // <ProtectedRoute>
            <Memberships />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/membership/arbitrator",
          element: (
            // <ProtectedRoute>
            <ArbitratorRegistration />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/membership/expert-registration",
          element: (
            // <ProtectedRoute>
            <ExpertRegistration />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services/membership/membership",
          element: (
            // <ProtectedRoute>
            <MembershipRegistration />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/e-services",
          element: (
            // <ProtectedRoute>
            <E_Services />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/arbitrator",
          element: (
            // <ProtectedRoute>
            <ArbitratorDashboard />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/arbitrator/profile",
          element: (
            // <ProtectedRoute>
            <ArbitratorProfile />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/arbitrator/application",
          element: (
            // <ProtectedRoute>
            <UserApplication />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard/arbitrator/application/status",
          element: (
            // <ProtectedRoute>
            <ApplicationStatus />
            // </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
