import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import iconVision from "../../../assets/images/icon-vision.svg";
import iconMission from "../../../assets/images/icon-mission.svg";
import iconObjectives from "../../../assets/images/icon-objectives.svg";
import "./ourVision.css";
function OurVision() {
  const content = [
    {
      id: 1,
      img: iconVision,
      heading: "Our Vision",
      detail:
        "Xiorem ipsum dolor sit amet, coectetur adipci ng de elit. Pellentesque porttitor elit arcu, vitae mollis diam aliquam non otent oevnec qdfruis libero molestie.",
    },
    {
      id: 2,
      img: iconMission,
      heading: "Our Mission",
      detail:
        "Pellentesque porttitor elit arcu, vitae mollis dia mrvrs aliquam noniorem ipsum dolor sit amet, tetur ading elit. Suspendisse potent oevnec qdfruis libero molestie.",
    },
    {
      id: 3,
      img: iconObjectives,
      heading: "Our Objectives",
      detail:
        "Pellentesque porttitor elit arcu, vitae mollis dia mrvrs aliquam noniorem ipsum dolor sit amet, tetur ading elit. Suspendisse potent oevnec qdfruis libero molestie.",
    },
  ];

  return (
    <section className="our-vision">
      <Container className="custom-container" fluid>
        <Row className="justify-content-center">
          {content.map((item) => (
            <Col key={item.id} lg={4} md={6}>
              <div className="content-wrapper">
                <div className="icon-wrapper d-md-flex align-items-end me-md-0 me-3">
                  <Image src={item.img} alt="icon" fluid />
                </div>
                <div className="text-wrapper">
                  <h4>{item.heading}</h4>
                  <p className="mb-0">{item.detail}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default OurVision;
