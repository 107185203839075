import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./eventCard.css";
const EventCard = ({ date, dateMonth, title, city, state, content }) => {
  return (
    <div className="event-card-wrapper d-flex mb-3 pb-3">
      <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
        <p className="text-white mb-0">
          <span className="fw-semibold">{date}</span>
          <br /> {dateMonth}
        </p>
      </div>
      <div className="content-box">
        <h6 className="mb-2">{title}</h6>
        <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
          <FontAwesomeIcon icon={faLocationDot} color="#1A65AD" />
          <span>
            {city} - {state}
          </span>
        </div>
        <p className="mb-0">{content}</p>
      </div>
    </div>
  );
};

export default EventCard;
