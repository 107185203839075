import React from "react";
import { Container, Image } from "react-bootstrap";
import { translationSchedule } from "../../../components/images";
import "./translationSchedule.css";

const TranslationSchedule = () => {
  return (
    <section className="translation-schedule">
      <Container fluid className="custom-container">
        <h2 className="mb-3">Translation Schedule And Entry Conditions</h2>
        <div className="img-wrapper mb-4">
          <Image src={translationSchedule} alt="Schedule img" fluid />
        </div>
        <p>
          Xiorem ipsum dolor sit amet, coectetur adipci ng de elit. Pellentesque
          porttitor elit arcu, vitae mollis diam aliquam non otent oevnec
          qdfruis libero molestie. Sed ut purus vel est pulvinar eleife
          ellensque eget arcu in enim condimentum pellentesque ornare id enim
          aecenaste. In semper lobortis metus, eget elementum eros aliquet non.
          Integer.
        </p>
        <p>
          Xiorem ipsum dolor sit amet, coectetur adipci ng de elit. Pellentesque
          porttitor elit arcu, vitae mollis diam aliquam non otent oevnec
          qdfruis libero molestie. Sed ut purus vel est pulvinar eleife
          ellensque eget arcu in enim condimentum pellentesque ornare id enim
          aecenaste. In semper lobortis metus, eget elementum eros aliquet non.
          Integer. am blandit placerat nunc, at auctor nisl elementum eu.
          Maecenas pretium accumsan finibus. Nam et scelerisque lacus, ac
          laoreet leo. In molestie justo in neque tincidunt, a eleifend metus
          ultricies. Integer at euismod dui. Donec aliquam tortor diam, eget
          dignissim lectus ullamcorper eu. Curabitur ac odio ultricies augue
          eleifend ultricies. Sed porttitor ante sodales sem accumsan, in
          aliquet elit pellentesque. Sed at velit sit amet lorem pretium
          bibendum. Donec ac odio vel metus sodales vestibulum a sed elit.
        </p>
        <p>
          <span className="text-blue fw-semibold">
            Aliquam sodales congue sodales. Praesent pellentesque magna eu
            ultricies luctus.
          </span>{" "}
          Proin id interdum nibh, quis fermentum quam. Sed id ligula ut eros
          volutpat malesuada sed porta nisl. Nunc blandit, diam auctor sodales
          sagittis, ex nisi varius lacus, vitae malesuada nisi neque quis urna.
          Pellentesque dapibus elementum erat, ut faucibus nisi ullamcorper ac.
          Vivamus quis metus mollis, pulvinar ipsum in, semper enim. In hac
          habitasse platea dictumst.
        </p>
        <h4>Entry Conditions</h4>
        <p>
          Pellentesque ornare id enim aecenaste. In semper lobortis metus, eget
          elementum eros aliquet non. Integer. am blandit placerat nunc, at
          auctor nisl elementum eu. Maecenas pretium accumsan finibus. Nam et
          scelerisque lacus, ac laoreet leo. In molestie justo in neque
          tincidunt, a eleifend metus ultricies. Integer at euismod dui. Donec
          aliquam tortor diam, eget dignissim lectus ullamcorper eu. Curabitur
          ac odio ultricies augue eleifend ultricies.
        </p>
        <p>
          Ornare entesque ornare id enim aecenaste. In semper lobortis metus,
          eget elementum eros aliquet non. Integer. am blandit placerat nunc, at
          auctor nisl elementum eu. Maecenas pretium accumsan finibus. Nam et
          scelerisque lacus, ac laoreet leo.
        </p>
        <p>
          “We are confident that our UAE banking sector can make significant
          contributions to the UAE’s National Climate Change Plan. UBF’s
          significance lies not only in its commitment to supporting
          sustainability, but also in its role as the platform for cooperation
          among all its UBF member banks. The Federation seeks to accelerate
          progress in this domain by fostering a business environment that
          encourages competitive, active, and sustainable finance,”
        </p>
        <p>
          he said He added, “Under the direct supervision of Central Bank of the
          UAE, our banking sector continues to consolidate its position as a
          regional and global leader, which places a great responsibility on us
          to maintain such position and to build on such solid base to achieve
          higher goals and targets.” UBF Director-General affirmed, “In addition
          to financial commitments, UBF recognises the pivotal role of
          digitalisation and advanced technologies in driving global climate
          action. UBF, through its involvement in several initiatives, including
          this COP28 UAE Banks Pledge, actively promotes innovative technology
          solutions in sustainable finance to achieve sustainable development
          goals.”
        </p>
      </Container>
    </section>
  );
};

export default TranslationSchedule;
