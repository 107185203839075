import React from "react";
import "./event.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
function Event() {
  return (
    <div className="event-container mb-xl-0 mb-4">
      <div className="event-card-wrapper d-flex mb-3 pb-3">
        <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
          <p className="text-white mb-0">
            <span className="fw-semibold">07</span>
            <br /> FEB 2023
          </p>
        </div>
        <div className="content-box">
          <h6 className="mb-2">Hainan International Tropical Food</h6>
          <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
            <FontAwesomeIcon icon={faLocationDot} color="#1A65AD" />
            <span>Hainan City - China</span>
          </div>
          <p className="mb-0">
            Tropical Food Supply Chain, which will be held from
            10-12-15/10/2023.
          </p>
        </div>
      </div>
      <div className="event-card-wrapper d-flex mb-3 pb-3">
        <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
          <p className="text-white mb-0">
            <span className="fw-semibold">07</span>
            <br /> FEB 2023
          </p>
        </div>
        <div className="content-box">
          <h6 className="mb-2">Hainan International Tropical Food</h6>
          <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
            <FontAwesomeIcon icon={faLocationDot} color="#1A65AD" />
            <span>Hainan City - China</span>
          </div>
          <p className="mb-0">
            Tropical Food Supply Chain, which will be held from
            10-12-15/10/2023.
          </p>
        </div>
      </div>
      <div className="event-card-wrapper d-flex mb-3 pb-3">
        <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
          <p className="text-white mb-0">
            <span className="fw-semibold">07</span>
            <br /> FEB 2023
          </p>
        </div>
        <div className="content-box">
          <h6 className="mb-2">Hainan International Tropical Food</h6>
          <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
            <FontAwesomeIcon icon={faLocationDot} color="#1A65AD" />
            <span>Hainan City - China</span>
          </div>
          <p className="mb-0">
            Tropical Food Supply Chain, which will be held from
            10-12-15/10/2023.
          </p>
        </div>
      </div>
      <div className="event-card-wrapper d-flex pb-3">
        <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
          <p className="text-white mb-0">
            <span className="fw-semibold">07</span>
            <br /> FEB 2023
          </p>
        </div>
        <div className="content-box">
          <h6 className="mb-2">Hainan International Tropical Food</h6>
          <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
            <FontAwesomeIcon icon={faLocationDot} color="#1A65AD" />
            <span>Hainan City - China</span>
          </div>
          <p className="mb-0">
            Tropical Food Supply Chain, which will be held from
            10-12-15/10/2023.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Event;
