import React from "react";
import Select from "react-select";

const SelectGroup = ({ options, value, onChange }) => {
  return (
    <>
      <Select options={options} value={value} onChange={onChange} />
    </>
  );
};

export default SelectGroup;
