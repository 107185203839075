import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { arbiratorWoman } from "../../../components/images";
import "./legalAdvice.css";
function LegalAdvice() {
  return (
    <section className="legal-advice">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={6} className="position-relative">
            <div className="arbirator-woman">
              <Image
                src={arbiratorWoman}
                alt="arbirator woman"
                fluid
                className="h-100"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="content-wrapper">
              <div className="mb-xl-5 mb-4">
                <strong className="advice-text text-uppercase fw-bold">
                  LEGAL ADVICE
                </strong>
                <p className="p-lg">
                  Our team of highly skilled and experienced arbitration lawyers
                  in the UAE is dedicated to providing top-notch legal
                  assistance.
                </p>
                <p>
                  Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                  Pellentesque porttitor elit arcu, vitae mollis diam aliquam
                  non otent oevnec qdfruis libero molestie. Sed ut purus vel est
                  pulvinar eleife ellensque eget arcu in enim condimentum
                  pellentesque ornare id enim aecenaste. In semper lobortis
                  metus, eget elementum eros aliquet non. Integer.
                </p>
              </div>
              <Button variant="none" className="btn-blue">
                FREE CONSULTATION
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LegalAdvice;
