import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./customTabs.css";
import ExpertRegistration from "../expertRegistration/expertRegistration";
// import MembershipRegistration from '../../../components/userDashboard/e-services/memberships/membershipRegistration/membershipRegistration';
import ArbitratorRegistration from "../../../components/userDashboard/e-services/memberships/arbitratorComponent/arbitratorRegistration/arbitratorRegistraion";
// import ExpertRegistration from '../expertRegistration/expertRegistration';

const CustomTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <h2 className="mb-4">Membership</h2>
      <div className="tab-buttons d-flex gap-lg-3 gap-2 flex-lg-nowrap flex-wrap">
        <Button
          variant="none"
          onClick={() => handleTabClick("tab1")}
          className={`tab-btn ${activeTab === "tab1" ? "active" : ""}`}
        >
          Expert Registration New/ Renewal
        </Button>
        <Button
          variant="none"
          onClick={() => handleTabClick("tab2")}
          className={`tab-btn ${activeTab === "tab2" ? "active" : ""}`}
        >
          Arbitrator Registration New/ Renewal
        </Button>
        <Button
          variant="none"
          onClick={() => handleTabClick("tab3")}
          className={`tab-btn ${activeTab === "tab3" ? "active" : ""}`}
        >
          Membership Registration New/ Renewal
        </Button>
      </div>
      <div>
        {activeTab === "tab1" && <ExpertRegistration />}
        {activeTab === "tab2" && <ArbitratorRegistration />}
        {activeTab === "tab3" && <p>Content for Tab 3</p>}
      </div>
    </>
  );
};

export default CustomTabs;
