import React from "react";
import "./newsCard.css";
import { Col, Image } from "react-bootstrap";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewsCard = ({ img, preTitle, title, content }) => {
  return (
    <Col lg={6} className="mb-3">
      <div className="news-article-wrapper d-flex h-100" role="button">
        <div className="news-article-img">
          <Image src={img} fluid alt="article image" className="h-100 w-100" />
        </div>
        <div className="news-article-content">
          <span className="pre-heading d-inline-block mb-2">{preTitle}</span>
          <h4 className="mb-lg-3 mb-2">{title}</h4>
          <p className="mb-lg-3 mb-2">{content}</p>
          <span
            className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
            role="button"
          >
            Details
            <FontAwesomeIcon icon={faArrowRightLong} />
          </span>
        </div>
      </div>
    </Col>
  );
};

export default NewsCard;
