import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EventCard from "../../../components/common/eventCard/eventCard";
import "./workshop.css";

const Workshop = () => {
  const cardContent = [
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
    {
      date: "07",
      dateMonth: "FEB 2023",
      title: "Hainan International Tropical Food",
      city: "Hainan City",
      state: "China",
      content:
        "Tropical Food Supply Chain, which will be held from 10-12-15/10/2023.",
    },
  ];
  return (
    <section className="workshop-sec">
      <Container fluid className="custom-container">
        <h4 className="text-blue mb-4">Workshop</h4>
        <Row>
          {cardContent.map((list,index) => (
            <Col lg={4} md={6} key={index}>
              <EventCard
                date={list.date}
                dateMonth={list.dateMonth}
                title={list.title}
                city={list.city}
                state={list.state}
                content={list.content}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Workshop;
