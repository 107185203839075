import React from "react";
import { businessmanSpeaker } from "../../../components/images";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./advantages.css";

const Advantages = () => {
  return (
    <section className="advantages-sec">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={6} className="position-relative">
            <div className="member-img pe-3 text-center">
              <Image
                src={businessmanSpeaker}
                alt="members img"
                fluid
                className="h-100"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="content-wrapper">
              <h2 className="mb-0">Arbitration At Ras Al Khaimah</h2>
              <strong className="title text-uppercase d-inline-block">
                ADVANTAGES
              </strong>
              <p className="p-lg">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p>
                International Commercial Arbitration Centre " Tahkeem" welcomes
                specialists from all levels in the field of commercial disputes
                settlement. The Centre offers membership and fellowship to all
                professionals; such as advocates, experts and lawyers all
                specializations as well as engineers, accountants, businessmen,
                representatives of trade authorities and institutions,
                international arbitrators and mediators. Members of the Centre
                enjoys several privileges according to the type of membership as
                follows:
              </p>
              <ul className="general-list list-unstyled mb-4">
                <li>Expert’s Membership.</li>
                <li>Arbitrator’s Membership.</li>
                <li>Centre’s Membership.</li>
              </ul>
              <Button variant="none" className="btn-blue">
                Contact Us
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Advantages;
