// RadioButtonGroup.js
import React from "react";
import { Form } from "react-bootstrap";

const RadioButtonGroup = ({
  options,
  selectedValue,
  setSelectedValue,
  groupName,
  type = null,
}) => {
  return (
    <>
      {options.map((option) => (
        <Form.Check
          key={option}
          type={type !== null ? type : "radio"}
          name={groupName}
          label={option.charAt(0).toUpperCase() + option.slice(1)}
          checked={selectedValue?.includes(option)}
          onChange={() => setSelectedValue(option)}
        />
      ))}
    </>
  );
};

export default RadioButtonGroup;
