import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./mediationTypes.css";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import {
  bindingArbitration,
  mandatoryArbitration,
  nonBindingArbitration,
} from "../../../components/images";

const MediationTypes = () => {
  const cardContent = [
    {
      img: bindingArbitration,
      title: "Binding Arbitration",
      content:
        "Both parties appoint an independent arbitrator and they are bound by the final decision.",
    },
    {
      img: nonBindingArbitration,
      title: "Non-Binding Arbitration",
      content:
        "Each party reserves the right to litigate the case in court if they are dissatisfied with the outcome.",
    },
    {
      img: mandatoryArbitration,
      title: "Mandatory Arbitration",
      content:
        "A judge may appoint an arbitrator early in litigation if the amount in dispute is too small to justify protracted litigation.",
    },
  ];
  return (
    <section className="mediation-types">
      <Container fluid className="custom-container">
        <div className="heading-sec text-center mb-lg-5 mb-4">
          <h2 className="mb-3">Types Of Arbitration</h2>
          <p className="mb-0">
            Broadly, there are three types of arbitration available within the
            United Arab Emirates to settle a dispute. They are as follows:
          </p>
        </div>
        <Row className="justify-content-center">
          {cardContent.map((item,index) => (
            <Col lg={4} sm={6} key={index}>
              <ServicesCard
                img={item.img}
                title={item.title}
                content={item.content}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default MediationTypes;
