import React from "react";
import Banner from "../../components/common/banner/banner";
import Header from "../../components/common/header/header";
import { mediaBannerBg } from "../../components/images";
import LatestNews from "../../components/common/latestNews/latestNews";
import LegalAdvice from "./legalAdvice/legalAdvice";
import PhotoAlbum from "./photoAlbum/photoAlbum";
import Videos from "./videos/videos";
import Events from "./events/events";
import Workshop from "./workshop/workshop";
import TrainingCourses from "./trainingCourses/trainingCourses";
import Seminars from "./seminars/seminars";
import Footer from "../../components/common/footer/footer";

function MediaCenter() {
  return (
    <>
      <Header />
      <Banner
        title="Media Center"
        content="Chamber is very keen in providing the decision makers with information and opinions concerning the commercial and industrial issues."
        img={mediaBannerBg}
      />
      <LatestNews mainTitle="Latest News" />
      <LegalAdvice />
      <PhotoAlbum />
      <Videos />
      <Events />
      <Workshop />
      <TrainingCourses />
      <Seminars />
      <Footer />
    </>
  );
}

export default MediaCenter;
