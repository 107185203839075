import React from "react";
import "./arbitrationClause.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { modelArbitration } from "../../components/images";

const ArbitrationClause = () => {
  return (
    <section className="arbitration-clause">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="content-wrapper mb-lg-4 mb-5">
              <h2 className="mb-3">Model Arbitration Clause</h2>
              <p className="p-lg">
                Any dispute arising out of or in connection with this contract,
                including any question regarding its existence, validity or
                termination, shall be finally resolved by arbitration by one
                arbitrator, to be appointed by the Dubai International
                Arbitration Centre in accordance with the DIAC Arbitration Rules
                in force on the date of the submission of the request for
                appointment. The legal seat of the arbitration shall be [*] and
                the language of the arbitration shall be [**].”
              </p>
              <p>
                Any dispute arising out of or in connection with this contract,
                including any question regarding its existence, validity or
                termination, shall be finally resolved by arbitration by one
                arbitrator, to be appointed by the Dubai International
                Arbitration Centre in accordance with the DIAC Arbitration Rules
                in force on the date of the submission of the request for
                appointment. The legal seat of the arbitration shall be [*] and
                the language of the arbitration shall be [**].”
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="img-wrapper">
              <Image src={modelArbitration} alt="model abitration img" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ArbitrationClause;
