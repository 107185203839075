import React, { useState } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import Slider from "react-slick";
import {
  NextArrow,
  PrevArrow,
} from "../../../components/common/customSliderArows/customSliderArows";
import "./videos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
const Videos = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentVideo, setCurrentVideo] = useState(null);

  const settings = {
    dots: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    arrows: false,
    dots: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // DragEvent: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const onThumbnailClick = (videoUrl) => {
    setCurrentVideo(videoUrl);
  };
  const videos = [
    {
      thumbnailUrl:
        "https://www.copahost.com/blog/wp-content/uploads/2019/07/imgsize2.png",
      url: "https://www.w3schools.com/tags/movie.mp4",
    },
    {
      thumbnailUrl:
        "https://wpmudev.com/wp-content/uploads/2011/03/image-url-1470x940.png",
      url: "https://www.youtube.com/watch?v=0pogUgKVL_w.mp4",
    },
    {
      thumbnailUrl:
        "https://www.copahost.com/blog/wp-content/uploads/2019/07/imgsize2.png",
      url: "https://www.w3schools.com/tags/movie.mp4",
    },
    {
      thumbnailUrl:
        "https://www.copahost.com/blog/wp-content/uploads/2019/07/imgsize2.png",
      url: "https://www.w3schools.com/tags/movie.mp4",
    },
  ];
  return (
    <section className="video-album">
      <Container fluid className="custom-container">
        <h2 className="mb-lg-5 mb-4">Videos</h2>
        <Slider {...settings} className="position-relative">
          {videos.map((video, index) => (
            <div key={index} className="video-body">
              <div
                className="video-thumbnail position-relative"
                onClick={handleShow}
                role="button"
              >
                <Image
                  src={video.thumbnailUrl}
                  alt={`Thumbnail ${index}`}
                  className="mw-100 w-100"
                  onClick={() => onThumbnailClick(video.url)}
                  height={270}
                />
                <div className="play-icon">
                  <FontAwesomeIcon icon={faPlay} fontSize={25} />
                </div>
              </div>
              <div className="video-details d-flex align-items-center">
                <div className="date text-center me-3 flex-shrink-0">
                  <span className="fw-semibold">22</span>
                  <p className="month text-uppercase mb-0">SEP</p>
                </div>
                <p className="mb-0 flex-grow-1">
                  Ras Al Khaimah – Kflvoev xcvc vele. Js ozdk vc Skxfkz; dk
                  elvlpzd
                </p>
              </div>
            </div>
          ))}
        </Slider>
        {currentVideo && (
          <>
            <Modal
              className="video-modal"
              show={show}
              onHide={handleClose}
              size="lg"
              centered
            >
              <Modal.Body closeButton>
                <video
                  src={currentVideo}
                  type="video/mp4"
                  controls
                  muted
                  autoPlay
                />
                {/* <source src={currentVideo} type="video/mp4" /> */}
                {/* <source src="movie.ogg" type="video/ogg" /> */}
                {/* Your browser does not support the video tag. */}
                {/* </video> */}
                <Slider {...settings2}>
                  {videos.map((video, index) => (
                    <div key={index} onClick={handleShow} role="button">
                      <Image
                        src={video.thumbnailUrl}
                        alt={`Thumbnail ${index}`}
                        onClick={() => onThumbnailClick(video.url)}
                        width={155}
                        fluid
                      />
                    </div>
                  ))}
                </Slider>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </section>
  );
};

export default Videos;
