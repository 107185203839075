import React, { useState, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createFormRequest } from "../../../../redux/e-services/action";
import Swal from "sweetalert2";
import FullPageLoader from "../../../FullPageLoader/FullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import Select from "react-select";
import E_Services from "../e-services";
import { nominationSchema } from "../../../../validation/validationSchema";

const NonListedArbitrator = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.e_services.loader);
  const countries = useMemo(() => countryList().getData(), []);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(nominationSchema)
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
      formData.append("serviceType", "nonListedArbitrator");
      formData.append("serviceData", JSON.stringify(data));
      Swal.fire({
        // title: 'Are you sure?',
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(createFormRequest(formData));
            if (data?.success === true) {
              toast.success(t("successApiValidation"));
            } else {
              toast.error(t("errorApiValidation"));
            }
          }
        }
      });
  }
  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
        <h2>{t("Statement")}/ {t("Certificate Request")}</h2>
        {loader ? <FullPageLoader /> : null}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Date")} </Form.Label>
            <Controller
              name="caseDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors.caseDate} />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.caseDate?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("caseNumber")} </Form.Label>
            <Form.Control type="text" {...register("caseNo")} isInvalid={!!errors.caseNo} />
            <Form.Control.Feedback type="invalid">{errors.caseNo?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Legal Representative")}</Form.Label>
            <Form.Control type="text" name="RequestedBy" {...register("RequestedBy")} isInvalid={!!errors.RequestedBy}/>
            <Form.Control.Feedback type="invalid">{errors.RequestedBy?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Email")} </Form.Label>
            <Form.Control type="email" {...register("email")} isInvalid={!!errors?.email} />
            <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control type="text" {...register("phoneNumber")} isInvalid={!!errors?.phoneNumber} />
            <Form.Control.Feedback type="invalid">{errors?.phoneNumber?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <h3>{t("arbitrator details")}</h3>
            <Form.Label> {t('Name of')} {t('Arbitrator')}</Form.Label>
            <Form.Control type="text" {...register("nameOfArbitrator")} isInvalid={!!errors?.nameOfArbitrator} />
            <Form.Control.Feedback type="invalid">{errors?.nameOfArbitrator?.message}</Form.Control.Feedback>            
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("Nationality")}</Form.Label>
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countries}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors?.nationality?.message}</Form.Control.Feedback>  
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("specialization")}</Form.Label>
            <Controller
              name="specialization"
              control={control}
              render={({ field }) => <Form.Control type="text" {...field} isInvalid={!!errors?.specialization} />}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Address")}</Form.Label>
            <Form.Control as="textarea" name="addressAritrator" {...register("addressAritrator")} isInvalid={!!errors?.addressAritrator}  />
            <Form.Control.Feedback type="invalid">{errors?.addressAritrator?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Email")} </Form.Label>
            <Form.Control type="email" {...register("emailArbitrator")} isInvalid={!!errors?.emailArbitrator} />
            <Form.Control.Feedback type="invalid">{errors?.emailArbitrator?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control type="text" {...register("phoneNumberArbitrator")} isInvalid={!!errors?.phoneNumberArbitrator} />
            <Form.Control.Feedback type="invalid">{errors?.phoneNumberArbitrator?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("challenge")}</Form.Label>
            <Form.Control as="textarea" name="challenge" {...register("challenge")} isInvalid={!!errors?.challenge} />
            <Form.Control.Feedback type="invalid">{errors?.challenge?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature Date")}</Form.Label>
            <Controller
              name="signatureDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors?.signatureDate}/>
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.signatureDate?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature")} </Form.Label>
            <Form.Control type="text" {...register("signature")} isInvalid={!!errors?.signature} />
            <Form.Control.Feedback type="invalid">{errors?.signature?.message}</Form.Control.Feedback>
          </Form.Group>
        <Button variant="primary" type="submit" >
          {t("Save Changes")}
        </Button>
        </Form>
        <Link to={'/dashboard/e-services'}>
          {t("Back to e-services")}
        </Link>
        <div>
          <h6>{t("Note")} :- {t("non listed note")}</h6>
        </div>
      </div>
    </div>
  )
}

export default NonListedArbitrator;
