import React from "react";
import { Button, Form } from "react-bootstrap";
import "./eCalculator.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
function ECalculator() {
  return (
    <>
      <div className="e-calculator">
        <h4 className="title text-blue fw-semibold pb-3">
          Arbitration fees E-Calculator
        </h4>
        <p className="para-content">
          Calculate the cost of arbitration under the Ras Al Khaimah rules
        </p>
        <div className="d-flex align-items-center gap-4 mb-3">
          <h6 className="text-blue fw-semibold mb-0">AED</h6>
          <div className="aed-price"></div>
        </div>
        <Form>
          <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
            <h6 className="fw-semibold mb-0">No. of Arbitrators</h6>
            <Form.Select
              className="number-field"
              role="button"
              aria-label="number"
            >
              {/* <option>Number</option> */}
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3">4</option>
              <option value="3">5</option>
            </Form.Select>
          </div>
          <div className="text-end">
            <Button variant="none" className="btn-blue">
              CALCULATE
            </Button>
          </div>
        </Form>
      </div>
      <div className="social-bazz">
        <h4 className="text-blue">Social Bazz</h4>
        <div className="content-box d-flex align-items-center justify-content-between flex-xxl-nowrap flex-wrap gap-2">
          <p className="text-white text-end mb-0">
            <span className="fw-semibold d-xxl-block text-end">
              Follow us on
            </span>{" "}
            social platforms
          </p>
          <ul className="social-icons list-unstyled mb-0 d-flex gap-3">
            <li>
              <a
                href="/"
                className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ECalculator;
