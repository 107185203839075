import React from "react";
import "./ourPartners.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import partnersAnimation from "../../../assets/lotties/partners-animation.json";
import percentageAnimation from "../../../assets/lotties/percentage-animation.json";

function OurPartners() {
  const partnersOptions = {
    loop: true,
    autoplay: true,
    animationData: partnersAnimation,
  };
  const percentageOptions = {
    loop: true,
    autoplay: true,
    animationData: percentageAnimation,
  };
  return (
    <section className="our-partner">
      <Container fluid className="custom-container">
        <Row className="justify-content-between">
          <Col lg={4}>
            <div className="partners-lottie position-relative">
              <Lottie options={partnersOptions} />
            </div>
          </Col>
          <Col xl={7} lg={8}>
            <div className="content-wrapper mb-5">
              <h2>Our Partners</h2>
              <p className="p-lg">
                Nulla tincidunt vitae enim vel feugiat. Maecenas ut metus at leo
                dignissim luctus in vel purus suisque facilisi.
              </p>
            </div>
            <ul className="total-partners list-unstyled d-flex flex-md-nowrap flex-wrap gap-lg-4 gap-3 mb-lg-5 mb-4">
              <li className="position-relative">
                <div className="percentage-lottie">
                  <Lottie options={percentageOptions} />
                </div>
                <span>102</span>
                <p className="text-uppercase text-center mt-1 mb-0 fw-semibold">
                  TOTAL PARTNERS
                </p>
              </li>
              <li className="position-relative">
                <div className="percentage-lottie">
                  <Lottie options={percentageOptions} />
                </div>
                <span>22</span>
                <p className="text-uppercase text-center mt-1 mb-0 fw-semibold">
                  SERVICE PARTNERS
                </p>
              </li>
              <li className="position-relative">
                <div className="percentage-lottie">
                  <Lottie options={percentageOptions} />
                </div>
                <span>30</span>
                <p className="text-uppercase text-center mt-1 mb-0 fw-semibold">
                  INTERNATIONAL PROTOCOL
                </p>
              </li>
              <li className="position-relative">
                <div className="percentage-lottie">
                  <Lottie options={percentageOptions} />
                </div>
                <span>40</span>
                <p className="text-uppercase text-center mt-1 mb-0 fw-semibold">
                  MAIN PARTNERS
                </p>
              </li>
              <li className="position-relative">
                <div className="percentage-lottie">
                  <Lottie options={percentageOptions} />
                </div>
                <span>10</span>
                <p className="text-uppercase text-center mt-1 mb-0 fw-semibold">
                  STRATEGIC PARTNERS
                </p>
              </li>
            </ul>
            <div className="d-flex align-items-center gap-4">
              <div className="members-number d-flex flex-column text-yellow">
                <span className="fw-semibold">100+</span>
                <p className="mt-n1 mb-0">Members</p>
              </div>
              <Button variant="none" className="btn-blue">
                BECOME A PARTNER
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OurPartners;
