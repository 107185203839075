import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/header-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./footer.css";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <section className="footer text-white position-relative">
      <div className="top-sec">
        <Container fluid className="custom-container">
          <Row>
            <Col lg={4}>
              <div className="logo-content-wrapper">
                <div className="mb-xl-5 mb-4">
                  <strong className="d-inline-block mb-lg-4 mb-3">
                    <Link to="#" className="d-inline-block">
                      <Image src={Logo} alt="logo" fluid />
                    </Link>
                  </strong>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
                <div className="social-contact">
                  <h4 className="mb-3">Social Contact</h4>
                  <ul className="social-icons list-unstyled mb-0 d-flex gap-3">
                    <li>
                      <a
                        href="#"
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={3} md={5} sm={7}>
              <div className="content-wrapper">
                <h4 className="title">Services</h4>
                <ul className="footer-links list-unstyled mb-0">
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Settlement of Commercial
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Legal Seminar Or Workshop
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Training Courses For Arbitrators
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Legal Information
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Arbitration fees e-Calculator
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={2} md={3} sm={5}>
              <div className="content-wrapper">
                <h4 className="title">Useful Links</h4>
                <ul className="footer-links list-unstyled mb-0">
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      e-Services
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="about-us" className="text-decoration-none">
                      About us
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Services
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="/mediation" className="text-decoration-none">
                      Mediation
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="/arbitration" className="text-decoration-none">
                      Arbitration
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="content-wrapper">
                <h4 className="title">Contact us</h4>
                <ul className="contect-info list-unstyled mb-0">
                  <li className="mb-md-3 mb-2">
                    <a
                      href="tel::+971 021-444 6663"
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>+971 021-444 6663</span>
                    </a>
                  </li>
                  <li className="mb-md-3 mb-2">
                    <a
                      href="mailto::info@sitename.com"
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>info@sitename.com</span>
                    </a>
                  </li>
                  <li className="mb-md-3 mb-2">
                    <Link
                      href="#"
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>
                        No. 149, Zone A Plot1 Lumumba Avenue ,Kampala,Uganda
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-sec text-center text-white">
        <Container>
          <p className="mb-0">
            Copyright © 2023 Ras Al Khaimah | All Rights Reserved.
          </p>
        </Container>
      </div>
    </section>
  );
}

export default Footer;
