import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import {
  seminarService1,
  seminarService2,
  seminarService3,
} from "../../../components/images";
import "./seminars.css";

const Seminars = () => {
  return (
    <section className="seminars-conferences position-relative">
      <Container fluid className="custom-container">
        <h2 className="title text-white text-center">
          Seminars And Conferences
        </h2>
        <Row className="justify-content-center">
          <Col lg={4} sm={6}>
            <ServicesCard
              img={seminarService1}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={seminarService2}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
          <Col lg={4} sm={6}>
            <ServicesCard
              img={seminarService3}
              preTitle="Arbitration"
              title="Settlement of Commercial Dispute"
              content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Seminars;
