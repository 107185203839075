import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import Footer from "../../components/common/footer/footer";
import { membershipBanner } from "../../components/images";
import CustomTabs from "./customTabs/customTabs";
import { Container } from "react-bootstrap";
import "./index.css";
const Membership = () => {
  return (
    <div>
      <Header />
      <Banner
        img={membershipBanner}
        title="Memberships"
        content="Chamber is very keen in providing the decision makers with information and opinions concerning the commercial and industrial issues."
      />
      <div className="membership-wrapper">
        <Container fluid className="custom-container">
          <CustomTabs />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Membership;
