import React from "react";
import { handshake } from "../../../components/images";
import { Col, Container, Image, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import handshakeAnimation from "../../../assets/lotties/hanshake.json";
import "./advantages.css";

const Advantages = () => {
  const handshakeOptions = {
    loop: true,
    autoplay: true,
    animationData: handshakeAnimation,
  };
  return (
    <section className="mediaion-advantages grey-bg-sec">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={5} className="position-relative">
            <div className="member-img text-center position-relative pe-sm-0 pe-4">
              <Image
                src={handshake}
                alt="members img"
                fluid
                className="h-100"
              />
              <div className="handshake-lottie">
                <Lottie options={handshakeOptions} />
              </div>
            </div>
          </Col>
          <Col lg={{ span: 6, offset: 1 }}>
            <div className="content-wrapper">
              <h2 className="mb-0">Mediation At Ras Al Khaimah</h2>
              <strong className="title text-uppercase d-inline-block">
                ADVANTAGES
              </strong>
              <p className="p-lg">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p>
                International Commercial Arbitration Centre "Tahkeem" welcomes
                specialists from all levels in the field of commercial disputes
                settlement. The Centre offers membership and fellowship to all
                professionals; such as advocates, experts and lawyers all
                specializations as well as engineers, accountants, businessmen,
                representatives of trade authorities and institutions,
                international arbitrators and mediators. Members of the Centre
                enjoys several privileges according to the type of membership as
                follows:
              </p>
              <ul className="general-list list-unstyled mb-0">
                <li>Expert’s Membership.</li>
                <li>Arbitrator’s Membership.</li>
                <li>Centre’s Membership.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Advantages;
