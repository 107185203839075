import React from "react";
import "./arbitrationCharacteristics.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  confidentialityIcon,
  costIcon,
  flexibilityIcon,
  globIcon,
  impartialityIcon,
  speedIcon,
} from "../../../components/images";
const ArbitrationCharacteristics = () => {
  const cardContent = [
    {
      icon: impartialityIcon,
      title: "Impartiality",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: costIcon,
      title: "Cost-Effectiveness",
      content:
        "While there are costs associated with arbitration, it can be more cost- effective than traditional litigation, especially for complex disputes.",
    },
    {
      icon: confidentialityIcon,
      title: "Confidentiality",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: globIcon,
      title: "Global Recognition",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: speedIcon,
      title: "Speed and Efficiency",
      content:
        "While there are costs associated with arbitration, it can be more cost- effective than traditional litigation, especially for complex disputes.",
    },
    {
      icon: flexibilityIcon,
      title: "Flexibility",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
  ];
  return (
    <section className="arbitration-characteristics">
      <Container fluid className="custom-container">
        <h2>Characteristics Of Arbitration</h2>
        <Row>
          {cardContent.map((item, index) => (
            <Col lg={4} sm={6} key={index}>
              <div className="char-container mb-md-4 mb-3">
                <div className="char-card-wrapper d-flex pb-md-3 pb-2">
                  <div className="icon-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
                    <Image src={item.icon} alt="icon" fluid />
                  </div>
                  <div className="content-box">
                    <h6 className="mb-sm-2 mb-1">{item.title}</h6>
                    <p className="mb-0">{item.content}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ArbitrationCharacteristics;
