import React from "react";
import "./informationTechnology.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import computerAnimation from "../../../assets/lotties/computer-animation.json";
import circleAnimation from "../../../assets/lotties/circle-animation.json";
import Lottie from "react-lottie";
import { informationTechnology } from "../../../components/images";
const InformationTechnology = () => {
  const computerOptions = {
    loop: true,
    autoplay: true,
    animationData: computerAnimation,
  };
  const circleOptions = {
    loop: true,
    autoplay: true,
    animationData: circleAnimation,
  };
  return (
    <section className="information-technology grey-bg-sec">
      <Container className="custom-container" fluid>
        <Row>
          <Col lg={5} className="position-relative">
            <div className="member-img text-center position-relative pe-sm-0 pe-4">
              <Image
                src={informationTechnology}
                alt="section img"
                fluid
                className="h-100"
              />
              <dic className="computer-circle">
                <div className="computer-lottie">
                  <Lottie options={computerOptions} />
                </div>
                <div className="circle-lottie">
                  <Lottie options={circleOptions} />
                </div>
              </dic>
            </div>
          </Col>
          <Col lg={{ span: 6, offset: 1 }}>
            <div className="content-wrapper">
              <h2 className="mb-0">Information Technology</h2>
              <strong className="title text-uppercase d-inline-block">
                DEPARTMENT
              </strong>
              <p className="p-lg">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p>
                International Commercial Arbitration Centre " Tahkeem" welcomes
                specialists from all levels in the field of commercial disputes
                settlement. The Centre offers membership and fellowship to all
                professionals; such as advocates, experts and lawyers all
                specializations as well as engineers, accountants, businessmen,
                representatives of trade authorities and institutions,
                international arbitrators and mediators. Members of the Centre
                enjoys several privileges according to the type of membership as
                follows:
              </p>
              <ul className="general-list list-unstyled mb-0">
                <li>Expert’s Membership.</li>
                <li>Arbitrator’s Membership.</li>
                <li>Centre’s Membership.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InformationTechnology;
