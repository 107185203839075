import React from "react";
import { Container } from "react-bootstrap";
import "./termsOfReference.css";
function TermsOfReference() {
  return (
    <section className="terms-reference text-center text-white position-relative">
      <Container fluid className="custom-container">
        <h2 className="text-white">
          The Center Secretariat And Its Terms Of Reference
        </h2>
        <p className="mb-4">
          Ut accumsan, nibh at sagittis sodales, lorem magna faucibus sapien,
          non vestibulum risus orci sed dolor. Cras diam lorem, facilisis sit
          amet tincidunt consequat, efficitur id tellus. Donec hendrerit euismod
          ex, vitae sollicitudin tellus ornare sit amet. Suspendisse potenti.
          Donec eget viverra magna, et consequat libero. Suspendisse non porta
          risus, feugiat molestie quam. Morbi non dui quis diam iaculis
          ullamcorper vitae in turpis. Maecenas nec justo in metus tincidunt
          tincidunt nec a ligula. Aenean et molestie dui.
        </p>
        <p className="mb-0">
          Nulla vestibulum posuere felis. Aenean at ex id ipsum rhoncus maximus
          ut vel magMaecenas maximus vestibulum diam id tempus. Praesent sit
          amet maximus felis. In id diam finibus, cursus nisi non, ultricies
          justo.na. Suspendisse consequat laoreet nisl Quisque fermentum
          imperdiet facilisis. Fusce sit amet maximus nibh, at maximus quam.
          Aenean ac tortor consequat, bibendum lectus quis, blandit sem. Donec
          ut laoreet orci. Maecenas maximus vestibulum diam id tempus. Praesent
          sit amet maximus felis. In id diam finibus, cursus nisi non, ultricies
          justo.Donec hendrerit euismod ex, vitae sollicitudin tellus ornare sit
          amet. Suspendisse potenti. Donec eget viverra magna, et consequat
          libero. Suspendisse non porta risus, feugiat molestie quam. Morbi non
          dui quis diam iaculis ullamcorper vitae in turpis. Maecenas nec justo
          in metus tincidunt tincidunt nec a ligula.
        </p>
      </Container>
    </section>
  );
}

export default TermsOfReference;
