import React from "react";
import SliderImg1 from "../../../assets/images/services-slide1.png";
import { Button, Container, Image } from "react-bootstrap";
import Slider from "react-slick";
import "./ourServices.css";
import {
  PrevArrow,
  NextArrow,
} from "../../../components/common/customSliderArows/customSliderArows";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import { alLogo } from "../../../components/images";

function OurServices() {
  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="our-services position-relative">
      <Container fluid className="custom-container position-relative">
        <div className="sec-al-logo">
          <Image src={alLogo} alt="al logo" fluid />
        </div>
        <h2 className="mb-lg-5 mb-4">Our Services</h2>
        <Slider {...settings} className="position-relative">
          <ServicesCard
            img={SliderImg1}
            preTitle="Arbitration"
            title="Settlement of Commercial Dispute"
            content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
          />
          <ServicesCard
            img={SliderImg1}
            preTitle="Arbitration"
            title="Settlement of Commercial Dispute"
            content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
          />
          <ServicesCard
            img={SliderImg1}
            preTitle="Arbitration"
            title="Settlement of Commercial Dispute"
            content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
          />
          <ServicesCard
            img={SliderImg1}
            preTitle="Arbitration"
            title="Settlement of Commercial Dispute"
            content="Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non."
          />
          {/* <div className='services-card mb-3'>
                        <div className='img-wrapper'>
                            <Image src={SliderImg1} alt='service img' fluid />
                        </div>
                        <div className='card-content'>
                            <span className='pre-heading'>Arbitration</span>
                            <h4 className='mb-2'>Settlement of Commercial Dispute</h4>
                            <p>
                                Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non.
                            </p>
                            <span className='details d-flex align-items-center justify-content-end gap-2'>
                                Details
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </span>
                        </div>
                    </div>
                    <div className='services-card mb-3'>
                        <div className='img-wrapper'>
                            <Image src={SliderImg1} alt='service img' fluid />
                        </div>
                        <div className='card-content'>
                            <span className='pre-heading'>Arbitration</span>
                            <h4 className='mb-2'>Settlement of Commercial Dispute</h4>
                            <p>
                                Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non.
                            </p>
                            <span className='details-arrow align-items-center justify-content-end gap-2'>
                                Details
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </span>
                        </div>
                    </div>
                    <div className='services-card mb-3'>
                        <div className='img-wrapper'>
                            <Image src={SliderImg1} alt='service img' fluid />
                        </div>
                        <div className='card-content'>
                            <span className='pre-heading'>Arbitration</span>
                            <h4 className='mb-2'>Settlement of Commercial Dispute</h4>
                            <p>
                                Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non.
                            </p>
                            <span className='details d-flex align-items-center justify-content-end gap-2'>
                                Details
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </span>
                        </div>
                    </div>
                    <div className='services-card mb-3'>
                        <div className='img-wrapper'>
                            <Image src={SliderImg1} alt='service img' fluid />
                        </div>
                        <div className='card-content'>
                            <span className='pre-heading'>Arbitration</span>
                            <h4 className='mb-2'>Settlement of Commercial Dispute</h4>
                            <p>
                                Proin sit amet orci congue, facilisis msie auris sed, ultrices ante. In semper lobortis mesve ki eget elementum eros aliquet non.
                            </p>
                            <span className='details-arrow align-items-center justify-content-end gap-2' role='button'>
                                Details
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </span>
                        </div>
                    </div> */}
        </Slider>
        <div className="text-center">
          <Button variant="none" className="btn-blue">
            VIEW ALL SERVICES
          </Button>
        </div>
      </Container>
    </section>
  );
}

export default OurServices;
