import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  alLeftside,
  eventImg,
  indoorIcon,
  outdoorIcon,
  theEventImg,
} from "../../../components/images";
import calendarAnimation from "../../../assets/lotties/calendar.json";
import popperAnimation from "../../../assets/lotties/popper.json";
import Lottie from "react-lottie";
import "./events.css";

const Events = () => {
  const calendarOption = {
    loop: true,
    autoplay: true,
    animationData: calendarAnimation,
  };
  const popperOption = {
    loop: true,
    autoplay: true,
    animationData: popperAnimation,
  };
  return (
    <section className="event-sec position-relative">
      <div className="al-left-side-sec d-flex align-items-center position-absolute top-0 h-100">
        <Image src={alLeftside} fluid alt="al logo" className="h-100" />
      </div>
      <Container fluid className="custom-container">
        <Row className="justify-content-lg-between justify-content-center">
          <Col
            xl={{ span: 3, offset: 1 }}
            lg={{ span: 4, offset: 1, order: 1 }}
            sm={6}
            xs={{ span: 8, order: 2 }}
          >
            <div className="position-relative text-lg-left text-end">
              <div className="event-img">
                <Image src={eventImg} alt="event img" fluid />
              </div>
              <div className="the-event">
                <Image src={theEventImg} alt="event img" fluid />
              </div>
              <div className="calendar-lottie">
                <Lottie options={calendarOption} />
              </div>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{ order: 1 }}>
            <div className="d-flex align-items-center">
              <strong className="title fw-bold">EVENTS</strong>
              <div className="popper-lottie">
                <Lottie options={popperOption} />
              </div>
            </div>
            <p className="p-lg mb-md-4 mb-3">
              Our team of highly skilled and experienced arbitration lawyers in
              the UAE is dedicated to providing top-notch legal assistance.
            </p>
            <ul className="event-types list-unstyled d-flex gap-md-4 gap-3 pt-3 mb-lg-0 mb-5">
              <li className="text-center position-relative">
                <span className="fw-semibold mb-md-2 mb-1 d-inline-block">
                  06
                </span>
                <p className="text-uppercase fw-semibold mb-0">INDOOR</p>
                <div className="event-icon d-flex align-items-center justify-content-center bg-white rounded-circle">
                  <Image src={indoorIcon} alt="icon" fluid />
                </div>
              </li>
              <li className="text-center position-relative">
                <span className="fw-semibold mb-md-2 mb-1 d-inline-block">
                  03
                </span>
                <p className="text-uppercase fw-semibold mb-0">OUTDOOR</p>
                <div className="event-icon d-flex align-items-center justify-content-center bg-white rounded-circle">
                  <Image src={outdoorIcon} alt="icon" fluid />
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Events;
