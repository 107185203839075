import React from "react";
import { Form } from "react-bootstrap";

const LanguageComponent = ({ checked, handleCheck }) => {
  return (
    <>
      <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
        <Form.Check
          type="checkbox"
          value="Reading"
          label="Reading"
          checked={checked?.reading}
          onChange={() => handleCheck("Reading")}
        />
        {/* <Form.Label>Reading</Form.Label> */}
        <Form.Check
          type="checkbox"
          value="Written"
          label="Written"
          checked={checked?.writting}
          onChange={() => handleCheck("Written")}
        />
        {/* <Form.Label>Written</Form.Label> */}
        <Form.Check
          type="checkbox"
          value="Spoken"
          label="Spoken"
          checked={checked?.spoken}
          onChange={() => handleCheck("Spoken")}
        />
        {/* <Form.Label>Spoken</Form.Label> */}
      </Form.Group>
    </>
  );
};

export default LanguageComponent;
