import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { createFormRequest } from "../../../../../redux/e-services/action";
import Swal from "sweetalert2";
import FullPageLoader from "../../../../FullPageLoader/FullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RadioButtonGroup from "../../../../../utils/htmlElements/radioButton/radioButtonGroup";
import E_Services from "../../e-services";
import { certificateSchema } from "../../../../../validation/validationSchema";

const Certificate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.e_services.loader);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(certificateSchema),
  });
 
  const titleOption = [
    t("Arbitration Case Validation"),
    t("Arbitration Registration Validation"),
    t("Expert Registration Validation"),
    t("Arbitral Award Submission"),
    t("Other"),
  ];
  const onSubmit = async (data) => {
    const formData = new FormData();
    
      formData.append("serviceType", "certificate");
      formData.append("serviceData", JSON.stringify(data));
      Swal.fire({
        // title: 'Are you sure?',
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(createFormRequest(formData));
            if (data?.success === true) {
              toast.success(t("successApiValidation"));
            } else {
              toast.error(t("errorApiValidation"));
            }
          }
        }
      });
  }
  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
        <h2>{t("Statement")}/ {t("Certificate Request")}</h2>
        {loader ? <FullPageLoader /> : null}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Date")} </Form.Label>
            <Controller
              name="caseDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors.caseDate} />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.caseDate?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Requested By")}</Form.Label>
            <Form.Control type="text" name="RequestedBy" {...register("RequestedBy")} isInvalid={!!errors.RequestedBy}/>
            <Form.Control.Feedback type="invalid">{errors.RequestedBy?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Email")} </Form.Label>
            <Form.Control type="email" {...register("email")} isInvalid={!!errors?.email} />
            <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control type="text" {...register("phoneNumber")} isInvalid={!!errors?.phoneNumber} />
            <Form.Control.Feedback type="invalid">{errors?.phoneNumber?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Addressed To")}</Form.Label>
            <Form.Control as="textarea" name="AddressedTo" {...register("AddressedTo")} isInvalid={!!errors?.AddressedTo}  />
            <Form.Control.Feedback type="invalid">{errors?.AddressedTo?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Certificate Subject')}</Form.Label>
            <Controller
              name="selectedSubject"
              control={control}
              render={({ field }) => (
                <RadioButtonGroup
                  options={titleOption}
                  selectedValue={field.value}
                  setSelectedValue={value => field.onChange(value)}
                />
              )}
            />
             <Form.Control.Feedback type="invalid">{errors?.selectedSubject?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
          <Form.Control as="textarea" name="otherDetails" {...register("otherDetails")} isInvalid={!!errors?.otherDetails}/>
          <Form.Control.Feedback type="invalid">{errors?.otherDetails?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature Date")}</Form.Label>
            <Controller
              name="signatureDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors?.signatureDate} />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.signatureDate?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature")} </Form.Label>
            <Form.Control type="text" {...register("signature")} isInvalid={!!errors?.signature} />
            <Form.Control.Feedback type="invalid">{errors?.signature?.message}</Form.Control.Feedback>
          </Form.Group>
        <Button variant="primary" type="submit" >
          {t("Save Changes")}
        </Button>
        </Form>
        <Link to={'/dashboard/e-services'}>
          {t("Back to e-services")}
        </Link>
        <div>
          <h6>{t("Note")} :- {t("Request fee 10,000 AED • The amount will be refunded if the request was accepted by the executive committee.")}</h6>
        </div>
      </div>
    </div>
  )
}

export default Certificate;
