import axios from "axios";
import { config } from "../../config/config";
// import { toast } from 'react-toastify';

const baseUrl = config.serverUrl;
// let liveBaseUrl = ENV.liveServerUrl

async function apiHelper(apiType, path, data, token) {
  // if (baseUrl === undefined || !baseUrl) {
  //     baseUrl = ""
  // }
  // const xauthtoken = JSON.parse(localStorage.getItem("token"))
  if (
    apiType === "post" ||
    apiType === "put" ||
    apiType === "get" ||
    apiType === "delete" ||
    apiType === "patch"
  ) {
    const response = await axios({
      method: apiType,
      url: `${baseUrl + path}`,
      data,
      headers: {
        authorization: token,
        // 'x-auth-token': ENV.x_auth_token
      },
    });
    return response;
  }
}

export { apiHelper };
