import React from "react";
import { Button, Form } from "react-bootstrap";
import { createFormRequest } from "../../../../../redux/e-services/action";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FullPageLoader from "../../../../FullPageLoader/FullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import E_Services from "../../e-services";
import { certifiedCopySchema } from "./../../../../../validation/validationSchema";


const CertifiedCopy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.e_services.loader);

  // useForm from react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(certifiedCopySchema),
  });

  // On form submit
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("serviceType", "certifiedCopy");
    formData.append("serviceData", JSON.stringify(data));

    Swal.fire({
      text: t("sureMessage"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("CancelButton"),
      confirmButtonText: t("SendButton"),
      showConfirmButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(createFormRequest(formData));
        if (res?.success === true) {
          toast.success(t("successApiValidation"));
          reset(); // reset form after successful submission
        } else {
          toast.error(t("errorApiValidation"));
        }
      }
    });
  };

  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
        <h2>{t("CERTIFIED COPY OF AN AWARD")}</h2>
        {loader ? <FullPageLoader /> : null}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Case Date */}
          <Form.Group className="mb-3" controlId="caseDate">
            <Form.Label>{t("Date")}</Form.Label>
            <Form.Control
              type="date"
              {...register("caseDate")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.caseDate?.message}</span>
          </Form.Group>

          {/* Case No */}
          <Form.Group className="mb-3" controlId="caseNo">
            <Form.Label>{t("caseNumber")}</Form.Label>
            <Form.Control
              type="text"
              {...register("caseNo")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.caseNo?.message}</span>
          </Form.Group>

          {/* Legal Representative */}
          <Form.Group className="mb-3" controlId="legalRepresentative">
            <Form.Label>{t("Legal Representative")}</Form.Label>
            <Form.Control
              type="text"
              {...register("legalRepresentative")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.legalRepresentative?.message}</span>
          </Form.Group>

          {/* Email */}
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              {...register("email")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.email?.message}</span>
          </Form.Group>

          {/* Phone Number */}
          <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control
              type="text"
              {...register("phoneNumber")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.phoneNumber?.message}</span>
          </Form.Group>

          {/* Copies */}
          <Form.Group className="mb-3" controlId="copies">
            <Form.Label>{t("Number of Copies")}</Form.Label>
            <Form.Control
              type="text"
              {...register("copies")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.copies?.message}</span>
          </Form.Group>

          {/* Signature Date */}
          <Form.Group className="mb-3" controlId="signatureDate">
            <Form.Label>{t("Signature Date")}</Form.Label>
            <Form.Control
              type="date"
              {...register("signatureDate")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.signatureDate?.message}</span>
          </Form.Group>

          {/* Signature */}
          <Form.Group className="mb-3" controlId="signature">
            <Form.Label>{t("Signature")}</Form.Label>
            <Form.Control
              type="text"
              {...register("signature")}
            />
            <span className="pl-1 pt-0 text-danger">{errors.signature?.message}</span>
          </Form.Group>

          {/* Submit Button */}
          <Button variant="primary" type="submit">
            {t("Save Changes")}
          </Button>
        </Form>
        <Link to={"/dashboard/e-services"}>{t("Back to e-services")}</Link>
      </div>
    </div>
  )
}

export default CertifiedCopy;
