import React from "react";
import bannerBuilding from "../../../assets/images/home-banner-building.png";
import waveAnimation from "../../../assets/lotties/wave-animation.json";
import { Container, Image } from "react-bootstrap";
import "./banner.css";
import Lottie from "react-lottie";
import AOS from "aos";
import "aos/dist/aos.css";

function Banner() {
  AOS.init();
  const waveOptions = {
    loop: true,
    autoplay: true,
    animationData: waveAnimation,
  };
  return (
    <section className="home-banner position-relative">
      <Container fluid className="custom-container">
        <strong
          className="arbitration text-white text-center d-block"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          Arbitr<code className="d-lg-inline-block d-none">&nbsp;</code>ation
        </strong>
        <div className="banner-building">
          <Image src={bannerBuilding} alt="building" fluid className="h-100" />
        </div>
        <div className="wave-lottie">
          <Lottie options={waveOptions} />
        </div>
      </Container>
    </section>
  );
}

export default Banner;
