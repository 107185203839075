import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { ForgetPassword } from "../../../redux/auth/action";
import { forgotPasswordSchema } from "../../../validation/validationSchema";

/**
 * ForgotPasswordModal Component
 * Displays a modal with a form to reset the user's password by submitting their email address.
 *
 * @param {object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {function} props.handleClose - Function to close the modal.
 *
 * @returns {JSX.Element} The rendered ForgotPasswordModal component.
 */
const ForgotPasswordModal = (props) => {
  /**
   * React Hook Form setup
   * `register` - Registers form fields into the React Hook Form system.
   * `handleSubmit` - Handles form submission with validation.
   * `errors` - Contains validation error messages, if any.
   * `reset` - Resets form fields to their initial values.
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  /**
   * Closes the modal and resets the form fields.
   * Called when the modal close button is clicked or on successful submission.
   */
  const handleClose = () => {
    props.handleClose();
    reset();
  };

  /**
   * Handles form submission for password reset.
   * Sends the entered email address to the backend to trigger a reset email.
   *
   * @param {object} data - Contains form data, specifically the user's email.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data?.email,
    };
    const response = await ForgetPassword(payload);
    if (response?.success === true) {
      Swal.fire({
        icon: "success",
        title: "Email has been sent to you",
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal
        className="custom-modal form-modal"
        show={props.show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="title">Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Email <span className="steric-check">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="email@example.com"
                {...register("email")}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
