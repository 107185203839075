import React from "react";
import about from "../../../assets/images/about-sec.png";
import AlLogo from "../../../assets/images/about-al-logo.png";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./about.css";
function About() {
  return (
    <section className="about-sec position-relative">
      <Container className="custom-container" fluid>
        <Row className="justify-content-between flex-md-row flex-column-reverse">
          <Col xl={4} md={5}>
            <div className="about-img">
              <Image src={about} fluid alt="sec-img" />
            </div>
          </Col>
          <Col md={7}>
            <div className="content-wrapper position-relative mb-md-0 mb-5">
              <span className="pre-heading">About</span>
              <h2>RAK Mediation & Arbitration</h2>
              <p>
                Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
                otent oevnec qdfruis libero molestie. Sed ut purus vel est
                pulvinar eleife ellensque eget arcu in enim condimentum
                pellentesque ornare id enim aecenas.
              </p>
              <p>
                Ultrices ante. In semper lobortis metus, eget elementum eros
                aliquet non. Integer leo ipsum, dignissim et interdum et,
                malesuada a velit. In et nisi viverra, suscipit tortor a,
                faucibus neque. Aenean vel augue enim. Aliquam augue odio,
                aliquam ornare tincidunt et, fringilla sed Mauris molestie
                imperdiet nisl, nec auctor mi sceleri vitae eros quis{" "}
                <b>ligula facilisis posuere. .</b>
              </p>
              <Button variant="none" className="btn-blue">
                READ MORE
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="sec-right-logo d-flex align-items-center justify-content-end">
        <Image src={AlLogo} fluid alt="al logo" />
      </div>
    </section>
  );
}

export default About;
