import React from "react";
import { Form } from "react-bootstrap";

const ApplicationStatus = () => {
  return (
    <>
      <Form>
        <h1>Hello</h1>
      </Form>
    </>
  );
};

export default ApplicationStatus;
