import React from "react";
import "./stats.css";
import { Container, Image, Stack } from "react-bootstrap";
import statsIcon1 from "../../../assets/images/icon-stats1.svg";
import statsIcon2 from "../../../assets/images/icon-stats2.svg";
import statsIcon3 from "../../../assets/images/icon-stats3.svg";
import statsIcon4 from "../../../assets/images/icon-stats4.svg";

function Stats() {
  return (
    <section className="stats-sec position-relative">
      <Container fluid className="custom-container">
        <Stack
          direction="horizontal"
          className="justify-content-between flex-wrap w-100"
        >
          <div className="content-wrapper text-center">
            <div className="icon-wrapper mb-3">
              <Image src={statsIcon1} alt="stats icon" fluid />
            </div>
            <span className="value text-white d-inline-block mb-1">6.5B</span>
            <p className="text-white mb-0">
              The average value of registered disputes per year
            </p>
          </div>
          <div className="content-wrapper text-center">
            <div className="icon-wrapper mb-3">
              <Image src={statsIcon2} alt="stats icon" fluid />
            </div>
            <span className="value text-white d-inline-block mb-1">3.2k</span>
            <p className="text-white mb-0">
              Total registered <br className="d-sm-inline-block d-none" /> cases
            </p>
          </div>
          <div className="content-wrapper text-center">
            <div className="icon-wrapper mb-3">
              <Image src={statsIcon3} alt="stats icon" fluid />
            </div>
            <span className="value text-white d-inline-block mb-1">200</span>
            <p className="text-white mb-0">
              Cases registered in <br className="d-sm-inline-block d-none" />{" "}
              2023
            </p>
          </div>
          <div className="content-wrapper text-center">
            <div className="icon-wrapper mb-3">
              <Image src={statsIcon4} alt="stats icon" fluid />
            </div>
            <span className="value text-white d-inline-block mb-1">
              7.8 Billion
            </span>
            <p className="text-white mb-0">
              Total amount in dispute{" "}
              <br className="d-sm-inline-block d-none" /> in 2022
            </p>
          </div>
        </Stack>
      </Container>
    </section>
  );
}

export default Stats;
