import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { validatePassword } from "../../utils/validations/validation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPassword } from "../../redux/auth/action";
import Swal from "sweetalert2";

const ResetPasswordComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [errMsg, setErrMsg] = useState({
    password: "",
    confirmPassword: "",
  });
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  const handleChange = (event) => {
    const value = event.target.value;
    setPassword({ ...password, [event.target.name]: value });
  };

  const passwordFormSubmit = async () => {
    let passwordMsg = "";
    let confirmPasswordMsg = "";
    let check = true;

    if (password.new === "") {
      passwordMsg = "Password is required";
      check = false;
    } else {
      passwordMsg = validatePassword(password.new);
      if (passwordMsg) check = false;
    }

    if (password.new && password.confirm && password.new !== password.confirm) {
      check = false;
      confirmPasswordMsg = "Passwords do not match";
    }

    if (password.new && !password.confirm) {
      check = false;
      confirmPasswordMsg = "Confirm Password is Required";
    }
    setErrMsg({
      password: passwordMsg,
      confirmPassword: confirmPasswordMsg,
    });
    if (check) {
      const payload = {};
      payload.userId = userId;
      payload.token = token;
      payload.password = password.new;
      payload.confirmPassword = password.confirm;
      const response = await ResetPassword(payload);
      if (response?.success === true) {
        Swal.fire({
          icon: "success",
          title: "your password has been reset successfully",
          showConfirmButton: false,
          timer: 1000,
        });
        setPassword({});
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      }
    }
  };
  return (
    <>
      <Row>
        <Col lg="4" md="8">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("newPassword")}</Form.Label>
              <Form.Control
                type="password"
                name="new"
                onChange={(event) => handleChange(event)}
                value={password.new}
              ></Form.Control>
            </Form.Group>
            <span className={errMsg.password ? `` : `d-none`}>
              <label className="pl-1 pt-0 text-danger">{errMsg.password}</label>
            </span>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("confirmPassword")}</Form.Label>
              <Form.Control
                type="password"
                name="confirm"
                onChange={(event) => handleChange(event)}
                value={password.confirm}
              ></Form.Control>
            </Form.Group>
            <span className={errMsg.confirmPassword ? `` : `d-none`}>
              <label className="pl-1 pt-0 text-danger">
                {errMsg.confirmPassword}
              </label>
            </span>
            <Button onClick={(e) => passwordFormSubmit(e)}>
              {" "}
              {t("submit")}{" "}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ResetPasswordComponent;
