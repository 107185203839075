import React from "react";
import * as yup from "yup";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { faqSchema } from "../../../validation/validationSchema";

import "./faq.css";

function Faq() {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(faqSchema),
  });

  const onSubmit = (data) => {
    // Handle form submission
  };

  return (
    <section className="faq-sec">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={8}>
            <div className="content-wrapper me-xxl-5 me-lg-4">
              <div className="mb-lg-5 mb-4">
                <p className="fa-text mb-0">Frequently Asked</p>
                <span className="question fw-semibold">Questions</span>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="0" flush>
                <Accordion.Item
                  className="bg-transparent border-0"
                  eventKey="0"
                >
                  <Accordion.Header className="position-relative">
                    Help desk and legal development quick help numbers
                  </Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Aliquam iaculis pellentesque orci euismod porttitor. Nam
                    imperdiet feugiat dui, sit amet rutrum ipsum. Proin
                    imperdiet nisl felis. Nam ullamcorper leo nec dui mollis
                    egestas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="bg-transparent border-0"
                  eventKey="1"
                >
                  <Accordion.Header className="position-relative">
                    Help desk and legal development quick help numbers
                  </Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Aliquam iaculis pellentesque orci euismod porttitor. Nam
                    imperdiet feugiat dui, sit amet rutrum ipsum. Proin
                    imperdiet nisl felis. Nam ullamcorper leo nec dui mollis
                    egestas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="bg-transparent border-0"
                  eventKey="2"
                >
                  <Accordion.Header className="position-relative">
                    Help desk and legal development quick help numbers
                  </Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Aliquam iaculis pellentesque orci euismod porttitor. Nam
                    imperdiet feugiat dui, sit amet rutrum ipsum. Proin
                    imperdiet nisl felis. Nam ullamcorper leo nec dui mollis
                    egestas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="bg-transparent border-0"
                  eventKey="3"
                >
                  <Accordion.Header className="position-relative">
                    Help desk and legal development quick help numbers
                  </Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Aliquam iaculis pellentesque orci euismod porttitor. Nam
                    imperdiet feugiat dui, sit amet rutrum ipsum. Proin
                    imperdiet nisl felis. Nam ullamcorper leo nec dui mollis
                    egestas.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
          <Col lg={4}>
          <Form className="faq-form text-white" onSubmit={handleSubmit(onSubmit)}>
              <h4 className="mb-md-5 mb-4 pe-xl-5">
                Still Have A Question About Our Services
              </h4>

              {/* Name Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your name*"
                  className="text-white bg-transparent"
                  {...register("name")}
                  isInvalid={errors.name}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </InputGroup>

              {/* Email Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Your email*"
                  className="text-white bg-transparent"
                  {...register("email")}
                  isInvalid={errors.email}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </InputGroup>

              {/* Subject Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Subject*"
                  className="text-white bg-transparent"
                  {...register("subject")}
                  isInvalid={errors.subject}
                />
                {errors.subject && (
                  <div className="invalid-feedback">{errors.subject.message}</div>
                )}
              </InputGroup>

              {/* Message Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Message*"
                  rows={5}
                  className="text-white bg-transparent resize-none"
                  {...register("message")}
                  isInvalid={errors.message}
                />
                {errors.message && (
                  <div className="invalid-feedback">{errors.message.message}</div>
                )}
              </InputGroup>

              <div className="text-end">
                <Button
                  variant="none"
                  className="btn-blue bg-white text-blue"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Faq;
