import {
  LOADER_FALSE,
  LOADER_TRUE,
  CREATE_ARBITRATION,
  CREATE_COPY,
  CREATE_CORRECTION_REQUEST,
} from "../actionTypes";

const initialState = {
  arbitrationData: {},
  duplicationData: {},
  correctionData: {},
  loader: false,
};

const e_servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ARBITRATION:
      return {
        ...state,
        arbitrationData: action.payload,
      };
    case CREATE_COPY:
      return {
        ...state,
        duplicationData: action.payload,
      };
    case CREATE_CORRECTION_REQUEST:
      return {
        ...state,
        correctionData: action.payload,
      };
    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };
    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    default:
      return state;
  }
};

export default e_servicesReducer;
