import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { servicesBanner } from "../../components/images";
import OurVision from "../../components/common/ourVision/ourVision";
import AccountingCosts from "./accountingCosts/accountingCosts";
import ListOfServices from "./listOfServices/listOfServices";
import Faq from "../../components/common/faq/faq";
import Footer from "../../components/common/footer/footer";

const Services = () => {
  return (
    <>
      <Header />
      <Banner
        img={servicesBanner}
        title="Services"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <OurVision />
      <AccountingCosts />
      <ListOfServices />
      <Faq />
      <Footer />
    </>
  );
};

export default Services;
