import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./caseManagement.css";
import { alLogo, caseManagement } from "../../../components/images";

const CaseManagement = () => {
  return (
    <section className="case-management">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={7}>
            <div className="content-wrapper mb-lg-0 mb-5">
              <h2 className="mb-3">Case Management</h2>
              <p className="p-lg">
                Case managers act as advocates and facilitators, connecting
                clients with appropriate resources and support.
              </p>
              <p>
                Case management is a systematic approach to assisting
                individuals or groups in navigating complex systems to achieve
                specific outcomes. It involves the coordination, assessment,
                planning, and monitoring of services to meet the unique needs of
                a client or a particular case.
              </p>
              <p>
                Case managers act as advocates and facilitators, connecting
                clients with appropriate resources and support. The process
                often includes assessing the client's needs, developing a
                tailored plan, collaborating with relevant service providers,
                and regularly reviewing progress. Whether applied in healthcare,
                social services, or legal contexts, case management aims to
                enhance efficiency, improve outcomes, and promote the well-being
                of those in need through a holistic and client-centered
                approach. Case managers act as advocates and facilitators,
                connecting clients with appropriate resources and support. The
                process often includes assessing the client's needs, developing
                a tailored plan, collaborating with relevant service providers,
                and regularly reviewing progress. Whether applied in healthcare,
                social services, or legal contexts,
              </p>
            </div>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} className="position-relative">
            <div className="sec-img ps-lg-0 ps-4">
              <Image src={caseManagement} alt="section img" fluid />
            </div>
            <div className="al-logo">
              <Image src={alLogo} alt="al logo" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CaseManagement;
