import React from "react";
import "./newsDetail.css";
import { Container, Image } from "react-bootstrap";
import { newsDetail } from "../../../components/images";
const NewsDetail = () => {
  return (
    <section className="news-detail-sec">
      <Container fluid className="custom-container">
        <div className="content-wrapper pb-5">
          <h4 className="mb-4">
            Highlighting the centre’s leading role in serving the economic and
            business sectors in Sharjah
          </h4>
          <div className="img-wrapper mb-lg-4 mb-3">
            <Image src={newsDetail} alt="blog img" fluid />
            <p className="date">
              <span className="fw-semibold">22</span>SEP
            </p>
          </div>
          <p>
            Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
            Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
            otent oevnec qdfruis libero molestie. Sed ut purus vel est pulvinar
            eleife ellensque eget arcu in enim condimentum pellentesque ornare
            id enim aecenaste. In semper lobortis metus, eget elementum eros
            aliquet non. Integer.
          </p>
          <p>
            Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
            Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
            otent oevnec qdfruis libero molestie. Sed ut purus vel est pulvinar
            eleife ellensque eget arcu in enim condimentum pellentesque ornare
            id enim aecenaste. In semper lobortis metus, eget elementum eros
            aliquet non. Integer. am blandit placerat nunc, at auctor nisl
            elementum eu. Maecenas pretium accumsan finibus. Nam et scelerisque
            lacus, ac laoreet leo. In molestie justo in neque tincidunt, a
            eleifend metus ultricies. Integer at euismod dui. Donec aliquam
            tortor diam, eget dignissim lectus ullamcorper eu. Curabitur ac odio
            ultricies augue eleifend ultricies. Sed porttitor ante sodales sem
            accumsan, in aliquet elit pellentesque. Sed at velit sit amet lorem
            pretium bibendum. Donec ac odio vel metus sodales vestibulum a sed
            elit.
          </p>
          <p>
            <span className="text-blue fw-semibold">
              Aliquam sodales congue sodales. Praesent pellentesque magna eu
              ultricies luctus.
            </span>{" "}
            Proin id interdum nibh, quis fermentum quam. Sed id ligula ut eros
            volutpat malesuada sed porta nisl. Nunc blandit, diam auctor sodales
            sagittis, ex nisi varius lacus, vitae malesuada nisi neque quis
            urna. Pellentesque dapibus elementum erat, ut faucibus nisi
            ullamcorper ac. Vivamus quis metus mollis, pulvinar ipsum in, semper
            enim. In hac habitasse platea dictumst.
          </p>
          <p>
            Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
            Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
            otent oevnec qdfruis libero molestie. Sed ut purus vel est pulvinar
            eleife ellensque eget arcu in enim condimentum pellentesque ornare
            id enim aecenaste. In semper lobortis metus, eget elementum eros
            aliquet non. Integer.
          </p>
          <p>
            Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
            Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
            otent oevnec qdfruis libero molestie. Sed ut purus vel est pulvinar
            eleife ellensque eget arcu in enim condimentum pellentesque ornare
            id enim aecenaste. In semper lobortis metus, eget elementum eros
            aliquet non. Integer. am blandit placerat nunc, at auctor nisl
            elementum eu. Maecenas pretium accumsan finibus. Nam et scelerisque
            lacus, ac laoreet leo. In molestie justo in neque tincidunt, a
            eleifend metus ultricies. Integer at euismod dui. Donec aliquam
            tortor diam, eget dignissim lectus ullamcorper eu. Curabitur ac odio
            ultricies augue eleifend ultricies. Sed porttitor ante sodales sem
            accumsan, in aliquet elit pellentesque. Sed at velit sit amet lorem
            pretium bibendum. Donec ac odio vel metus sodales vestibulum a sed
            elit.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default NewsDetail;
