import React from "react";
import { Button, Container, Image, Row, Col } from "react-bootstrap";
import AlLogo from "../../../assets/images/about-al-logo.png";
import Head from "../../../assets/images/head-speech.png";
import "./speechByHead.css";

function SpeechByHead() {
  return (
    <section className="head-speech position-relative">
      <Container>
        <Row className="justify-content-between">
          <Col xl={4} lg={5}>
            <div className="head-img text-lg-left text-center mb-lg-0 mb-5">
              <Image src={Head} alt="head of center" fluid />
            </div>
          </Col>
          <Col lg={7}>
            <div className="content-wrapper">
              <h2>Speech By The Head Of The Center</h2>
              <p className="mb-4">
                Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
                otent oevnec qdfruis libero molestie. Sed ut purus vel est
                pulvinar eleife ellensque eget arcu in enim condimentum
                pellentesque ornare id enim aecenas.
              </p>
              <p className="mb-4">
                Ultrices ante. In semper lobortis metus, eget elementum eros
                aliquet non. Integer leo ipsum, dignissim et interdum et,
                malesuada a velit. In et nisi viverra, suscipit tortor a,
                faucibus neque. Aenean vel augue enim. Aliquam augue odio,
                aliquam ornare tincidunt et, fringilla sed Mauris molestie
                imperdiet nisl, nec auctor mi sceleri vitae eros quis ligula
                facilisis posuere. .
              </p>
              <Button variant="none" className="btn-blue">
                CONTACT US
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="sec-right-logo d-flex align-items-center justify-content-end">
        <Image src={AlLogo} fluid alt="al logo" />
      </div>
    </section>
  );
}

export default SpeechByHead;
