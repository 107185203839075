import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { juryFormation } from "../../../components/images";
import "./formation.css";
const Formation = () => {
  return (
    <section className="jury-formation">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={6}>
            <h2 className="mb-3">Formation Of Jury Panel</h2>
            <p className="mb-lg-4 mb-3">
              In traditional legal proceedings, the formation of a jury panel is
              a critical step in ensuring a fair and unbiased trial. The jury
              panel is typically composed of individuals from the community who
              are randomly selected to hear evidence, weigh arguments, and
              deliver a verdict in a legal case. This diverse group aims to
              represent a cross-section of society, contributing to the
              impartiality of the judicial process.
            </p>
            <p className="mb-0">
              Jury selection involves a careful examination of potential jurors
              to identify any biases or conflicts that could affect their
              ability to make an objective decision. Once selected, the jury
              panel plays a pivotal role in upholding the principles of justice
              and contributing to the democratic foundation of the legal system.
            </p>
          </Col>
          <Col lg={6}>
            <div className="img-wrapper">
              <Image src={juryFormation} alt="jury formation img" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Formation;
