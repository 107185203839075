import React from "react";
import "./financialAffairs.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { financialAffairs, halfAlLogo } from "../../../components/images";

const FinancialAffairs = () => {
  return (
    <section className="financial-affairs position-relative">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col xl={4} lg={5}>
            <div className="sec-img mb-lg-0 mb-4 text-lg-left text-center">
              <Image src={financialAffairs} alt="Section img" fluid />
            </div>
          </Col>
          <Col xl={{ span: 7, offset: 1 }} lg={7}>
            <div className="content-wrapper pe-xl-5 pe-lg-4">
              <h2 className="mb-3">Financial Affairs Department</h2>
              <p>
                Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
                otent oevnec qdfruis libero molestie. Sed ut purus vel est
                pulvinar eleife ellensque eget arcu in enim condimentum
                pellentesque ornare id enim aecenas.
              </p>
              <p>
                Ultrices ante. In semper lobortis metus, eget elementum eros
                aliquet non. Integer leo ipsum, dignissim et interdum et,
                malesuada a velit. In et nisi viverra, suscipit tortor a,
                faucibus neque. Aenean vel augue enim. Aliquam augue odio,
                aliquam ornare tincidunt et, fringilla sed Mauris molestie
                imperdiet nisl, nec auctor mi sceleri vitae eros quis{" "}
                <span className="fw-semibold">ligula facilisis posuere. .</span>
              </p>
              <Button variant="none" className="btn-blue mt-2">
                Contact Us
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="sec-right-logo d-flex align-items-center justify-content-end">
        <Image src={halfAlLogo} fluid alt="al logo" />
      </div>
    </section>
  );
};

export default FinancialAffairs;
