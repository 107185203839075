import React from "react";
import { Container } from "react-bootstrap";
import "./centralBuildingSec.css";

const CentralBuildingSec = ({ title, content1, content2 }) => {
  return (
    <section className="central-building-sec text-center text-white position-relative">
      <Container fluid className="custom-container">
        <h2 className="mb-3 text-white">{title}</h2>
        <p className="mb-4">{content1}</p>
        <p className="mb-0">{content2}</p>
      </Container>
    </section>
  );
};

export default CentralBuildingSec;
