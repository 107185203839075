import {
  CREATE_ARBITRATION,
  CREATE_COPY,
  LOADER_TRUE,
  LOADER_FALSE,
  CREATE_CORRECTION_REQUEST,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { helperFunction } from "../../utils/helperFunctions";

export const createArbitrationRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/e-services/create-request`,
      data,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      dispatch({
        type: CREATE_ARBITRATION,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const createduplicationRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/e-services/create-certified-copy`,
      data,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      dispatch({
        type: CREATE_COPY,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const createInterpretationRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/e-services/create-interpretation-request`,
      data,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      dispatch({
        type: CREATE_CORRECTION_REQUEST,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const createFormRequest = (data) => async (dispatch) => {
  const token = helperFunction.getToken("Token")
    ? JSON.parse(helperFunction.getToken("Token"))
    : "";
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/e-services/create-request`,
      data,
      token,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      // dispatch({
      //     type: CREATE_CORRECTION_REQUEST,
      //     payload: data
      // })
      return data;
    }
  } catch (error) {
    dispatch({
      type: LOADER_FALSE,
    });
    toast.error(error?.response?.data?.message);
  }
};
