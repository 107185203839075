import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, FormGroup } from "react-bootstrap";
import RadioButtonGroup from "../../../../../../utils/htmlElements/radioButton/radioButtonGroup";
import SelectGroup from "../../../../../../utils/htmlElements/select/selectGroup";
import { createFormRequest } from "../../../../../../redux/e-services/action";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import LanguageComponent from "../languages/languageComponent";

const ArbitratorRegistration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationOption = ["New Request", "Renewal Request"];
  const [applicationStatus, setApplicationStatus] = useState(null);
  const genderOptions = ["male", "female"];
  const [selectedGenders, setSelectedGenders] = useState(null);
  const titleOption = ["Doctor", "Professor", "Lawyer", "Other"];
  const [arabicOption, setArabicOption] = useState({});
  const [englishOption, setEnglishOption] = useState({});
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [academicQualification, setAcademicQualification] = useState([
    {
      degree: "",
      educationalInstitute: "",
      location: "",
      certificateDate: "",
      document: "",
    },
  ]);
  const [errExpertMsg, setErrExpertMsg] = useState({
    degree: "",
    educationalInstitute: "",
    location: "",
    certificateDate: "",
    document: "",
  });
  const [selectedSpecalization, setSelectedSpecalization] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = useMemo(() => countryList().getData(), []);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    dob: "",
    landline: "",
    mobile: "",
    placeOfWork: "",
    presentJob: "",
    city: "",
    fax: "",
    Pbox: "",
    email: "",
  });
  const [errMsg, setErrMsg] = useState({
    name: "",
  });

  const specializationOptions = [
    "Conflicts of Law",
    "Engineerig",
    "Commercial Law",
    "Construction Law",
    "Company Law",
    "Competition Law",
    "Contracts",
    "Joint Ventures",
    "Bankruptcy",
    "Copyright",
    "Envoirmental law",
    "Foreign Investment",
    "Aviation Law",
    "Maritime Law",
    "Oil & Gas Law",
    "Royalites Sharia Law",
    "Taxtaion",
    "Bank",
    "Transfer of Technology",
  ];

  const [fieldOfSpecialization, setfieldOfSpecialization] = useState([
    { titleOfProgram: "", organizationName: "", year: "", document: "" },
  ]);
  const [errfieldOfSpecialization, seterrFieldOfSpecialization] = useState({
    titleOfProgram: "",
    organizationName: "",
    year: "",
    document,
  });
  const [fieldOfArbitrataion, setfieldOfArbitrataion] = useState([
    { reportType: "", reportYear: "" },
  ]);

  // const [errfieldOfArbitrataion, seterrFieldOfArbitrataion] = useState({
  //   reportType: "",
  //   reportYear: "",
  // });
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [professionalMembership, setProfessionalMembership] = useState([
    { membership: "", joinDate: "", valid: false, notValid: false },
  ]);

  const [errprofessionalMembership, seterrProfessionalMembership] = useState({
    membership: "",
    joinDate: "",
    status: "",
  });

  const [attachments, setAttachments] = useState({
    idCard: "",
    image: "",
    gcCertificate: "",
    cv: "",
  });
  const [otherLanguage, setOtherLanguage] = useState("");
  const [erremploymentHistoryMsg, setErrEmploymentHistoryMsg] = useState({
    organization: "",
    jobPosition: "",
    from: "",
    to: "",
  });
  const [employmentHistory, setEmploymentHistory] = useState([
    { organization: "", jobPosition: "", from: "", to: "" },
  ]);

  const handleOtherCheckboxChange = (e) => {
    setShowOtherInput(e.target.checked);
    if (!e.target.checked) {
      setOtherLanguage("");
    }
  };

  const handleOtherInputChange = (e) => {
    setOtherLanguage(e.target.value);
  };

  const handleArabicOption = (key) => {
    setArabicOption((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleEnglishOption = (key) => {
    setEnglishOption((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSpecializationChange = (option) => {
    setSelectedSpecalization((prevOptions) =>
      prevOptions?.includes(option)
        ? prevOptions.filter((value) => value !== option)
        : [...prevOptions, option],
    );
  };

  const handleAcademicQualificationChange = (index, event) => {
    const { name, value } = event.target;
    if (event?.target?.files) {
      const file = event?.target?.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const updatedQualification = [...academicQualification];
      updatedQualification[index][name] = file;
      setAcademicQualification(updatedQualification);
    }
    const updatedQualification = [...academicQualification];
    updatedQualification[index][name] = value;
    setAcademicQualification(updatedQualification);
  };

  const addAcademicQualification = () => {
    setAcademicQualification([
      ...academicQualification,
      {
        degree: "",
        educationalInstitute: "",
        location: "",
        certificateDate: "",
        document: "",
      },
    ]);
  };

  const removeAcademicQualification = (index) => {
    if (academicQualification.length > 1) {
      const updated = [...academicQualification];
      updated.splice(index, 1);
      setAcademicQualification(updated);
    }
  };

  const handleEmploymentHistoryChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...employmentHistory];
    updatedData[index][name] = value;
    setEmploymentHistory(updatedData);
  };

  const addEmploymentHistory = () => {
    setEmploymentHistory([
      ...employmentHistory,
      { organization: "", jobPosition: "", from: "", to: "" },
    ]);
  };

  const removeEmploymentHistory = (index) => {
    if (employmentHistory.length > 1) {
      const updated = [...employmentHistory];
      updated.splice(index, 1);
      setEmploymentHistory(updated);
    }
  };
  const handleFieldOfSpecializationChange = (index, event) => {
    const { name, value } = event.target;
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const updatedData = [...fieldOfSpecialization];
      updatedData[index][name] = file;
      setfieldOfSpecialization(fieldOfSpecialization);
    }
    const updatedData = [...fieldOfSpecialization];
    updatedData[index][name] = value;
    setfieldOfSpecialization(updatedData);
  };

  const addfieldOfSpecialization = () => {
    setfieldOfSpecialization([
      ...fieldOfSpecialization,
      { titleOfProgram: "", organizationName: "", year: "", document },
    ]);
  };

  const removefieldOfSpecialization = (index) => {
    if (fieldOfSpecialization.length > 1) {
      const updated = [...fieldOfSpecialization];
      updated.splice(index, 1);
      setfieldOfSpecialization(updated);
    }
  };
  // const handleFieldOfArbitrataionChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const updatedData = [...fieldOfArbitrataion];
  //   updatedData[index][name] = value;
  //   setfieldOfArbitrataion(updatedData);
  // };

  // const addfieldOfArbitrataion = () => {
  //   setfieldOfArbitrataion([
  //     ...fieldOfArbitrataion,
  //     { reportType: "", reportYear: "" },
  //   ]);
  // };

  // const removefieldOfArbitrataion = (index) => {
  //   if (fieldOfArbitrataion.length > 1) {
  //     const updated = [...fieldOfArbitrataion];
  //     updated.splice(index, 1);
  //     setfieldOfArbitrataion(updated);
  //   }
  // };

  const handleProfessionalMembershipChange = (index, fieldName, value) => {
    const updatedData = [...professionalMembership];
    if (fieldName === "membershipStatus") {
      updatedData[index].valid = value === "Valid";
      updatedData[index].notValid = value === "Not valid";
    } else {
      updatedData[index][fieldName] = value;
    }
    setProfessionalMembership(updatedData);
  };

  const addProfessionalMembership = () => {
    setProfessionalMembership([
      ...professionalMembership,
      { membership: "", joinDate: "", valid: false, notValid: false },
    ]);
  };

  const removeProfessionalMembership = (index) => {
    if (professionalMembership.length > 1) {
      const updated = [...professionalMembership];
      updated.splice(index, 1);
      setProfessionalMembership(updated);
    }
  };

  const handleAttachmentsChange = (index, event) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setAttachments({ ...attachments, [event.target.name]: file });
    }
  };

  const handleUserChange = (event) => {
    const value = event.target.value;
    setUserInfo({ ...userInfo, [event.target.name]: value });
  };

  const handleSubmit = () => {
    let check = true;
    let name = "";
    if (userInfo.name === "") {
      check = false;
      name = "Name is Required";
    }
    setErrMsg({ name });
    if (check) {
      const formData = new FormData();
      const serviceData = {
        applicationStatus,
        userInformation: userInfo,
        selectedGenders,
        selectedNationality,
        selectedCountry,
        academicQualification,
        arabicOption,
        englishOption,
        otherLanguage,
        employmentHistory,
        selectedSpecalization,
        fieldOfSpecialization,
        fieldOfArbitrataion,
        professionalMembership,
      };
      formData.append("serviceType", "arbitrationMemberShip");
      formData.append("serviceData", JSON.stringify(serviceData));
      formData.append("serviceDocument", attachments);
      Swal.fire({
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(createFormRequest(formData));
            if (data?.success === true) {
              toast.success(t("successApiValidation"));
            } else {
              toast.error(t("errorApiValidation"));
            }
          }
        }
      });
    }
  };

  return (
    <section className="arbitrator-registration">
      {/* <h1>ARBITRATOR REGISTRATION REQUEST RENEWAL</h1> */}
      <Form className="membership-form general-form">
        <div className="white-sec">
          <Form.Group className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <Form.Label className="radio-title text-blue fw-semibold mb-0">
              ApplicationStatus
            </Form.Label>
            <RadioButtonGroup
              options={applicationOption}
              selectedValue={applicationStatus}
              setSelectedValue={setApplicationStatus}
              groupName="application"
            />
          </Form.Group>
          <h4 className="mb-4 text-blue">Personal Information</h4>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap mb-4">
            <FormGroup className="label-wrapper w-100">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name *"
                value={userInfo.name}
                onChange={(e) => handleUserChange(e)}
              />
              {userInfo.name === "" ? (
                <span className={errMsg.name ? `` : `d-none`}>
                  <label className="pl-1 pt-0 text-danger">
                    {errMsg.name ? t("nameValidation") : ""}
                  </label>
                </span>
              ) : (
                ""
              )}
            </FormGroup>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={userInfo.dob}
                onChange={(e) => handleUserChange(e)}
                placeholder="Date Of Birth"
              />
              <span className={errMsg.dob ? `` : `d-none`}>
                <label className="pl-1 pt-0 text-danger">{errMsg.dob}</label>
              </span>
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Nationality</Form.Label>
              <SelectGroup
                options={countries}
                value={selectedNationality}
                onChange={(e) => setSelectedNationality(e)}
                placeholder="Nationality"
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Landline Number</Form.Label>
              <Form.Control
                type="text"
                name="landline"
                placeholder="Landline Number"
                value={userInfo.landline}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                placeholder="Mobile Number"
                value={userInfo.mobile}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Present Place Of Work</Form.Label>
              <Form.Control
                type="text"
                name="placeOfWork"
                placeholder="Present Place of Work"
                value={userInfo.placeOfWork}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Present Job</Form.Label>
              <Form.Control
                type="text"
                name="presentJob"
                placeholder="Present Job"
                value={userInfo.presentJob}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Country</Form.Label>
              <SelectGroup
                options={countries}
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                value={userInfo.city}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Fax</Form.Label>
              <Form.Control
                type="text"
                name="fax"
                placeholder="Fax"
                value={userInfo.fax}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>P.Box</Form.Label>
              <Form.Control
                type="text"
                name="Pbox"
                placeholder="P.Box"
                value={userInfo.Pbox}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                value={userInfo.email}
                onChange={(e) => handleUserChange(e)}
              />
            </Form.Group>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <p className="radio-title text-blue fw-semibold mb-0">Gender</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <RadioButtonGroup
                options={genderOptions}
                selectedValue={selectedGenders}
                setSelectedValue={setSelectedGenders}
                groupName="gender"
              />
            </Form.Group>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3">
            <p className="radio-title text-blue fw-semibold mb-0">Title</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <RadioButtonGroup
                options={titleOption}
                selectedValue={selectedTitle}
                setSelectedValue={setSelectedTitle}
                groupName="title"
              />
            </Form.Group>
          </div>
        </div>
        <div className="gray-sec">
          <h4 className="text-blue mb-lg-4 mb-3">Academic Qualifications</h4>
          <p className="mb-4">
            Please Provide A Copy Of The Academic Qualifications.
          </p>
          {academicQualification.map((expert, index) => (
            <>
              <div
                className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-sm-start justify-content-end mb-5"
                key={index}
              >
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Degree / Certification</Form.Label>
                  <Form.Control
                    type="text"
                    name="degree"
                    value={expert.degree}
                    onChange={(e) =>
                      handleAcademicQualificationChange(index, e)
                    }
                    placeholder={t("Degree/Certification")}
                  />
                  {expert.degree === "" ? (
                    <span className={errExpertMsg.degree ? `` : `d-none`}>
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {errExpertMsg.degree ? t("degreeValidation") : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Educational Institution</Form.Label>
                  <Form.Control
                    type="text"
                    name="educationalInstitute"
                    value={expert.educationalInstitute}
                    onChange={(e) =>
                      handleAcademicQualificationChange(index, e)
                    }
                    placeholder={t("Educationl Institute")}
                  />
                  {expert.educationalInstitute === "" ? (
                    <span
                      className={
                        errExpertMsg.educationalInstitute ? `` : `d-none`
                      }
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {errExpertMsg.educationalInstitute
                          ? t("educationalInstituteValidation")
                          : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={expert.location}
                    onChange={(e) =>
                      handleAcademicQualificationChange(index, e)
                    }
                    placeholder={t("Location")}
                  />
                  {expert.location === "" ? (
                    <span className={errExpertMsg.location ? `` : `d-none`}>
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {errExpertMsg.location ? t("locationValidation") : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Certificate Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="certificateDate"
                    value={expert.certificateDate}
                    onChange={(e) =>
                      handleAcademicQualificationChange(index, e)
                    }
                    placeholder={t("Certificate Date")}
                  />
                  {expert.certificateDate === "" ? (
                    <span
                      className={errExpertMsg.certificateDate ? `` : `d-none`}
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {errExpertMsg.certificateDate
                          ? t("certificateDateValidation")
                          : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      handleAcademicQualificationChange(index, e)
                    }
                  />
                </Form.Group>
                {index > 0 && (
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => removeAcademicQualification(index)}
                  >
                    {t("Remove")}
                  </Button>
                )}
                <Button
                  variant="none"
                  className="btn-blue btn-sm text-capitalize"
                  onClick={addAcademicQualification}
                >
                  {t("Add")}
                </Button>
              </div>
            </>
          ))}
          <h4 className="text-blue mb-lg-4 mb-3">Languages</h4>
          <Form.Group className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <Form.Label className="radio-title text-blue fw-semibold mb-0">
              Arabic
            </Form.Label>
            <LanguageComponent
              checked={arabicOption}
              handleCheck={handleArabicOption}
            />
          </Form.Group>
          <Form.Group className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <Form.Label className="radio-title text-blue fw-semibold mb-0">
              English
            </Form.Label>
            <LanguageComponent
              checked={englishOption}
              handleCheck={handleEnglishOption}
            />
          </Form.Group>
          <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
            <Form.Label className="fw-semibold text-blue mb-md-0">
              Other Languages
            </Form.Label>
            <Form.Check type="checkbox" onChange={handleOtherCheckboxChange} />
            {showOtherInput && (
              <Form.Control
                type="text"
                placeholder="Enter other language"
                value={otherLanguage}
                onChange={handleOtherInputChange}
              />
            )}
          </Form.Group>
        </div>
        <div className="white-sec">
          <h4 className="text-blue mb-lg-4 mb-3">Employment History</h4>
          <p className="mb-4">
            Please Summarize The Career History Over The Past 5 Years.
          </p>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end mb-5">
            {employmentHistory.map((data, index) => (
              <React.Fragment key={index}>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Organization Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="organization"
                    value={data.organization}
                    onChange={(e) => handleEmploymentHistoryChange(index, e)}
                    placeholder={t("Organization Name")}
                  />
                  {data.organization === "" ? (
                    <span
                      className={
                        erremploymentHistoryMsg.organization ? `` : `d-none`
                      }
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {erremploymentHistoryMsg.organization
                          ? t("organizationValidation")
                          : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="text"
                    name="from"
                    value={data.from}
                    onChange={(e) => handleEmploymentHistoryChange(index, e)}
                    placeholder={t("From")}
                  />
                  {data.from === "" ? (
                    <span
                      className={erremploymentHistoryMsg.from ? `` : `d-none`}
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {erremploymentHistoryMsg.from
                          ? t("fromValidation")
                          : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="text"
                    name="to"
                    value={data.to}
                    onChange={(e) => handleEmploymentHistoryChange(index, e)}
                    placeholder={t("to")}
                  />
                  {data.to === "" ? (
                    <span
                      className={erremploymentHistoryMsg.to ? `` : `d-none`}
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {erremploymentHistoryMsg.to ? t("toValidation") : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="label-wrapper w-100">
                  <Form.Label>Job Position</Form.Label>
                  <Form.Control
                    type="text"
                    name="jobPosition"
                    value={data.jobPosition}
                    onChange={(e) => handleEmploymentHistoryChange(index, e)}
                    placeholder={t("Job Position")}
                  />
                  {data.jobPosition === "" ? (
                    <span
                      className={
                        erremploymentHistoryMsg.jobPosition ? `` : `d-none`
                      }
                    >
                      <Form.Label className="pl-1 pt-0 text-danger">
                        {erremploymentHistoryMsg.jobPosition
                          ? t("jobPositionValidation")
                          : ""}
                      </Form.Label>
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                {index > 0 && (
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => removeEmploymentHistory(index)}
                  >
                    {t("Remove")}
                  </Button>
                )}
              </React.Fragment>
            ))}
            <Button
              variant="none"
              className="btn-blue btn-sm text-capitalize"
              onClick={addEmploymentHistory}
            >
              {t("Add")}
            </Button>
          </div>
          <h4 className="text-blue mb-lg-4 mb-3">Specialization</h4>
          <div className="check-list-wrapper d-flex flex-wrap gap-md-3 gap-2">
            <RadioButtonGroup
              options={specializationOptions}
              selectedValue={selectedSpecalization}
              setSelectedValue={handleSpecializationChange}
              groupName="specialization"
              type="checkbox"
            />
          </div>
        </div>
        <div className="gray-sec">
          <div className="mb-lg-5 mb-4">
            <div className="title-wrapper mb-4">
              <h4 className="text-blue mb-lg-4 mb-3">
                Training In Dispute Resolution / Arbitration
              </h4>
              <p className="mb-0">
                Please Indicate Training Programs In The Area Of Commercial
                Dispute Resolution And Arbitration In Which You Participated
                With Providing Us With Proof Of Attendance Of Such Programs.
              </p>
            </div>
            <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end">
              {fieldOfSpecialization.map((data, index) => (
                <React.Fragment key={index}>
                  <Form.Group className="label-wrapper w-100">
                    <Form.Label>Title Of Program</Form.Label>
                    <Form.Control
                      type="text"
                      name="titleOfProgram"
                      value={data.titleOfProgram}
                      onChange={(e) =>
                        handleFieldOfSpecializationChange(index, e)
                      }
                      placeholder={t("Title Of Program")}
                    />
                    {data.titleOfProgram === "" ? (
                      <span
                        className={
                          errfieldOfSpecialization.titleOfProgram
                            ? ``
                            : `d-none`
                        }
                      >
                        <Form.Label className="pl-1 pt-0 text-danger">
                          {errfieldOfSpecialization.titleOfProgram
                            ? t("titleOfProgramValidation")
                            : ""}
                        </Form.Label>
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="label-wrapper w-100">
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationName"
                      value={data.organizationName}
                      onChange={(e) =>
                        handleFieldOfSpecializationChange(index, e)
                      }
                      placeholder={t("Organization Name")}
                    />
                    {data.organizationName === "" ? (
                      <span
                        className={
                          errfieldOfSpecialization.organizationName
                            ? ``
                            : `d-none`
                        }
                      >
                        <Form.Label className="pl-1 pt-0 text-danger">
                          {errfieldOfSpecialization.organizationName
                            ? t("organizationNameValidation")
                            : ""}
                        </Form.Label>
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="label-wrapper w-100">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="year"
                      value={data.year}
                      onChange={(e) =>
                        handleFieldOfSpecializationChange(index, e)
                      }
                      placeholder={t("Year")}
                    />
                    {data.year === "" ? (
                      <span
                        className={
                          errfieldOfSpecialization.year ? `` : `d-none`
                        }
                      >
                        <Form.Label className="pl-1 pt-0 text-danger">
                          {errfieldOfSpecialization.year
                            ? t("yearValidation")
                            : ""}
                        </Form.Label>
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="label-wrapper w-100">
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        handleFieldOfSpecializationChange(index, e)
                      }
                    ></Form.Control>
                  </Form.Group>
                  {index > 0 && (
                    <Button
                      variant="danger"
                      className="btn-sm float-start"
                      onClick={() => removefieldOfSpecialization(index)}
                    >
                      {t("Remove")}
                    </Button>
                  )}
                </React.Fragment>
              ))}
              <Button
                variant="none"
                className="btn-blue btn-sm text-capitalize"
                onClick={addfieldOfSpecialization}
              >
                {t("Add")}
              </Button>
            </div>
          </div>
          <div className="title-wrapper mb-4">
            <h4 className="text-blue mb-lg-4 mb-3">
              Experience In The Field Of Arbitration
            </h4>
            <p>
              Number And Nature Of Cases In Which You Participated As An
              Arbitrator And/Or Advocate In The Last 5.Please Note
            </p>
            <ul className="general-list list-unstyled mb-4">
              <li className="fw-normal">
                If The Arbitration Is Private, Please Provide Us With A Brief
                Summary Of The Parties Without Compromising The Confidentiality
                Of The Information.
              </li>
              <li className="fw-normal">
                If The Arbitration Was Done Through An Arbitration Center,
                Please Provide Us With A Reference Number For Each Case.
              </li>
            </ul>
          </div>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Arbitral Institution</Form.Label>
              <Form.Control type="text" placeholder="Arbitral Institution" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Case Ref. No</Form.Label>
              <Form.Control type="text" placeholder="Case Ref. No" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Dispute Type</Form.Label>
              <Form.Control type="text" placeholder="Dispute Type" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Approximate Sum In Dispute</Form.Label>
              <Form.Control
                type="text"
                placeholder="Approximate Sum In Dispute"
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Date Of Award Rendered</Form.Label>
              <Form.Control type="date" placeholder="Date Of Award Rendered" />
            </Form.Group>
          </div>
        </div>
        <div className="white-sec mb-lg-5 mb-4 border-bottom-1">
          <div className="title-wrapper mb-4">
            <h4 className="text-blue mb-lg-4 mb-3">Professional Membership</h4>
            <p className="mb-0">
              Please Indicate The Institutions / Parties To Resolve The Disputes
              To Which You Belong As A Member.
            </p>
          </div>
          {professionalMembership?.map((data, index) => (
            <div
              className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end mb-md-4 mb-3"
              key={index}
            >
              <Form.Group className="label-wrapper w-100">
                <Form.Label>Membership</Form.Label>
                <Form.Control
                  type="text"
                  name="membership"
                  value={data.membership}
                  onChange={(e) =>
                    handleProfessionalMembershipChange(
                      index,
                      "membership",
                      e.target.value,
                    )
                  }
                  placeholder={t("membership")}
                />
                {data.membership === "" ? (
                  <span
                    className={
                      errprofessionalMembership.membership ? `` : `d-none`
                    }
                  >
                    <Form.Label className="pl-1 pt-0 text-danger">
                      {errprofessionalMembership.membership
                        ? t("membershipValidation")
                        : ""}
                    </Form.Label>
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3">
                <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
                  <Form.Label className="radio-title text-blue fw-semibold mb-0">
                    Membership Status
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Valid"
                    name={`membershipStatus-${index}`}
                    checked={data.valid}
                    onChange={() =>
                      handleProfessionalMembershipChange(
                        index,
                        "membershipStatus",
                        "Valid",
                      )
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Not valid"
                    name={`membershipStatus-${index}`}
                    checked={data.notValid}
                    onChange={() =>
                      handleProfessionalMembershipChange(
                        index,
                        "membershipStatus",
                        "Not valid",
                      )
                    }
                  />
                </Form.Group>
              </div>
              {index > 0 && (
                <Button
                  variant="danger"
                  className="btn-sm float-start"
                  onClick={() => removeProfessionalMembership(index)}
                >
                  {t("Remove")}
                </Button>
              )}
            </div>
          ))}
          <div className="text-end mb-4">
            <Button
              type="button"
              className="btn-blue btn-sm text-capitalize"
              onClick={addProfessionalMembership}
            >
              {t("Add")}
            </Button>
          </div>
          <div className="mb-lg-4 mb-3">
            <h4 className="text-blue mb-lg-4 mb-3">
              Publications And Literature
            </h4>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Label>
                Do You Have Any Intellectual Contributions In The Field Of
                Arbitration?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                name="contributions"
                value="Yes"
              />
              <Form.Check
                type="radio"
                label="No"
                name="contributions"
                value="No"
              />
            </Form.Group>
          </div>
          <h4 className="text-blue mb-lg-4 mb-3">Attachments</h4>
          <div className="d-flex gap-md-4 gap-3 align-items-end flex-sm-row flex-column mb-md-4 mb-3">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>
                Copy Of Passport Or Photo Of Identity Card
              </Form.Label>
              <Form.Control
                name="idCard"
                type="file"
                onChange={(e) => handleAttachmentsChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Personal Photo</Form.Label>
              <Form.Control
                name="image"
                type="file"
                onChange={(e) => handleAttachmentsChange(e)}
              />
            </Form.Group>
          </div>
          <div className="d-flex gap-md-4 gap-3 align-items-end flex-sm-row flex-column">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Certificate Of Good Conduct</Form.Label>
              <Form.Control
                name="gcCertificate"
                type="file"
                onChange={(e) => handleAttachmentsChange(e)}
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>CV</Form.Label>
              <Form.Control
                name="cv"
                type="file"
                onChange={(e) => handleAttachmentsChange(e)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="text-end btn-sec">
          <Button
            variant="none"
            type="submit"
            onClick={() => handleSubmit()}
            className="btn-blue text-uppercase"
          >
            {t("Submit")}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default ArbitratorRegistration;
