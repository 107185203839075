import React from "react";
import "./photoAlbum.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  albumPhoto1,
  albumPhoto2,
  albumPhoto3,
  albumPhoto4,
} from "../../../components/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faImage } from "@fortawesome/free-solid-svg-icons";

function PhotoAlbum() {
  const photoCard = [
    {
      id: 1,
      img: albumPhoto1,
      date: "29 Nov 2022",
      title: "Loram ipsum",
    },
    {
      id: 2,
      img: albumPhoto2,
      date: "26 Nov 2022",
      title: "Portugal",
    },
    {
      id: 3,
      img: albumPhoto3,
      date: "22 Nov 2022",
      title: "UAE Flag",
    },
    {
      id: 4,
      img: albumPhoto4,
      date: "29 Sep 2022",
      title: "Ramadan Tent",
    },
    {
      id: 5,
      img: albumPhoto1,
      date: "29 Nov 2022",
      title: "Loram ipsum",
    },
    {
      id: 6,
      img: albumPhoto2,
      date: "26 Nov 2022",
      title: "Portugal",
    },
    {
      id: 7,
      img: albumPhoto3,
      date: "22 Nov 2022",
      title: "UAE Flag",
    },
    {
      id: 8,
      img: albumPhoto4,
      date: "29 Sep 2022",
      title: "Ramadan Tent",
    },
  ];
  return (
    <section className="photo-album">
      <Container>
        <h2 className="mb-lg-5 mb-4">Photo Albums</h2>
        <Row className="mb-lg-4 mb-3 gx-lg-4 gx-3">
          {photoCard.map((item,index) => (
            <Col lg={3} md={4} xs={6} key={index}>
              <div
                className="content-wrapper position-relative mb-3"
                role="button"
              >
                <div className="img-warpper position-relative">
                  <Image src={item.img} alt="ablum photo" fluid />
                </div>
                <div className="details position-absolute p-xl-3 p-2">
                  <span className="date text-yellow">{item.date}</span>
                  <h4 className="title text-white mb-0">{item.title}</h4>
                </div>
                <div className="hover-content text-white">
                  <div className="img-icons position-relative mb-3">
                    <FontAwesomeIcon
                      icon={faImage}
                      className="img-icon1"
                      fontSize={30}
                    />
                    <FontAwesomeIcon
                      icon={faImage}
                      className="img-icon2"
                      fontSize={30}
                    />
                    <FontAwesomeIcon icon="fa-solid fa-image" />
                  </div>
                  <p className="fw-semibold">View Album</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <p className="text-blue text-uppercase mb-0 fw-semibold">LOAD MORE</p>
          <FontAwesomeIcon icon={faChevronDown} fontSize={25} role="button" />
        </div>
      </Container>
    </section>
  );
}

export default PhotoAlbum;
