import authReducer from "./auth/reducer";
import e_servicesReducer from "./e-services/reducer";
import userReducer from "./user/reducer";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import {thunk} from "redux-thunk";
import ArbitratorReducer from "./arbitrator/e-services/reducer";

const middleware = [thunk];
const reducer = combineReducers({
  auth: authReducer,
  e_services: e_servicesReducer,
  user: userReducer,
  Arbitrator: ArbitratorReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
