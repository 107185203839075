import React from "react";
import Banner from "../../components/common/banner/banner";
import Header from "../../components/common/header/header";
import { newsBannerBg } from "../../components/images/index";
import NewsDetail from "./newsDetail/newsDetail";
import LatestNews from "../../components/common/latestNews/latestNews";
import Footer from "../../components/common/footer/footer";

function News() {
  return (
    <>
      <Header />
      <Banner
        title="Al Khaimah News"
        content="Chamber is very keen in providing the decision makers with information and opinions concerning the commercial and industrial issues."
        img={newsBannerBg}
      />
      <NewsDetail />
      <LatestNews mainTitle="Other Latest News" />
      <Footer />
    </>
  );
}

export default News;
