import React from "react";
import { Button,  Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import {Controller, useForm } from "react-hook-form";
import { helperFunction } from "../../../../utils/helperFunctions";
import FullPageLoader from "../../../FullPageLoader/FullPageLoader";
import {
  doubleString
} from "../../../../utils/validations/validation";
import { updateUser } from "../../../../redux/user/action";
import { useDispatch, useSelector } from "react-redux";
import { profileSchema } from "../../../../validation/validationSchema";

const ArbitratorProfile = () => {
  const userKey = helperFunction.getUserKey("userId")
    ? doubleString(helperFunction.getUserKey("userId"))
    : "";
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.user.loader);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      profileImage: null, 
    },
  });
  
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("userName", data?.username);
    formData.append("profileImage", data?.profileImage);
    formData.append("id", userKey);
    dispatch(updateUser(formData));
  };

  return (
    <>
      {loader ? <FullPageLoader /> : null}
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formProfileImage">
          <Form.Label>Profile Image</Form.Label>
          <Controller
            control={control}
            name="profileImage"
            render={({ field }) => (
              <Form.Control
                type="file"
                onChange={(e) => field.onChange(e.target.files[0])}
              />
            )}
          />
          <span className="text-danger">{errors?.profileImage?.message}</span>
        </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>UserName</Form.Label>
        <Form.Control
            type="text"
            {...register("username")}
            placeholder="Enter your name"
          />
          <span className="pl-1 pt-0 text-danger">{errors?.username?.message}</span>
      </Form.Group>
      <div className="remember-login mb-md-4 mb-3 d-sm-flex align-items-center justify-content-between text-sm-start text-end">
        <Button
          className="btn-blue"
          variant="none"
          type="submit"
        >
          update
        </Button>
      </div>
      </Form>
    </>
  );
};

export default ArbitratorProfile;
