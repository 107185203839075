import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ServicesCard from "../../components/common/servicesCard/servicesCard";
import {
  alLogo,
  lawService1,
  lawService2,
  lawService3,
  lawService4,
  lawService5,
  lawService6,
  lawService7,
  lawService8,
  lawService9,
} from "../../components/images";
import "./aboutLaws.css";

const AboutLaws = () => {
  const lawsContent = [
    {
      img: lawService1,
      title: "Sharjah Center for International Commercial Arbitration Rules",
      content:
        "Guided by international best practices, the Sharjah Center for International Commercial Arbitration Rules facilitates expeditious",
    },
    {
      img: lawService2,
      title: "Dubai International Arbitration Center (DIAC)rules",
      content:
        "Built upon principles of neutrality and procedural efficiency, the DIAC rules offer a cutting-edge platform",
    },
    {
      img: lawService3,
      title: "Ras Al Khaimah Center by laws and rules",
      content:
        "Driven by international standards, the rules of the Ras Al Khaimah Center provide a structured and transparent framework",
    },
    {
      img: lawService4,
      title: "Law of the United Arab Emirates on arbitration",
      content:
        "Encompassing principles of neutrality and enforceability, the UAE's arbitration law empowers businesses to engage",
    },
    {
      img: lawService5,
      title: "Arbitration laws of the Gulf Cooperation Council",
      content:
        "The arbitration laws of the Gulf Cooperation Council (GCC) harmonize dispute resolution practices across member states",
    },
    {
      img: lawService6,
      title: "Arbitration laws of the Arab states",
      content:
        "Arbitration laws across Arab states reflect a collective commitment to modernizing dispute resolution",
    },
    {
      img: lawService7,
      title: "Arbitration laws of foreign countries o The New York Convention",
      content:
        "Arbitration laws of countries, in adherence to The New York Convention, provide a recognized framework for the enforcement",
    },
    {
      img: lawService8,
      title:
        "The Gulf Cooperation Council agreement o Riyadh agreement on judicial cooperation",
      content:
        "Aligned with the principles of the GCC, the Riyadh Agreement aims to enhance",
    },
    {
      img: lawService9,
      title: "Arbitration laws of the UAE",
      content:
        "This legislation aligns with international standards, providing a clear and comprehensive structure for arbitration",
    },
  ];
  return (
    <section className="about-laws">
      <Container fluid className="custom-container position-relative">
        <div className="sec-al-logo">
          <Image src={alLogo} alt="al logo" fluid />
        </div>
        <div className="text-center mb-lg-5 mb-4 px-xl-5 px-lg-4">
          <p className="mb-0">
            The New Arbitration Law repeals Articles 203 to 218 of Federal Law
            No. (11) of 1992, i.e. the Civil Procedure Law and hence creates a
            new ecosystem for arbitral proceedings to take place henceforth. It
            applies to arbitration proceedings that commenced before its
            issuance, whilst recognizing the validity of the processes followed
            until it comes into force.
          </p>
        </div>
        <Row className="gy-4">
          {lawsContent.map((item, index) => (
            <Col lg={4} sm={6} key={index}>
              <ServicesCard
                img={item.img}
                title={item.title}
                content={item.content}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default AboutLaws;
