import {
  REGISTER_USER,
  LOGIN_USER,
  ERR_USER,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { helperFunction } from "../../utils/helperFunctions";

/**
 * Action creator for registering a new user.
 *
 * @param {Object} user - The user data for registration.
 * @returns {Function} - A function that dispatches actions based on the registration outcome.
 */
export const RegisterUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper("post", `/api/v1/user/sign-up`, user);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      helperFunction.saveItem("userId", data.data.id);
      dispatch({
        type: REGISTER_USER,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LOADER_FALSE,
    });
  }
};

/**
 * Action creator for logging in a user.
 *
 * @param {Object} user - The user credentials for login.
 * @returns {Function} - A function that dispatches actions based on the login outcome.
 */
export const LoginUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper("post", `/api/v1/user/log-in`, user);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      helperFunction.encryptUserData(data.data.id, data?.jwtToken);
      dispatch({
        type: LOGIN_USER,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LOADER_FALSE,
    });
    dispatch({
      type: ERR_USER,
      payload: error?.response?.data?.message,
    });
  }
};

/**
 * Action creator for initiating password recovery.
 *
 * @param {Object} data - The email or username of the user requesting password recovery.
 * @returns {Promise<Object>} - A promise that resolves with the response data if successful.
 */
export const ForgetPassword = async (data) => {
  try {
    // dispatch({
    //     type: LOADER_TRUE
    // })
    const res = await apiHelper("post", `/api/v1/user/forget-password`, data);
    if (res?.data) {
      // dispatch({
      //     type: LOADER_FALSE
      // })
      const { data } = res;
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    // dispatch({
    //     type: LOADER_FALSE
    // })
  }
};

/**
 * Action creator for resetting a user's password.
 *
 * @param {Object} data - The new password data.
 * @returns {Promise<Object>} - A promise that resolves with the response data if successful.
 */
export const ResetPassword = async (data) => {
  try {
    // dispatch({
    //     type: LOADER_TRUE
    // })
    const res = await apiHelper("patch", `/api/v1/user/reset-password`, data);
    if (res?.data) {
      // dispatch({
      //     type: LOADER_FALSE
      // })
      const { data } = res;
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    // dispatch({
    //     type: LOADER_FALSE
    // })
  }
};

/**
 * Action creator for resending the verification email to a user.
 *
 * @param {Object} data - The user's email or username for verification.
 * @returns {Function} - A function that dispatches actions based on the resend outcome.
 */
export const ResendEmail = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/user/resend-verify-email`,
      data,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LOADER_FALSE,
    });
  }
};
