import React from "react";
import { Button, Form } from "react-bootstrap";

const ExpertRegistration = () => {
  return (
    <section className="expert-registration">
      <Form className="membership-form general-form">
        <div className="white-sec">
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <p className="radio-title text-blue fw-semibold mb-0">
              Application Status
            </p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="status"
                type="radio"
                value="New Request"
                label="New Request"
              />
              <Form.Check
                name="status"
                type="radio"
                value="Renewel Request"
                label="Renewel Request"
              />
            </Form.Group>
          </div>
          <h4 className="mb-4 text-blue">Personal Information</h4>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap mb-4">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Name*</Form.Label>
              <Form.Control type="text" placeholder="Name*" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control type="date" placeholder="Date Of Birth" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Nationality</Form.Label>
              <Form.Control type="text" placeholder="Nationality" />
            </Form.Group>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <p className="radio-title text-blue fw-semibold mb-0">Gender</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="gendre"
                type="radio"
                value="Male"
                label="Male"
              />
              <Form.Check
                name="gendre"
                type="radio"
                value="Female"
                label="Female"
              />
            </Form.Group>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3">
            <p className="radio-title text-blue fw-semibold mb-0">Title</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="title"
                type="radio"
                value="Doctor"
                label="Doctor"
              />
              <Form.Check
                name="title"
                type="radio"
                value="Professor"
                label="Professor"
              />
              <Form.Check
                name="title"
                type="radio"
                value="Lawyer"
                label="Lawyer"
              />
              <Form.Check
                name="title"
                type="radio"
                value="Other"
                label="Other"
              />
            </Form.Group>
          </div>
        </div>
        <div className="gray-sec">
          <h4 className="text-blue mb-lg-4 mb-3">Address</h4>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Landline Number</Form.Label>
              <Form.Control type="text" placeholder="Landline Number" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" placeholder="Mobile Number" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Present Place Of Work</Form.Label>
              <Form.Control type="text" placeholder="Present Place Of Work" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Present Job</Form.Label>
              <Form.Control type="text" placeholder="Present Job" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" placeholder="Country" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" placeholder="City" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Fax</Form.Label>
              <Form.Control type="text" placeholder="Fax" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>P.Box</Form.Label>
              <Form.Control type="text" placeholder="P.Box" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" />
            </Form.Group>
          </div>
        </div>
        <div className="white-sec">
          <h4 className="text-blue mb-lg-4 mb-3">Academic Qualifications</h4>
          <p className="mb-4">
            Please Provide A Copy Of The Academic Qualifications.
          </p>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-sm-start justify-content-end mb-5">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Degree / Certification</Form.Label>
              <Form.Control type="text" placeholder="Degree / Certification" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Educational Institution</Form.Label>
              <Form.Control type="text" placeholder="Educational Institution" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Location</Form.Label>
              <Form.Control type="text" placeholder="Location" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Certificate Date</Form.Label>
              <Form.Control type="calendar" placeholder="Certificate Date" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Control type="file" placeholder="Attachment" />
            </Form.Group>
            <Button variant="none" className="btn-blue btn-sm text-capitalize">
              Add
            </Button>
          </div>
          <h4 className="text-blue mb-lg-4 mb-3">Languages</h4>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <p className="radio-title text-blue fw-semibold mb-0">Arabic</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="arabic"
                type="radio"
                value="Reading"
                label="Reading"
              />
              <Form.Check
                name="arabic"
                type="radio"
                value="Written"
                label="Written"
              />
              <Form.Check
                name="arabic"
                type="radio"
                value="Spoken"
                label="Spoken"
              />
            </Form.Group>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3 mb-lg-5 mb-4">
            <p className="radio-title text-blue fw-semibold mb-0">English</p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="english"
                type="radio"
                value="Reading"
                label="Reading"
              />
              <Form.Check
                name="english"
                type="radio"
                value="Written"
                label="Written"
              />
              <Form.Check
                name="english"
                type="radio"
                value="Spoken"
                label="Spoken"
              />
            </Form.Group>
          </div>
          <Form.Group className="label-wrapper mb-3">
            <Form.Label className="fw-semibold text-blue mb-3">
              Other Languages
            </Form.Label>
            <Form.Control type="calendar" placeholder="Other Languages" />
          </Form.Group>
        </div>
        <div className="gray-sec">
          <h4 className="text-blue mb-lg-4 mb-3">Employment History</h4>
          <p className="mb-4">
            Please Summarize The Career History Over The Past 5 Years.
          </p>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end mb-5">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control type="text" placeholder="Organization Name" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>From</Form.Label>
              <Form.Control type="date" placeholder="Select Date" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>To</Form.Label>
              <Form.Control type="date" placeholder="Select Date" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Job Position</Form.Label>
              <Form.Control type="calendar" placeholder="Job Position" />
            </Form.Group>
            <Button variant="none" className="btn-blue btn-sm text-capitalize">
              Add
            </Button>
          </div>
          <h4 className="text-blue mb-lg-4 mb-3">Specialization</h4>
          <div className="check-list-wrapper d-flex flex-wrap gap-md-3 gap-2">
            <Form.Check type="checkbox" label="Conflict Of Law" />
            <Form.Check type="checkbox" label="Engineering" />
            <Form.Check type="checkbox" label="Commercial Law" />
            <Form.Check type="checkbox" label="Construction Law" />
            <Form.Check type="checkbox" label="Company Law" />
            <Form.Check type="checkbox" label="Competition Law" />
            <Form.Check type="checkbox" label="Contracts" />
            <Form.Check type="checkbox" label="Joint Ventures" />
            <Form.Check type="checkbox" label="Bankruptcy" />
            <Form.Check type="checkbox" label="Copyright" />
            <Form.Check type="checkbox" label="Environmental Law" />
            <Form.Check type="checkbox" label="Foreign Investment" />
            <Form.Check type="checkbox" label="Aviation Law" />
            <Form.Check type="checkbox" label="Maritime Law" />
            <Form.Check type="checkbox" label="Oil & Amp; Gas Law" />
            <Form.Check type="checkbox" label="Royalties Sharia Law" />

            <Form.Check type="checkbox" label="Taxation" />
            <Form.Check type="checkbox" label="Banks" />
            <Form.Check type="checkbox" label="Transfer Of Technologies" />
            <Form.Check type="checkbox" label="Other" />
          </div>
        </div>
        <div className="white-sec">
          <div className="mb-lg-5 mb-4">
            <div className="title-wrapper mb-4">
              <h4 className="text-blue mb-lg-4 mb-3">
                Training In The Field Of Specialization
              </h4>
              <p className="mb-0">
                Please Indicate Training Programs In The Area Of Commercial
                Dispute Resolution And Arbitration In Which You Participated
                With Providing Us With Proof Of Attendance Of Such Programs.
              </p>
            </div>
            <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end">
              <Form.Group className="label-wrapper w-100">
                <Form.Label>Title Of Program</Form.Label>
                <Form.Control type="text" placeholder="Title Of Program" />
              </Form.Group>
              <Form.Group className="label-wrapper w-100">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control type="text" placeholder="Organization Name" />
              </Form.Group>
              <Form.Group className="label-wrapper w-100">
                <Form.Label>Year</Form.Label>
                <Form.Control type="number" placeholder="Year" />
              </Form.Group>
              <Form.Group className="label-wrapper w-100">
                <Form.Control type="file" placeholder="Attach file" />
              </Form.Group>
              <Button
                variant="none"
                className="btn-blue btn-sm text-capitalize"
              >
                Add
              </Button>
            </div>
          </div>
          <div className="title-wrapper mb-4">
            <h4 className="text-blue mb-lg-4 mb-3">
              Experience In The Field Of Arbitration
            </h4>
            <p className="mb-0">
              Please Indicate The Number And Nature Of The Arbitration Cases
              That You Have Participated In As Expert During The Last 5 Years.
            </p>
          </div>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Report Type</Form.Label>
              <Form.Control type="text" placeholder="Report Type" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Report Year</Form.Label>
              <Form.Control type="text" placeholder="Report Year" />
            </Form.Group>
            <Button variant="none" className="btn-blue btn-sm text-capitalize">
              Add
            </Button>
          </div>
        </div>
        <div className="gray-sec">
          <div className="title-wrapper mb-4">
            <h4 className="text-blue mb-lg-4 mb-3">Professional Membership</h4>
            <p className="mb-0">
              Please Indicate The Institutions / Parties To Resolve The Disputes
              To Which You Belong As A Member.
            </p>
          </div>
          <div className="input-field-wrapper d-flex gap-md-4 gap-3 flex-wrap align-items-end justify-content-md-start justify-content-end mb-4">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Membership</Form.Label>
              <Form.Control type="text" placeholder="Membership" />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>Membership Join Date</Form.Label>
              <Form.Control type="date" placeholder="Membership Join Date" />
            </Form.Group>
            <Button variant="none" className="btn-blue btn-sm text-capitalize">
              Add
            </Button>
          </div>
          <div className="radios-wrapper d-flex align-items-center flex-wrap gap-3">
            <p className="radio-title text-blue fw-semibold mb-0">
              Membership Status
            </p>
            <Form.Group className="d-flex gap-md-4 gap-3 flex-wrap">
              <Form.Check
                name="Membership Status"
                type="radio"
                value="Valid"
                label="Valid"
              />
              <Form.Check
                name="Membership Status"
                type="radio"
                value="Not Valid"
                label="Not Valid"
              />
            </Form.Group>
          </div>
        </div>
        <div className="white-sec mb-lg-5 mb-4 border-bottom-1">
          <h4 className="text-blue mb-lg-4 mb-3">Attachments</h4>
          <div className="d-flex gap-md-4 gap-3 align-items-end flex-sm-row flex-column mb-md-4 mb-3">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>
                Copy Of Passport Or Photo Of Identity Card
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="Copy Of Passport Or Photo Of Identity Card"
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>
                Copy Of Passport Or Photo Of Identity Card
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="Copy Of Passport Or Photo Of Identity Card"
              />
            </Form.Group>
          </div>
          <div className="d-flex gap-md-4 gap-3 align-items-end flex-sm-row flex-column">
            <Form.Group className="label-wrapper w-100">
              <Form.Label>
                Copy Of Passport Or Photo Of Identity Card
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="Copy Of Passport Or Photo Of Identity Card"
              />
            </Form.Group>
            <Form.Group className="label-wrapper w-100">
              <Form.Label>
                Copy Of Passport Or Photo Of Identity Card
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="Copy Of Passport Or Photo Of Identity Card"
              />
            </Form.Group>
          </div>
        </div>
        <div className="text-end btn-sec">
          <Button variant="none" className="btn-blue" type="submit">
            SUBMIT
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default ExpertRegistration;
