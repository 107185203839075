import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { centerTablesBanner } from "../../components/images";
import Enrollment from "./enrollment/enrollment";
import TranslationSchedule from "./translationSchedule/translationSchedule";
import Membership from "./membership/membership";
import AssociateMember from "./associateMember/associateMember";
import Footer from "../../components/common/footer/footer";
const CenterTable = () => {
  return (
    <div>
      <Header />
      <Banner
        img={centerTablesBanner}
        title="Center Tables"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <Enrollment />
      <TranslationSchedule />
      <Membership />
      <AssociateMember />
      <Footer />
    </div>
  );
};

export default CenterTable;
