import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { juryPanelBanner } from "../../components/images";
import Formation from "./formation/formation";
import RoleOfCenter from "./roleOfCenter/roleOfCenter";
import Procedures from "./procedures/procedures";
import Membership from "./membership/membership";
import TermsOfReferences from "./termsOfReferences/termsOfReferences";
import Footer from "../../components/common/footer/footer";

const JuryPanel = () => {
  return (
    <>
      <Header />
      <Banner
        img={juryPanelBanner}
        title="Jury Panel"
        content="This approach ensures specialized judgment and tailored decision-making in the resolution of conflicts outside the traditional court system."
      />
      <Formation />
      <RoleOfCenter />
      <Procedures />
      <Membership />
      <TermsOfReferences />
      <Footer />
    </>
  );
};

export default JuryPanel;
