import React from "react";
import "./whyArbitration.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { alLogo, whyArbitration } from "../../../components/images";
const WhyArbitration = () => {
  return (
    <section className="why-arbitration position-relative">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={4}>
            <div className="img-wrapper mb-lg-0 mb-4">
              <Image src={whyArbitration} alt="section img" fluid />
            </div>
          </Col>
          <Col lg={{ span: 7, offset: 1 }}>
            <h2>Why Arbitration</h2>
            <p>
              Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
              Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
              otent oevnec qdfruis libero molestie. Sed ut purus vel est
              pulvinar eleife ellensque eget arcu in enim condimentum
              pellentesque ornare id enim aecenas.
            </p>
            <p>
              Ultrices ante. In semper lobortis metus, eget elementum eros
              aliquet non. Integer leo ipsum, dignissim et interdum et,
              malesuada a velit. In et nisi viverra, suscipit tortor a, faucibus
              neque. Aenean vel augue enim. Aliquam augue odio, aliquam ornare
              tincidunt et, fringilla sed Mauris molestie imperdiet nisl, nec
              auctor mi sceleri vitae eros quis{" "}
              <strong className="semibold">ligula facilisis posuere</strong>.
              .Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
              Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
              otent oevnec qdfruis libero molestie. Sed ut purus vel est pulvina
            </p>
          </Col>
        </Row>
      </Container>
      <div className="sec-right-logo d-flex align-items-center justify-content-end">
        <Image src={alLogo} fluid alt="al logo" />
      </div>
    </section>
  );
};

export default WhyArbitration;
