
export const helperFunction = {
  saveItem: function (name, value) {
    localStorage.setItem(`${name}`, JSON.stringify(value));
  },
  removeItem: function (name) {
    localStorage.removeItem(name);
  },
  encryptUserData: function (data, token) {
    if (data) {
      localStorage.setItem("userId", JSON.stringify(data));
    }
    if (token) {
      localStorage.setItem("Token", JSON.stringify(token));
    }
    return true;
  },
  getUserKey: function (keys = null) {
    const userData = localStorage.getItem(keys);
    return userData;
  },
  getToken: function () {
    const userData = localStorage.getItem("Token");
    if (userData) {
      return userData;
    }
    return {};
  },
  getHeaders: function () {
    const token = JSON.parse(localStorage.getItem("Token"));
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    if (token) {
      headers["Authorization"] = "Bearer " + token;
      headers["access-token"] = token;
    }
    return headers;
  },
  logout: function () {
    localStorage.removeItem("uToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("WalletAddress");
  },
  allowedMediaTypes: {
    images: ["jpg", "jpeg", "png", "gif", "svg", "pdf"],
    files: ["pdf"],
  },
};
