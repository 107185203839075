import { UPDATE_USER, LOADER_FALSE, LOADER_TRUE } from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { helperFunction } from "../../utils/helperFunctions";

export const updateUser = (user) => async (dispatch) => {
  const token = helperFunction.getToken("Token")
    ? JSON.parse(helperFunction.getToken("Token"))
    : "";
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper("patch", `/api/v1/user/update-user`, user, token);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      toast.success(`${res?.data?.message}`);
      dispatch({
        type: UPDATE_USER,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const ChangePassword = (user) => async (dispatch) => {
  const token = helperFunction.getToken("Token")
    ? JSON.parse(helperFunction.getToken("Token"))
    : "";
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "patch",
      `/api/v1/user/change-password`,
      user,
      token,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      // toast.success("Password changed successfully")
      dispatch({
        type: UPDATE_USER,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
