import React from "react";
import "./associateMember.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { alLogo, associateMember } from "../../../components/images";
const AssociateMember = () => {
  return (
    <section className="associate-member">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={7}>
            <div className="content-wrapper pe-xl-5 pe-sm-4 mb-lg-0 mb-5">
              <h2>Associate Member Schedule</h2>
              <p>
                In traditional legal proceedings, the formation of a jury panel
                is a critical step in ensuring a fair and unbiased trial. The
                jury panel is typically composed of individuals from the
                community who are randomly selected to hear evidence, weigh
                arguments, and deliver a verdict in a legal case. This diverse
                group aims to represent a cross-section of society, contributing
                to the impartiality of the judicial process.
              </p>
              <p>
                Jury selection involves a careful examination of potential
                jurors to identify any biases or conflicts that could affect
                their ability to make an objective decision. Once selected, the
                jury panel plays a pivotal role in upholding the principles of
                justice and contributing to the democratic foundation of the
                legal system.
              </p>
              <Button variant="none" className="btn-blue">
                CONTACT US
              </Button>
            </div>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} className="position-relative">
            <div className="img-wrapper ms-lg-0 ms-5">
              <Image src={associateMember} alt="associate menber img" fluid />
            </div>
            <div className="al-logo">
              <Image src={alLogo} alt="al logo" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AssociateMember;
