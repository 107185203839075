import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import OurVision from "../../components/common/ourVision/ourVision";
import HistoryOfArbitration from "./historyOfArbitration/historyOfArbitration";
import SpeechByHead from "./speechByHead/speechByHead";
import SecretaryGeneral from "./secretaryGeneral/secretaryGeneral";
import TermsOfReference from "./termsOfReference/termsOfReference";
import OurPartners from "./ourPartners/ourPartners";
import PartnersList from "./partnersList/partnersList";
import Faq from "../../components/common/faq/faq";
import Footer from "../../components/common/footer/footer";
import bannerBg from "../../assets/images/about-banner-bg.png";

function AboutUs() {
  return (
    <>
      <Header />
      <Banner
        title="About Ras Al Khaimah"
        content="Chamber is very keen in providing the decision makers with information and opinions concerning the commercial and industrial issues."
        img={bannerBg}
      />
      <OurVision />
      <HistoryOfArbitration />
      <SpeechByHead />
      <SecretaryGeneral />
      <TermsOfReference />
      <OurPartners />
      <PartnersList />
      <Faq />
      <Footer />
    </>
  );
}

export default AboutUs;
