import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createFormRequest } from "../../../../../redux/e-services/action";
import FullPageLoader from "../../../../FullPageLoader/FullPageLoader";
import E_Services from "../../e-services";
import { claimSchema } from "../../../../../validation/validationSchema";
import { config } from "../../../../../config/config";

const Claim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const loader = useSelector((state) => state.e_services.loader);

  const { register, handleSubmit, setValue, formState: { errors }, reset, control } = useForm({
    resolver: yupResolver(claimSchema),
  });
  const handleCaptchaChange = (value) => {
    setValue("reCaptcha", value); 
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
      formData.append("serviceType", "claim");
      formData.append("claimData", JSON.stringify(data));
      Swal.fire({
        // title: 'Are you sure?',
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(createFormRequest(formData));
            if (data?.success === true) {
              toast.success(t("successApiValidation"));
            }
          }
        }
      });
    
  };

  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
        {loader ? <FullPageLoader /> : null}
        <h2>{t("claimStatement")}</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Date")} </Form.Label>
            <Controller
              name="caseDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors.caseDate} />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.caseDate?.message}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("caseNumber")} </Form.Label>
            <Form.Control type="text" {...register("caseNo")} isInvalid={!!errors.caseNo} />
            <Form.Control.Feedback type="invalid">{errors.caseNo?.message}</Form.Control.Feedback>
          </Form.Group>         
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Legal Representative")}</Form.Label>
            <Form.Control type="text" {...register("legalRepresentative")} isInvalid={!!errors?.legalRepresentative} />
            <Form.Control.Feedback type="invalid">{errors.legalRepresentative?.message}</Form.Control.Feedback>
          </Form.Group>     
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Email")} </Form.Label>
            <Form.Control type="email" {...register("email")} isInvalid={!!errors?.email} />
            <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Phone")}</Form.Label>
            <Form.Control type="text" {...register("phoneNumber")} isInvalid={!!errors?.phoneNumber} />
            <Form.Control.Feedback type="invalid">{errors?.phoneNumber?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Number of Copies")}</Form.Label>
            <Form.Control type="number" {...register("copies")} isInvalid={!!errors?.copies} />
            <Form.Control.Feedback type="invalid">{errors.copies?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature Date")}</Form.Label>
            <Controller
              name="signatureDate"
              control={control}
              render={({ field }) => (
                <Form.Control type="date" {...field} isInvalid={!!errors?.signatureDate} />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.signatureDate?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("Signature")} </Form.Label>
            <Form.Control type="text" {...register("signature")} isInvalid={!!errors?.signature} />
            <Form.Control.Feedback type="invalid">{errors?.signature?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={config?.recaptchaSiteKey}
                      onChange={handleCaptchaChange}
                    />
                     {errors?.reCaptcha && (
                      <div className="invalid-feedback d-block">
                        {errors.reCaptcha?.message}
                      </div>
                    )}
                  </Form.Group>
        <Button variant="primary" type="submit" >
          {t("Save Changes")}
        </Button>
        </Form>
        <Link to={'/dashboard/e-services'}>
          {t("Back to e-services")}
        </Link>
      </div>
    </div>
  )
}

export default Claim;
