import React from "react";
import Event from "./event/event";
import { Col, Container, Row, Image } from "react-bootstrap";
import PollRequests from "./pollRequests/pollRequests";
import ECalculator from "./eCalculator/eCalculator";
import alLogo from "../../../assets/images/large-al-logo.png";
import "./eventPollsCalculator.css";

export default function EventPollsCalculator() {
  return (
    <section className="event-polls-calculator">
      <Container fluid className="custom-container position-relative">
        <div className="sec-al-logo">
          <Image src={alLogo} alt="al logo" fluid />
        </div>
        <h2 className="mb-lg-5 mb-4">Events, Poll, e-Calculator</h2>
        <Row className="justify-content-center">
          <Col xl={4}>
            <Event />
          </Col>
          <Col xl={4} md={6}>
            <PollRequests />
          </Col>
          <Col xl={4} md={6}>
            <ECalculator />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
