import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { conferencesManagement } from "../../../components/images";
import "./management.css";
const Management = () => {
  return (
    <section className="confrences-management">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={7}>
            <h2>
              Management Of Conferences, Training, & External Relations
              Secretarial
            </h2>
            <p className="mb-4">
              In traditional legal proceedings, the formation of a jury panel is
              a critical step in ensuring a fair and unbiased trial. The jury
              panel is typically composed of individuals from the community who
              are randomly selected to hear evidence, weigh arguments, and
              deliver a verdict in a legal case. This diverse group aims to
              represent a cross-section of society, contributing to the
              impartiality of the judicial process.
            </p>
            <p>
              Jury selection involves a careful examination of potential jurors
              to identify any biases or conflicts that could affect their
              ability to make an objective decision. Once selected, the jury
              panel plays a pivotal role in upholding the principles of justice
              and contributing to the democratic foundation of the legal system.
            </p>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <div className="sec-img">
              <Image src={conferencesManagement} alt="section img" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Management;
