import React, { useState, useMemo } from "react";
import { Form, Button } from "react-bootstrap";
import RadioButtonGroup from "../../../../../utils/htmlElements/radioButton/radioButtonGroup";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import SelectGroup from "../../../../../utils/htmlElements/select/selectGroup";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import LanguageComponent from "../arbitratorComponent/languages/languageComponent";
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { createFormRequest } from "../../../../../redux/e-services/action";
import { validateEmail } from "../../../../../utils/validations/validation";
import { toast } from "react-toastify";
import E_Services from "../../e-services";

const MembershipRegistration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationOption = ["New Request", "Renewal Request"];
  const [applicationStatus, setApplicationStatus] = useState("");
  const genderOptions = ["male", "female"];
  const [selectedGenders, setSelectedGenders] = useState("");
  const [selectedTahkeem, setSelectedTahkeem] = useState("");
  const tahkeemOption = ["Yes", "No", "General Information"];
  const AcademicOption = [
    { value: "bachelor", label: "Bachelor" },
    { value: "diploma", label: "Diploma" },
    { value: "institue", label: "Institue" },
    { value: "phd", label: "PHD" },
    { value: "master", label: "Master" },
  ];

  const [selectedAcademic, setSelectedAcademic] = useState({
    value: "",
    label: "",
  });
  const [selectedNationality, setSelectedNationality] = useState({
    value: "",
    label: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    value: "",
    label: "",
  });
  const countries = useMemo(() => countryList().getData(), []);
  const [arabicOption, setArabicOption] = useState({});
  const [englishOption, setEnglishOption] = useState({});
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherLanguage, setOtherLanguage] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    dob: "",
    landline: "",
    mobile: "",
    placeOfBirth: "",
    placeOfWork: "",
    presentJob: "",
    city: "",
    fax: "",
    Pbox: "",
    email: "",
    specialization: "",
    membership: "",
  });
  const [attachments, setAttachments] = useState({
    idCard: "",
    image: "",
    degree: "",
    cv: "",
  });

  const [errMsg, setErrMsg] = useState({
    name: "",
    email: "",
  });

  const handleUserChange = (event) => {
    const value = event.target.value;
    setUserInfo({ ...userInfo, [event.target.name]: value });
  };
  // const handleDateChange = (date) => {
  //   setUserInfo({ ...userInfo, dob: date });
  // };

  const handleOtherCheckboxChange = (e) => {
    setShowOtherInput(e.target.checked);
    if (!e.target.checked) {
      setOtherLanguage("");
    }
  };

  const handleOtherInputChange = (e) => {
    setOtherLanguage(e.target.value);
  };

  const handleArabicOption = (key) => {
    setArabicOption((prev) => ({ ...prev, [key]: !prev[key] }));
  };
  const handleEnglishOption = (key) => {
    setEnglishOption((prev) => ({ ...prev, [key]: !prev[key] }));
  };
    const handleAttachmentsChange = (event) => {
        if (event?.target?.files) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            setAttachments({ ...attachments, [event.target.name]: file })
        }
    };

    const handleSubmit = () => {
        let check = true;
        let name = ""
        let email = ""
        if (userInfo.name === "") {
            check = false;
            name = "Name is Required"
        }
        if (userInfo.email === "") {
            email = "Email is Required"
            check = false;
        } else {
            email = validateEmail(userInfo.email);
            if (email) check = false;
        }
        setErrMsg({ name, email })
        if (check) {
            var formData = new FormData();
            let serviceData = {
                applicationStatus,
                userInformation: userInfo,
                selectedGenders,
                selectedTahkeem,
                selectedNationality,
                selectedCountry,
                selectedAcademic,
                arabicOption,
                englishOption,
                otherLanguage,
            }
          }
        }
    return (
        <div className="main-wrapper dashboard d-flex">
            <E_Services />
            <div className="content-container">
                <section className='membership-registration'>
                    {/* <h1>ARBITRATOR REGISTRATION REQUEST RENEWAL</h1> */}
                    <Form className='membership-form general-form'>
                        <Form.Group>
                            <Form.Label>ApplicationStatus</Form.Label>
                            <RadioButtonGroup options={applicationOption} selectedValue={applicationStatus}
                                setSelectedValue={setApplicationStatus} groupName="application" />
                        </Form.Group>
                        <Form.Label>Personal Information</Form.Label>
                        <Form.Group>
                            <Form.Control type="text" name="name" placeholder="Name *" value={userInfo.name} onChange={(e) => handleUserChange(e)} />
                            {userInfo.name === "" ? <span className={errMsg.name ? `` : `d-none`}>
                                <label className="pl-1 pt-0 text-danger">
                                    {errMsg.name ? t("nameValidation") : ""}
                                </label>
                            </span> : ""}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Gender</Form.Label>
                            <RadioButtonGroup options={genderOptions} selectedValue={selectedGenders}
                                setSelectedValue={setSelectedGenders} groupName="gender" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="placeOfBirth" placeholder="Place Of Birth" value={userInfo.placeOfBirth} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="date" name="dob" value={userInfo.dob} placeholder="Your Custom Placeholder" onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <span className={errMsg.dob ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                                {errMsg.dob}
                            </label>
                        </span>
                        <Form.Group>
                            <Form.Label>Nationality</Form.Label>
                            <SelectGroup options={countries} value={selectedNationality} onChange={(e) => setSelectedNationality(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="mobile" placeholder="Mobile Number" value={userInfo.mobile} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="placeOfWork" placeholder="Present Place of Work" value={userInfo.placeOfWork} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <SelectGroup options={countries} value={selectedCountry} onChange={(e) => setSelectedCountry(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="city" placeholder="City" value={userInfo.city} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="fax" placeholder="Fax" value={userInfo.fax} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="Pbox" placeholder="P.Box" value={userInfo.Pbox} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="email" name="email" placeholder="Email" value={userInfo.email} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>                   
                        {userInfo.email === "" || errMsg.email !== "" ?
                            <label className="pl-1 pt-0 text-danger">
                                {errMsg.email ? t("emailValidation") : ""}
                            </label> : ""}
                        <h3>Academic Qualifications</h3>
                        <Form.Group>
                            <Select options={AcademicOption} value={selectedAcademic} onChange={(e) => setSelectedAcademic(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="specialization" placeholder="Specialization" value={userInfo.specialization} onChange={(e) => handleUserChange(e)} />
                        </Form.Group>

                        <Form>
                            <Form.Group>
                                <h3>Languages</h3>
                                <Form.Label>Arabic</Form.Label>
                                <LanguageComponent checked={arabicOption} handleCheck={handleArabicOption} />
                                <Form.Label>English</Form.Label>
                                <LanguageComponent checked={englishOption} handleCheck={handleEnglishOption} />
                                <Form.Label>Other Langages</Form.Label>
                                <Form.Check type="checkbox" onChange={handleOtherCheckboxChange} />
                                {showOtherInput && (
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter other language"
                                        value={otherLanguage}
                                        onChange={handleOtherInputChange}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group>
                                <Form.Label>Copy of passport or photo of identity card</Form.Label>
                                <div className='d-flex flex-sm-row flex-column gap-sm-4 gap-3 mb-sm-4 mb-3'>
                                    <Form.Control name="idCard" type="file" onChange={(e) => handleAttachmentsChange(e)}></Form.Control>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Personal Photo</Form.Label>
                                <div className='d-flex flex-sm-row flex-column gap-sm-4 gap-3 mb-sm-4 mb-3'>
                                    <Form.Control name="image" type="file" onChange={(e) => handleAttachmentsChange(e)}></Form.Control>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Certificate of good conduct</Form.Label>
                                <div className='d-flex flex-sm-row flex-column gap-sm-4 gap-3 mb-sm-4 mb-3'>
                                    <Form.Control name="degree" type="file" onChange={(e) => handleAttachmentsChange(e)}></Form.Control>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>CV</Form.Label>
                                <div className='d-flex flex-sm-row flex-column gap-sm-4 gap-3 mb-sm-4 mb-3'>
                                    <Form.Control name="cv" type="file" onChange={(e) => handleAttachmentsChange(e)}></Form.Control>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Are you interested in Tahkeem?</Form.Label>
                                <RadioButtonGroup options={tahkeemOption} selectedValue={selectedTahkeem}
                                    setSelectedValue={setSelectedTahkeem} groupName="application" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>What do You Want to be membership?</Form.Label>
                                <Form.Control as="textarea" name="membership" value={userInfo.membership} onChange={(event) => handleUserChange(event)} />
                            </Form.Group>
                            <div className='text-end'>
                                <Button variant='none' onClick={() => handleSubmit()} className='btn-blue text-uppercase'>
                                    {/* SUBMIT */} {t("Submit")}
                                </Button>
                            </div>
                        </Form>
                    </Form>
                    <div>
                        <h6>{t("Note")} :- {t("The application fee Is AED 500")}</h6>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default MembershipRegistration;
