import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";
import { arbitratorServiceCount } from "../../../redux/arbitrator/e-services/action";
import { useDispatch, useSelector } from "react-redux";
import { doubleString } from "../../../utils/validations/validation";
import { helperFunction } from "../../../utils/helperFunctions";

const ArbitratorDashboard = () => {
  const dispatch = useDispatch();
  const arbitrator = useSelector((state) => state.Arbitrator);
  const userKey = helperFunction.getUserKey("userId")
    ? doubleString(helperFunction.getUserKey("userId"))
    : "";

  useEffect(() => {
    if (userKey) {
      dispatch(arbitratorServiceCount(userKey));
    }
  }, [userKey]);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Link to={"/dashboard/arbitrator/profile"}> Update Profile </Link>
        </Col>
        <Col lg={6}>
          <Link to={"/dashboard/arbitrator/application"}> Applications </Link>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {arbitrator?.serviceCount?.serviceCount &&
            arbitrator.serviceCount.serviceCount.map((data, key) => {
              return (
                <div key={key}>
                  {data.serviceStatus === "pending" ? (
                    <h3>
                      Pending Applications:{" "}
                      {data.serviceStatus === "pending" ? data.count : 0}
                    </h3>
                  ) : (
                    ""
                  )}
                  {data.serviceStatus === "closed" ? (
                    <h3>
                      Closed Applications:{" "}
                      {data.serviceStatus === "closed" ? data.count : 0}
                    </h3>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
        </Col>
      </Row>
    </>
  );
};

export default ArbitratorDashboard;
