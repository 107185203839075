import React from "react";
import "./mediationCharacteristics.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  clockIcon,
  collaborativeIcon,
  confidentialityIcon,
  empowermentIcon,
  flexibilityIcon,
  voluntaryIcon,
} from "../../../components/images";
const MediationCharacteristics = () => {
  const cardContent = [
    {
      icon: voluntaryIcon,
      title: "Voluntary Process",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: collaborativeIcon,
      title: "Collaborative Decision-Making",
      content:
        "While there are costs associated with arbitration, it can be more cost- effective than traditional litigation, especially for complex disputes.",
    },
    {
      icon: confidentialityIcon,
      title: "Confidentiality",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: clockIcon,
      title: "Timely Resolution",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
    {
      icon: empowermentIcon,
      title: "Empowerment of Parties",
      content:
        "While there are costs associated with arbitration, it can be more cost- effective than traditional litigation, especially for complex disputes.",
    },
    {
      icon: flexibilityIcon,
      title: "Flexibility",
      content:
        "Arbitrators must be neutral and unbiased, avoiding any conflicts of interest to ensure a fair resolution of the dispute.",
    },
  ];
  return (
    <section className="mediation-characteristics">
      <Container fluid className="custom-container">
        <h2>Characteristics Of Commercial Mediation</h2>
        <Row>
          {cardContent.map((item,index) => (
            <Col lg={4} sm={6} key={index}>
              <div className="card-container mb-md-4 mb-3">
                <div className="card-wrapper d-flex pb-md-3 pb-2">
                  <div className="icon-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
                    <Image src={item.icon} alt="icon" fluid />
                  </div>
                  <div className="content-box">
                    <h6 className="mb-sm-2 mb-1">{item.title}</h6>
                    <p className="mb-0">{item.content}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default MediationCharacteristics;
