import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { mediationBanner } from "../../components/images";
import MediationConcept from "./mediationConcept/mediationConcept";
import WhyMediation from "./whyMediation/whyMediation";
import MediationCenter from "./mediationCenter/mediationCenter";
import MediationTypes from "./mediationTypes/mediationTypes";
import Advantages from "./advantages/advantages";
import MediationCharacteristics from "./mediationCharacteristics/mediationCharacteristics";
import Footer from "../../components/common/footer/footer";

const Mediation = () => {
  return (
    <>
      <Header />
      <Banner
        img={mediationBanner}
        title="Mediation"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <MediationConcept />
      <WhyMediation />
      <MediationCenter />
      <MediationTypes />
      <Advantages />
      <MediationCharacteristics />
      <Footer />
    </>
  );
};

export default Mediation;
