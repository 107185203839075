import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import validator from "validator";
import Swal from "sweetalert2";
import { doubleString } from "../../../utils/validations/validation";
import { helperFunction } from "../../../utils/helperFunctions";
import { ChangePassword } from "../../../redux/user/action";

const ChangePasswordModal = (props) => {
  const dispatch = useDispatch();
  const userKey = helperFunction.getUserKey("userId")
    ? doubleString(helperFunction.getUserKey("userId"))
    : "";
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [passwordMsgCheck, setPasswordMsgCheck] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState("");

  const handleClose = () => {
    props.handleClose();
    // password({});
  };

  const passwordFormSubmit = async (e) => {
    e.preventDefault();
    if (
      !validator.isEmpty(password.new) &&
      !validator.isEmpty(password.confirm)
    ) {
      if (password.new === password.confirm) {
        if (validator.isStrongPassword(password.new)) {
          setPasswordMsgCheck(false);
          const payload = {
            userId: userKey,
            password: password.new,
            confirmPassword: password.confirm,
          };

          const response = await dispatch(ChangePassword(payload));
          if (response?.success === true) {
            Swal.fire({
              icon: "success",
              title: "Password changed successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          setPassword({
            new: "",
            confirm: "",
          });
        } else {
          setPasswordMsg(
            "Password must contain Upper Case, Lower Case, a Special Character, a Number and must be at least 8 characters in length",
          );
          setPasswordMsgCheck(true);
        }
      } else {
        setPasswordMsg("New password & confirm password are not same.");
        setPasswordMsgCheck(true);
      }
    } else {
      setPasswordMsg("You have to fill all fields to change password.");
      setPasswordMsgCheck(true);
    }
  };

  return (
    <>
      <Modal
        className="custom-modal form-modal"
        show={props.show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="title">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Password <span className="steric-check">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                value={password.new}
                onChange={(e) =>
                  setPassword({ ...password, new: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Confirm Password <span className="steric-check">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                value={password.confirm}
                onChange={(e) =>
                  setPassword({ ...password, confirm: e.target.value })
                }
              />
            </Form.Group>
            <span className={passwordMsgCheck ? `` : `d-none`}>
              <label className="pl-1 pt-0 text-danger">{passwordMsg}</label>
            </span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => passwordFormSubmit(e)}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
