import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { contactUsBanner } from "../../components/images";
import Footer from "../../components/common/footer/footer";
import ContactDetails from "./contactDetails/contactDetails";

const ContactUs = () => {
  return (
    <>
      <Header />
      <Banner
        img={contactUsBanner}
        title="Contact"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <ContactDetails />
      <Footer />
    </>
  );
};

export default ContactUs;
