import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { createFormRequest } from "../../../../../redux/e-services/action";
import FullPageLoader from "../../../../FullPageLoader/FullPageLoader";
import { useTranslation } from "react-i18next";
import { joinderSchema } from "../../../../../validation/validationSchema";
import { Link } from "react-router-dom";
import E_Services from "../../e-services";

const Joinder = () => {
  const loader = useSelector((state) => state.e_services.loader);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { control, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(joinderSchema),
    defaultValues: {
      joinderClaimant: [{ name: '', address: '', phoneNumber: '', email: '', lawyerAddress: '' }],
      joinderRespondent: [{ name: '', address: '', phoneNumber: '', email: '' }],
      subject: '',
      claims: '',
      documents: [],
    },
  });

  const { fields: claimantFields, append: appendClaimant, remove: removeClaimant } = useFieldArray({
    control,
    name: "joinderClaimant",
  });

  const { fields: respondentFields, append: appendRespondent, remove: removeRespondent } = useFieldArray({
    control,
    name: "joinderRespondent",
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
      const serviceData = {
        joinderClaimant: data?.joinderClaimant,
        respondent: data?.joinderRespondent,
        subject: data?.subject,
        claims: data?.claims,
      };
      formData.append("serviceType", "joinderRequest");
      formData.append("serviceData", JSON.stringify(serviceData));
      formData.append("serviceDocument", data?.documents);
      Swal.fire({
        text: t("sureMessage"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("CancelButton"),
        confirmButtonText: t("SendButton"),
        showConfirmButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const data = await dispatch(createFormRequest(formData));
            if (data?.success === true) {
              toast.success(t("successApiValidation"));
            } else {
              toast.error(t("errorApiValidation"));
            }
          }
        }
      });
    
  };
  return (
    <div className="main-wrapper dashboard d-flex">
      <E_Services />
      <div className="content-container">
    <section className="arbitration-form-sec">

      <Container fluid className="custom-container">
        {loader ? <FullPageLoader /> : null}
        <h2 className="mb-4 pb-2">{t("joinderRequest")}</h2>
        <div className="intervention-form general-form">
          <h4 className="text-blue mb-lg-4 mb-3">
            {t("Applicant(JoinderClaimant)")}
          </h4>
          <Form onSubmit={handleSubmit(onSubmit)}>
          {claimantFields.map((field, index) => (
              <>
                <Row  key={field?.id}>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Submitted By</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`joinderClaimant.${index}.name`)}                      
                        placeholder={t("Submitted by (Joinder Claimant)*")}
                      />
                      {errors?.joinderClaimant?.[index]?.name && (
                  <span className="text-danger">{errors.joinderClaimant[index]?.name?.message}</span>
                )}          
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                         type="text"
                         {...register(`joinderClaimant.${index}.address`)}
                        placeholder={t("Address")}
                      />
                      {errors.joinderClaimant?.[index]?.address && (
                  <span className="text-danger">{errors?.joinderClaimant[index]?.address?.message}</span>
                )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Work Telephone</Form.Label>
                      <Form.Control
                        type="text"
                        name="workTelephone"
                        {...register(`joinderClaimant.${index}.workTelephone`)}
                        placeholder={t("Work Telephone")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Fax</Form.Label>
                      <Form.Control
                        type="text"
                        name="fax"
                        {...register(`joinderClaimant.${index}.fax`)}
                        placeholder={t("Fax")}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Lawyer")}</Form.Label>
                      <Form.Control
                         type="text"
                         name="lawyer"
                         {...register(`joinderClaimant.${index}.lawyer`)}
                        placeholder={t("Lawyer")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Address*")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lawyerAddress"
                        {...register(`joinderClaimant.${index}.lawyerAddress`)}
                        placeholder={t("Address*")}
                      />
                      {errors.joinderClaimant?.[index]?.lawyerAddress && (
                      <span className="text-danger">
                        {errors.joinderClaimant[index].lawyerAddress.message}
                      </span>
                    )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Mobile Number")}</Form.Label>
                      <Form.Control
                       type="text"
                       name="phoneNumber"
                       {...register(`joinderClaimant.${index}.phoneNumber`)}
                        placeholder={t("Mobile Number")}
                      />
                       {errors.joinderClaimant?.[index]?.phoneNumber && (
                      <span className="text-danger">
                        {errors.joinderClaimant[index].phoneNumber.message}
                      </span>
                    )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        {...register(`joinderClaimant.${index}.email`)}
                        placeholder={t("Email")}
                      />
                       {errors.joinderClaimant?.[index]?.email && (
                      <span className="text-danger">
                        {errors.joinderClaimant[index].email.message}
                      </span>
                    )}
                    </Form.Group>
                  </Col>
                </Row>
                {index > 0 && ( <Button variant="danger" className="float-start" onClick={() => removeClaimant(index)}>{t("Remove")}</Button>)}
              </>
            ))}
            <div className="text-end mb-3">
              <Button              
                className="btn-blue"
                onClick={appendClaimant}
              >
                {t("Add")}
              </Button>
            </div>       
          <div className="white-sec bg-white">
              <Form.Group className="mb-3">
                <Form.Label>{t("Subject")}*</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                type="text"
                {...register("subject")}
                placeholder={t("Subject*")}
              />
              {errors?.subject && (
                <span className="text-danger">{errors?.subject?.message}</span>
              )}
                </Form.Group>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("Claims")}*</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                       as="textarea"
                       rows={3}
                       {...register("claims")}
                       placeholder={t("Claims*")}
                     />
                     {errors?.claims && (
                       <span className="text-danger">{errors?.claims?.message}</span>
                     )}
                </Form.Group>            
              </Form.Group>
          </div>
          <h4 className="text-blue mb-lg-4 mb-3">
            {t("Against (Respondent)")}
          </h4>
   
          {respondentFields?.map((field, index) => (
              <>
                <Row key={field?.id}>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Name")}</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`joinderRespondent.${index}.name`)}
                        placeholder={t("Name")}
                      />
                      {errors.joinderRespondent?.[index]?.name && (
                  <span className="text-danger">{errors.joinderRespondent[index].name.message}</span>
                )}                  
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Fax")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="fax"
                        {...register(`joinderRespondent.${index}.fax`)}
                        placeholder={t("Fax")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Work Telephone")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="workTelephone"
                        {...register(`joinderRespondent.${index}.workTelephone`)}
                        placeholder={t("Work Telephone")}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Address")}*</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`joinderRespondent.${index}.address`)}
                        placeholder={t("Address")}
                      />
                      {errors?.joinderRespondent?.[index]?.address && (
                  <span className="text-danger">{errors.joinderRespondent[index].address.message}</span>
                )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        {...register(`joinderRespondent.${index}.email`)}
                        placeholder={t("Email")}
                      />
                       {errors.joinderRespondent?.[index]?.email && (
                  <span className="text-danger">{errors?.joinderRespondent[index]?.email?.message}</span>
                )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Mobile Number")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        {...register(`joinderRespondent.${index}.phoneNumber`)}
                        placeholder={t("Mobile Number")}
                      />
                       {errors.joinderRespondent?.[index]?.phoneNumber && (
                  <span className="text-danger">{errors?.joinderRespondent[index]?.phoneNumber?.message}</span>
                )}                    
                    </Form.Group>
                  </Col>
                </Row>
                {index > 0 && ( <Button variant="danger" onClick={() => removeRespondent(index)}>{t("Remove")}</Button>)}              
              </>
            ))}
            <div className="text-end mb-lg-5 mb-4">
            <Button variant="primary" type="button" onClick={() => appendRespondent({ name: '', address: '', phoneNumber: '', email: '' })}>{t("Add")}</Button>
            </div>

            <h4 className="text-blue mb-lg-4 mb-3">
            {t("Application Entry")}
          </h4>
   
          {respondentFields?.map((field, index) => (
              <>
                <Row key={field?.id}>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Name")}</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`joinderRespondent.${index}.name`)}
                        placeholder={t("Name")}
                      />
                      {errors.joinderRespondent?.[index]?.name && (
                  <span className="text-danger">{errors.joinderRespondent[index].name.message}</span>
                )}                  
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Fax")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="fax"
                        {...register(`joinderRespondent.${index}.fax`)}
                        placeholder={t("Fax")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Work Telephone")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="workTelephone"
                        {...register(`joinderRespondent.${index}.workTelephone`)}
                        placeholder={t("Work Telephone")}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Address")}*</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`joinderRespondent.${index}.address`)}
                        placeholder={t("Address")}
                      />
                      {errors?.joinderRespondent?.[index]?.address && (
                  <span className="text-danger">{errors.joinderRespondent[index].address.message}</span>
                )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        {...register(`joinderRespondent.${index}.email`)}
                        placeholder={t("Email")}
                      />
                       {errors.joinderRespondent?.[index]?.email && (
                  <span className="text-danger">{errors?.joinderRespondent[index]?.email?.message}</span>
                )}                    
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Mobile Number")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        {...register(`joinderRespondent.${index}.phoneNumber`)}
                        placeholder={t("Mobile Number")}
                      />
                       {errors.joinderRespondent?.[index]?.phoneNumber && (
                  <span className="text-danger">{errors?.joinderRespondent[index]?.phoneNumber?.message}</span>
                )}                    
                    </Form.Group>
                  </Col>
                </Row>
                {index > 0 && ( <Button variant="danger" onClick={() => removeRespondent(index)}>{t("Remove")}</Button>)}              
              </>
            ))}
            <div className="text-end mb-lg-5 mb-4">
            <Button variant="primary" type="button" onClick={() => appendRespondent({ name: '', address: '', phoneNumber: '', email: '' })}>{t("Add")}</Button>
            </div>
         
            <h4 className="text-blue mb-lg-4 mb-3">
              {/* Documents (Legal Power of Attorney, Statement of Claim, Document's Statement ) */}
              {t("joinderDocument")}
            </h4>
            <div className="d-flex flex-sm-row flex-column gap-sm-4 gap-3 mb-sm-4 mb-3">
              {/* <div className='input-box w-100'></div> */}
              <Form.Group className="mb-3">
              <Form.Label>{t("Upload Documents")}</Form.Label>
              <input
                type="file"
                {...register("documents")}
                multiple
              />
              {errors?.documents && (
                <span className="text-danger">{errors?.documents?.message}</span>
              )}
            </Form.Group>
              
            </div>
            <div className="text-end">
              <Button
                variant="none"
                type="submit"
                className="btn-blue text-uppercase"
              >
                {/* SUBMIT */} {t("Save Changes")}
              </Button>
            </div>
          </Form>
          <Link to={'/dashboard/e-services'}>
						{t("Back to e-services")}
					</Link>
        </div>
      </Container>
    </section>
    </div>
    </div>
  );
};
export default Joinder;
