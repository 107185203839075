import React from "react";
import Events from "./events/events";
import Home from "./home/home";
import News from "./news/news";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const Dashboard = () => {

  return (
    <div>
      <h4>Dashboard</h4>
      <Events />
      <Home />
      <News />
      <Row>
        <Col lg={6}>
          <Link to={"/dashboard/e-services"}> E-Services </Link>
        </Col>
        <Col lg={6}>
          <Link to={"/profile"}> Update Profile </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
