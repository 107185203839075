import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { RegisterUser } from "../../redux/auth/action";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { config } from "../../config/config";
import { registerSchema } from "../../validation/validationSchema";

/**
 * Register Component
 * Displays a registration form for new users with validation, CAPTCHA, and profile image upload.
 * Handles the submission of user data and registration process, including confirmation via SweetAlert.
 *
 * @returns {JSX.Element} The rendered Register component.
 */
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const loader = useSelector((state) => state.auth.loader);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(registerSchema), // Use the imported validation schema
  });
  /**
   * Handles the CAPTCHA response and sets its value to form state
   *
   * @param {string} value - The CAPTCHA response token.
   */
  const handleCaptchaChange = (value) => {
    setValue("reCaptcha", value);
  };
  /**
   * Handles the form submission for user registration.
   * Validates the data, submits the form, and shows a confirmation alert before dispatching the registration action.
   *
   * @param {object} data - The form data submitted by the user.
   */
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("userName", data?.userName);
    formData.append("email", data?.email);
    formData.append("password", data?.password);
    formData.append("confirmPassword", data?.confirmPassword);
    formData.append("profileImage", data?.profileImg);
    formData.append("isArbitrator", data?.arbitratorCheck);

    Swal.fire({
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Register",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(RegisterUser(formData));
        if (res?.success) {
          toast.success(
            res?.data?.isArbitrator
              ? "Arbitrator Added Successfully"
              : res?.message,
          );
          navigate("/login");
        }
      }
    });
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <h3>Register a user</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="name of user"
            {...register("userName")}
            isInvalid={!!errors.userName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.userName?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="email of user"
            {...register("email")}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="password"
            {...register("password")}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="confirm password"
            {...register("confirmPassword")}
            isInvalid={!!errors.confirmPassword}
          />
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Profile Image</Form.Label>
          <Controller
            control={control}
            name="profileImage"
            render={({ field }) => (
              <Form.Control
                type="file"
                onChange={(e) => field.onChange(e.target.files[0])}
              />
            )}
          />
          <span className="text-danger">{errors?.profileImage?.message}</span>
        </Form.Group>

        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Register as Arbitrator"
            {...register("arbitratorCheck")}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config?.recaptchaSiteKey}
            onChange={handleCaptchaChange}
          />
          {errors?.reCaptcha && (
            <div className="invalid-feedback d-block">
              {errors.reCaptcha?.message}
            </div>
          )}
        </Form.Group>
        <Link to="/login">Return to login</Link>
        <Button variant="primary" type="submit">
          Save Changes
        </Button>
      </Form>
    </>
  );
};

export default Register;
