import {
  LOADER_TRUE,
  LOADER_FALSE,
  ARBITRATOR_ESERVICES_APPLICATIONS,
  ARBITRATOR_SERVICE_COUNT,
} from "../../actionTypes";
import { apiHelper } from "../../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { helperFunction } from "../../../utils/helperFunctions";

export const getAllEServices = (arbitratorId) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "get",
      `/api/v1/e-services/e-service-filter?arbitratorId=${arbitratorId}`,
    );
    if (res?.data.data) {
      const { data } = res.data;
      dispatch({
        type: LOADER_FALSE,
      });
      dispatch({
        type: ARBITRATOR_ESERVICES_APPLICATIONS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: LOADER_FALSE,
    });
    toast.error(error?.response?.data?.message);
  }
};

export const createResponse = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "post",
      `/api/v1/arbitrator-response/create-response`,
      data,
    );
    if (res?.data) {
      const { data } = res;
      toast.success("Response added successfully");
      dispatch({
        type: LOADER_FALSE,
      });
      // dispatch({
      //     type: ARBITRATOR_ESERVICES_APPLICATIONS,
      //     payload: data
      // })
      return data;
    }
  } catch (error) {
    dispatch({
      type: LOADER_FALSE,
    });
    toast.error(error?.response?.data?.message);
  }
};

export const arbitratorServiceCount = (arbitratorId) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "get",
      `/api/v1/e-services/arbitrator-service-count?arbitratorId=${arbitratorId}`,
    );
    if (res?.data) {
      const { data } = res?.data||{};

      dispatch({
        type: LOADER_FALSE,
      });
      dispatch({
        type: ARBITRATOR_SERVICE_COUNT,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    dispatch({
      type: LOADER_FALSE,
    });
    toast.error(error?.response?.data?.message);
  }
};

export const arbitratorClosedResponse = (id) => async (dispatch) => {
  const token = helperFunction.getToken("Token")
    ? JSON.parse(helperFunction.getToken("Token"))
    : "";
  try {
    dispatch({
      type: LOADER_TRUE,
    });
    const res = await apiHelper(
      "patch",
      `/api/v1/e-services/close-e-service`,
      id,
      token,
    );
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: LOADER_FALSE,
      });
      // dispatch({
      //     type: ARBITRATOR_SERVICE_COUNT,
      //     payload: data
      // })
      return data;
    }
  } catch (error) {
    dispatch({
      type: LOADER_FALSE,
    });
    toast.error(error?.response?.data?.message);
  }
};
