import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./banner.css";
function Banner({ title, content, img }) {
  return (
    <section
      className="common-banner text-white"
      style={{ backgroundImage: `url(${img})` }}
    >
      <Container fluid className="custom-container">
        <Row>
          <Col xxl={7} xl={8} lg={9}>
            <h1 className="mb-3">{title}</h1>
            <p className="mb-0">{content}</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;
