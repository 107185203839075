import React from "react";
import statusIcon from "../../../../assets/images/icon-smile.svg";
import "./pollRequests.css";
import { Button, Image } from "react-bootstrap";

function PollRequests() {
  return (
    <div className="poll-requests text-white mb-xl-0 mb-4">
      <h4 className="pb-3 title">Poll Results</h4>
      <p className="p-lg mb-lg-4">
        What do you think of our new website user experience?
      </p>
      <ul className="poll-results list-unstyled mb-4">
        <li className="d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-sm-3 gap-2">
            <div className="result-icon">
              <Image src={statusIcon} className="mood icon" fluid />
            </div>
            <p className="status mb-0">Excellent</p>
          </div>
          <h4 className="mb-0">33%</h4>
        </li>
        <li className="d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-sm-3 gap-2">
            <div className="result-icon">
              <Image src={statusIcon} className="mood icon" fluid />
            </div>
            <p className="status mb-0">Good</p>
          </div>
          <h4 className="mb-0">32%</h4>
        </li>
        <li className="d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-sm-3 gap-2">
            <div className="result-icon">
              <Image src={statusIcon} className="mood icon" fluid />
            </div>
            <p className="status mb-0">Acceptable</p>
          </div>
          <h4 className="mb-0">25%</h4>
        </li>
        <li className="d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-sm-3 gap-2">
            <div className="result-icon">
              <Image src={statusIcon} className="mood icon" fluid />
            </div>
            <p className="status mb-0">Week</p>
          </div>
          <h4 className="mb-0">10%</h4>
        </li>
      </ul>
      <div className="text-center">
        <Button variant="none" className="yellow-white-btn">
          JOIN US
        </Button>
      </div>
    </div>
  );
}

export default PollRequests;
