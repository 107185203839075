import React from "react";
import { Outlet } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const Layout1 = () => {
  // const { i18n } = useTranslation();

  // const handleTranslate = (event, code) => {
  //   event.preventDefault();
  //   i18n.changeLanguage(code);
  // };
  return (
    <>
      <div className="main-content-area">{<Outlet />}</div>
    </>
  );
};
export default Layout1;
