import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./enrollment.css";
import { enrollment } from "../../../components/images";

const Enrollment = () => {
  return (
    <section className="enrollment-sec">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={7}>
            <div className="content-wrapper mb-lg-0 mb-5">
              <h2>
                Arbitrators' Schedule And Registration Conditions Expert
                Schedule And Conditions Of Enrollment
              </h2>
              <p className="p-lg">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p>
                Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                Pellentesque porttitor elit arcu, vitae mollis diam aliquam non
                otent oevnec qdfruis libero molestie. Sed ut purus vel est
                pulvinar eleife ellensque eget arcu in enim condimentum
                pellentesque ornare id enim aecenas.
              </p>
              <p>
                In accordance with Article 2.1 of Appendix I to the DIAC
                Arbitration Rules in conjunction with Article 40.3 of the DIAC
                Arbitration Rules, the advance on the Tribunal’s fees is fixed
                by the Centre at the average amount calculated by reference to
                the minimum and maximum values indicated in DIAC Table of Fees
                and Costs corresponding the total of the sum(s) claimed and/or
                counterclaimed. In addition, a sum equivalent to 20% of the
                advance on the Tribunal’s fees is added to the advance on costs
                of the arbitration, which is intended to cover the expenses of
                the Tribunal.
              </p>
            </div>
          </Col>
          <Col lg={5}>
            <div className="img-wrapper">
              <Image src={enrollment} alt="enrollment img" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Enrollment;
