import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { ResendEmail } from "../../../redux/auth/action";
import { doubleString } from "../../../utils/validations/validation";
import { helperFunction } from "../../../utils/helperFunctions";
import { resendEmailSchema } from "../../../validation/validationSchema";

const ResendVerifyMail = (props) => {
  const dispatch = useDispatch();
  // Retrieve and double encode the user ID if available
  const userKey = helperFunction.getUserKey("userId")
    ? doubleString(helperFunction.getUserKey("userId"))
    : "";

  /**
   * React Hook Form setup
   * `register` - Registers form fields into the React Hook Form system.
   * `handleSubmit` - Handles form submission with validation.
   * `errors` - Contains validation error messages, if any.
   * `reset` - Resets form fields to their initial values.
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resendEmailSchema),
  });
  const handleClose = () => {
    props.handleClose();
    reset();
  };
  /**
   * Form submit handler to dispatch the resend email action
   * @param {Object} data - Form data containing the user's email.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data?.email,
      userId: userKey,
    };
    const response = await dispatch(ResendEmail(payload));
    if (response?.success === true) {
      Swal.fire({
        icon: "success",
        title: "Verification Email has been sent to you",
        showConfirmButton: false,
        timer: 1500,
      });
      props.handleClose();
    }
  };

  return (
    <>
      <Modal
        className="custom-modal form-modal"
        show={props.show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="title">
            Resend Verification Email?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Email <span className="steric-check">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="email@example.com"
                {...register("email")}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResendVerifyMail;
