import React from "react";
import { Container, Row } from "react-bootstrap";
import { articleImg1 } from "../../images";
import NewsCard from "../newsCard/newsCard";
import "./latestNews.css";

function LatestNews({ mainTitle }) {
  return (
    <section className="latest-news-sec">
      <Container fluid className="custom-container">
        <h2 className="mb-lg-5 mb-4">{mainTitle}</h2>
        <Row>
          <NewsCard
            img={articleImg1}
            preTitle="Fri jun 19 2023 | Category"
            title="Egyptian general authority for investment"
            content="It's a good idea to consider multiple lose sources and for patterns in the feedback."
          />
          <NewsCard
            img={articleImg1}
            preTitle="Fri jun 19 2023 | Category"
            title="Egyptian general authority for investment"
            content="It's a good idea to consider multiple lose sources and for patterns in the feedback."
          />
          <NewsCard
            img={articleImg1}
            preTitle="Fri jun 19 2023 | Category"
            title="Egyptian general authority for investment"
            content="It's a good idea to consider multiple lose sources and for patterns in the feedback."
          />
          <NewsCard
            img={articleImg1}
            preTitle="Fri jun 19 2023 | Category"
            title="Egyptian general authority for investment"
            content="It's a good idea to consider multiple lose sources and for patterns in the feedback."
          />
        </Row>
      </Container>
    </section>
  );
}

export default LatestNews;
