import React from "react";
import Header from "../../components/common/header/header";
import Banner from "../../components/common/banner/banner";
import { arbitrationBanner } from "../../components/images";
import ArbitrationConcept from "./arbitrationConcept/arbitrationConcept";
import ArbitrationCharacteristics from "./arbitrationCharacteristics/arbitrationCharacteristics";
import WhyArbitration from "./whyArbitration/whyArbitration";
import ArbitrationCenter from "./arbitrationCenter/arbitrationCenter";
import ArbitrationTypes from "./arbitrationTypes/arbitrationTypes";
import Advantages from "./advantages/advantages";
import ArbitrationClause from "../arbitrationClause/arbitrationClause";
import Footer from "../../components/common/footer/footer";
import Arbitration from "../../components/userDashboard/e-services/request/arbitration/arbitration";

const Alrbitration = () => {
  return (
    <>
      <Header />
      <Banner
        img={arbitrationBanner}
        title="Arbitration"
        content="Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
      />
      <ArbitrationConcept />
      <ArbitrationCharacteristics />
      <WhyArbitration />
      <ArbitrationCenter />
      <ArbitrationTypes />
      <Advantages />
      <ArbitrationClause />
      {/* <ArbitrationForm /> */}
      <Arbitration />
      <Footer />
    </>
  );
};

export default Alrbitration;
