import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./termsOfReferences.css";
import { alLogo, termsReference } from "../../../components/images";
const TermsOfReferences = () => {
  return (
    <section className="terms-of-references">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={5} className="position-relative">
            <div className="reference-img mb-lg-0 mb-4">
              <Image src={termsReference} alt="terms img" fluid />
            </div>
            <div className="al-logo position-absolute">
              <Image src={alLogo} fluid alt="al logo" />
            </div>
          </Col>
          <Col lg={7}>
            <div className="content-wrapper">
              <h2 className="mb-3">Terms Of References</h2>
              <div className="mb-lg-5 mb-4">
                <p>
                  Xiorem ipsum dolor sit amet, coectetur adipci ng de elit.
                  Pellentesque porttitor elit arcu, vitae mollis diam aliquam
                  non otent oevnec qdfruis libero molestie. Sed ut purus vel est
                  pulvinar eleife ellensque eget arcu in enim condimentum
                  pellentesque ornare id enim aecenas.
                </p>
                <p>
                  Ultrices ante. In semper lobortis metus, eget elementum eros
                  aliquet non. Integer leo ipsum, dignissim et interdum et,
                  malesuada a velit. In et nisi viverra, suscipit tortor a,
                  faucibus neque. Aenean vel augue enim. Aliquam augue odio,
                  aliquam ornare tincidunt et, fringilla sed Mauris molestie
                  imperdiet nisl, nec auctor mi sceleri vitae eros quis{" "}
                  <span className="fw-semibold">
                    ligula facilisis posuere. .
                  </span>
                </p>
              </div>
              <Button variant="none" className="btn-blue">
                CONTACT US
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsOfReferences;
