import React from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/header-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./LoginForm.css";

const LoginForm = () => {
  return (
    <div className="login-form-area">
      <div className="login-form-main position-relative">
        <div className="logo-wrapper d-md-block d-none">
          <Image src={Logo} alt="wallet logo" width="350" fluid />
        </div>
        <div className="login-manage-wrapper">
          <div className="login-form">
            <div className="login-form-content">
              {/* <div className="d-flex flex-column justify-content-center h-100"> */}
              <h2 className="mb-3">Sign In</h2>
              <p className="mb-3">Please sign-in below to continue.</p>
              <Form
                className="form-content"
                // onSubmit={(event) => handleSubmit(event)}
              >
                <div className="form-group-wrapper">
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Your Email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 password-feild position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      // type={eyeIcon === faEye ? "password" : "text"}
                      placeholder="Enter your password"
                    />
                    <FontAwesomeIcon
                      className="password-eye-icon"
                      role="button"
                      // icon={eyeIcon}
                      // onClick={toggleEye}
                    />
                  </Form.Group>
                </div>
                <div className="remember-login mb-md-4 mb-3 d-sm-flex align-items-center justify-content-end text-sm-start text-end mt-lg-5">
                  <Button className="btn-blue" type="submit" variant="none">
                    Login
                  </Button>
                </div>
                <Link to="/" className="forgot-link text-decoration-none">
                  Forgotten password ?
                </Link>
              </Form>
              <p className="member-text text-end mb-0 mt-xxl-5 mt-lg-4 mt-3">
                Are you a new member ?{" "}
                <Link to="#" className="text-decoration-none">
                  Create Your Account
                </Link>
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
