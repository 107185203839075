import React from "react";
import { Container, Image } from "react-bootstrap";
import {
  partnerLogo1,
  partnerLogo2,
  partnerLogo3,
  partnerLogo4,
  partnerLogo5,
  partnerLogo6,
  partnerLogo7,
  partnerLogo8,
  partnerLogo9,
  partnerLogo10,
  partnerLogo11,
  partnerLogo12,
  partnerLogo13,
  partnerLogo14,
  partnerLogo15,
  partnerLogo16,
  partnerLogo17,
  partnerLogo18,
  partnerLogo19,
  partnerLogo20,
  partnerLogo21,
  partnerLogo22,
  partnerLogo23,
  partnerLogo24,
  partnerLogo25,
  partnerLogo26,
  partnerLogo27,
  partnerLogo28,
  partnerLogo29,
  partnerLogo30,
  partnerLogo31,
  partnerLogo32,
  partnerLogo33,
  partnerLogo34,
  partnerLogo35,
  partnerLogo36,
  partnerLogo37,
  partnerLogo38,
  partnerLogo39,
  partnerLogo40,
  partnerLogo41,
  partnerLogo42,
  partnerLogo43,
  partnerLogo44,
  partnerLogo45,
  partnerLogo46,
  partnerLogo47,
  partnerLogo48,
  partnerLogo49,
  partnerLogo50,
  partnerLogo51,
  partnerLogo52,
  partnerLogo53,
} from "../../../components/images/index";
import "./partnersList.css";

function PartnersList() {
  const servicesLogo = [
    partnerLogo1,
    partnerLogo2,
    partnerLogo3,
    partnerLogo4,
    partnerLogo5,
    partnerLogo6,
    partnerLogo7,
    partnerLogo8,
  ];
  const internationalProtocol = [
    partnerLogo9,
    partnerLogo10,
    partnerLogo11,
    partnerLogo12,
    partnerLogo13,
    partnerLogo14,
    partnerLogo15,
    partnerLogo16,
    partnerLogo17,
    partnerLogo18,
  ];
  const mainPartnerLogo = [
    partnerLogo19,
    partnerLogo20,
    partnerLogo21,
    partnerLogo22,
    partnerLogo23,
    partnerLogo24,
    partnerLogo25,
    partnerLogo26,
    partnerLogo27,
    partnerLogo28,
    partnerLogo29,
    partnerLogo30,
    partnerLogo31,
    partnerLogo32,
    partnerLogo33,
    partnerLogo34,
    partnerLogo35,
    partnerLogo36,
    partnerLogo37,
    partnerLogo38,
    partnerLogo39,
    partnerLogo40,
    partnerLogo41,
    partnerLogo42,
    partnerLogo43,
    partnerLogo44,
    partnerLogo45,
    partnerLogo46,
    partnerLogo47,
    partnerLogo48,
  ];
  const strategicPartnerLogo = [
    partnerLogo49,
    partnerLogo50,
    partnerLogo51,
    partnerLogo52,
    partnerLogo53,
  ];
  const servicesLogoList = servicesLogo.map((list, index) => (
    <li className="d-flex align-items-center justify-content-center" key={index}>
      <Image src={list} alt="partner logo" fluid />
    </li>
  ));
  const internationalProtocolList = internationalProtocol.map((list, index) => (
    <li className="d-flex align-items-center justify-content-center" key={index}>
      <Image src={list} alt="partner logo" fluid />
    </li>
  ));
  const mainPartnerList = mainPartnerLogo.map((list, index) => (
    <li className="d-flex align-items-center justify-content-center" key={index}>
      <Image src={list} alt="partner logo" fluid />
    </li>
  ));
  const strategicPartnerList = strategicPartnerLogo.map((list,index) => (
    <li className="d-flex align-items-center justify-content-center" key={index}>
      <Image src={list} alt="partner logo" fluid />
    </li>
  ));
  return (
    <section className="partners-sec">
      <Container fluid className="custom-container">
        <div className="heading-sec mb-4">
          <h4 className="mb-sm-3 mb-2">Service Partners</h4>
          <p>
            Is the partner who contributes to the provision of the chamber’s
            services and operations in different ways and times from different
            locations.
          </p>
        </div>
        <ul className="parner-list list-unstyled d-flex flex-wrap gap-sm-3 gap-2 mb-4">
          {servicesLogoList}
        </ul>
        <div className="heading-sec mb-4">
          <h4 className="mb-sm-3 mb-2">International protocol</h4>
          <p>
            It is an international agreement with specialized international
            institutions, companies and organizations to see the best practices
            in several areas such as: commercial law, arbitration, international
            events and conferences, and investment support in order to
            strengthen relations.
          </p>
        </div>
        <ul className="parner-list list-unstyled d-flex flex-wrap gap-sm-3 gap-2 mb-4">
          {internationalProtocolList}
        </ul>
        <div className="heading-sec mb-4">
          <h4 className="mb-sm-3 mb-2">Main Partners</h4>
          <p>
            Is the partner who collaborate with the Chamber to develop the
            servicers and achieve the customer happiness and share the expertise
            and knowledge in the technical aspects to enhance the performance
            efficiency.
          </p>
        </div>
        <ul className="parner-list list-unstyled d-flex flex-wrap gap-sm-3 gap-2 mb-4">
          {mainPartnerList}
        </ul>
        <div className="heading-sec mb-4">
          <h4 className="mb-sm-3 mb-2">Strategic Partner</h4>
          <p>
            Is the partner who contributes with the Chamber in achieving the
            strategic goals, and implementation of projects and initiatives in
            the Chamber.
          </p>
        </div>
        <ul className="parner-list list-unstyled d-flex flex-wrap gap-sm-3 gap-2 mb-0">
          {strategicPartnerList}
        </ul>
      </Container>
    </section>
  );
}

export default PartnersList;
