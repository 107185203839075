import React from "react";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./servicesCard.css";
import { Image } from "react-bootstrap";
const ServicesCard = ({ preTitle, title, img, content }) => {
  return (
    <div className="services-card mb-3">
      <div className="img-wrapper">
        <Image src={img} alt="service img" fluid />
      </div>
      <div className="card-content">
        <span className="pre-heading">{preTitle}</span>
        <h4 className="mb-2">{title}</h4>
        <p>{content}</p>
        <span
          className="details-arrow align-items-center justify-content-end gap-2"
          role="button"
        >
          Details
          <FontAwesomeIcon icon={faArrowRightLong} />
        </span>
      </div>
    </div>
  );
};

export default ServicesCard;
