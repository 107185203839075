import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import UAEflag from "../../../assets/images/uae-flag.png";
import AlLogoBg from "../../../assets/images/al-logo.png";
import "./historyOfArbitration.css";

function HistoryOfArbitration() {
  return (
    <section className="arbitration-history">
      <Container fluid className="custom-container">
        <Row className="justify-content-between">
          <Col lg={7}>
            <div className="content-wrapper mb-lg-0 mb-5">
              <h2 className="mb-3">
                Introduction To A Brief History Of Arbitration In The UAE
              </h2>
              <p className="p-lg mb-3">
                Our team of highly skilled and experienced arbitration lawyers
                in the UAE is dedicated to providing top-notch legal assistance.
              </p>
              <p className="mb-4">
                Nullam imperdiet, erat dignissim pellentesque laoreet, turpis
                lorem posuere quam, ac fringilla justo felis et leo. Nam sed
                odio ipsum. Quisque venenatis efficitur felis, sit amet
                dignissim odio pharetra eget. Vivamus nulla metus, euismod at
                magna in, pellentesque aliquam neque. Fusce tortor orci,
                sollicitudin feugiat velit a, congue lobortis magna. Nulla
                tincidunt vitae enim vel feugiat. Maecenas ut metus at leo
                dignissim luctus in vel purus. Quisque facilisis, sapien a
                hendrerit aliquam, nisi lacus maximus eros, auctor pulvinar odio
                libero sodales purus. Donec fermentum mauris rhoncus accumsan
                feugiat.
              </p>
              <p>
                Morbi faucibus, urna vitae mollis sollicitudin, nibh ipsum
                tempor metus, non aliquet sem velit pellentesque metus. In
                commodo porta tortor. Vestibulum mattis rhoncus mauris eget
                efficitur. Mauris auctor feugiat cursus. Nulla tristique est
                quis orci interdum dignissim. Duis in quam scelerisque,
                imperdiet nunc et, posuere orci. Cras eu justo vulputate,
                scelerisque eros nec, tempor ligula.
              </p>
            </div>
          </Col>
          <Col lg={5} className="position-relative text-lg-end text-center">
            <div className="side-img">
              <Image src={UAEflag} alt="flag img" fluid />
            </div>
            <div className="al-logo">
              <Image src={AlLogoBg} fluid alt="chairmain img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HistoryOfArbitration;
