import React from "react";
import CentralBuildingSec from "../../../components/common/centralBuildingSec/centralBuildingSec";

const RoleOfCenter = () => {
  return (
    <>
      <CentralBuildingSec
        title="The Role Of The Center"
        content1="The role of a center of arbitration is paramount in providing a structured and impartial platform for the resolution of disputes outside traditional court systems. These centers, such as the Dubai International Arbitration Center (DIAC) or the Sharjah Center for International Commercial Arbitration, facilitate fair, efficient, and enforceable resolutions. They offer a neutral environment where parties can select arbitrators, present their cases, and receive a binding decision."
        content2="These centers, such as the Dubai International Arbitration Center (DIAC) or the Sharjah Center for International Commercial Arbitration, facilitate fair, efficient, and enforceable resolutions. They offer a neutral environment where parties can select arbitrators, present their cases, and receive a binding decision. The center's rules and procedures ensure a streamlined arbitration process, promoting transparency, expediency, and cost-effectiveness. By administering arbitration cases, these centers contribute significantly to fostering trust in international business transactions and maintaining the integrity of alternative dispute resolution mechanisms."
      />
    </>
  );
};

export default RoleOfCenter;
