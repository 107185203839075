import React, { useRef } from "react";
import "./contactDetails.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { contactSchema } from "../../../validation/validationSchema";
import { config } from "../../../config/config";

const ContactDetails = () => {
  const recaptchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const handleCaptchaChange = (value) => {
    setValue("reCaptcha", value); 
  };

  // Handle form submission
  const onSubmit = (data) => {
    // Add logic to send form data to server or handle it as needed
    reset(); // Reset form fields after successful submission
  };

  return (
    <section className="contact-wrapper">
      <Container fluid className="custom-container">
        <Row className="gx-0">
          <Col lg={7}>
            <div className="send-message">
              <div className="heading-wrapper mb-4 pb-lg-2">
                <h2 className="text-blue mb-3">Send Us A Message</h2>
                <p className="mb-0">
                  For inquiries, feedback, or collaboration opportunities,
                  please feel free to reach out to us. Our dedicated team is
                  here to assist you—contact us via the provided email or phone
                  number, and we'll respond promptly.
                </p>
              </div>
              <Form className="contact-form general-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-sm-flex gap-sm-3">
                  <Form.Group
                    className="mb-sm-4 mb-3 w-100"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">
                      {errors?.name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-sm-4 mb-3 w-100"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" {...register("email")} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">
                      {errors?.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-lg-5 mb-4"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                     as="textarea"
                     placeholder="Your Message"
                     rows={5}
                     className="resize-none"
                     {...register("message")}
                     isInvalid={!!errors.message}
                   />
                   <Form.Control.Feedback type="invalid">
                     {errors?.message?.message}
                   </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={config?.recaptchaSiteKey}
                      onChange={handleCaptchaChange}
                    />
                     {errors?.reCaptcha && (
                      <div className="invalid-feedback d-block">
                        {errors.reCaptcha?.message}
                      </div>
                    )}
                  </Form.Group>
                <div className="text-end">
                  <Button variant="none" className="btn-blue" type="submit"  disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "SEND MESSAGE"}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col lg={5}>
            <div className="contact-info position-relative h-100">
              <div className="heading-wrapper mb-4">
                <h3 className="text-white mb-3">Contact Information</h3>
                <p className="mb-0">
                  For inquiries, feedback, or collaboration opportunities,
                  please feel free to reach out to us. Our dedicated team is he
                </p>
              </div>
              <ul className="contact-list list-unstyled">
                <li>
                  <h4 className="mb-2">Phone</h4>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="text-white me-3"
                    />
                    <p className="mb-0">+971 021-444 6663</p>
                  </div>
                </li>
                <li>
                  <h4 className="mb-2">Email</h4>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-white me-3"
                    />
                    <p className="mb-0">+971 021-444 6663</p>
                  </div>
                </li>
                <li>
                  <h4 className="mb-2">Work Hours</h4>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-white me-3"
                    />
                    <p className="mb-0">10:00 am -7:00 pm</p>
                  </div>
                </li>
                <li>
                  <h4 className="mb-2">Address</h4>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-white me-3"
                    />
                    <p className="mb-0">
                      No. 149, Zone A Plot1 Lumumba Avenue ,Kampala,Uganda
                    </p>
                  </div>
                </li>
              </ul>
              <div className="social-icon-wrapper d-sm-flex align-items-center">
                <p className="mb-sm-0 me-xl-5 me-sm-4">
                  Follow us on social platforms
                </p>
                <ul className="social-icons list-unstyled mb-0 d-flex gap-3">
                  <li>
                    <a
                      href="/"
                      className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactDetails;
